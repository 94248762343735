const termsGT =
  '<div style="font-family: Roboto,sans-serif; font-weight:400; color: #787878; line-height: 1.5; padding: 15px"> ' +
  ' <h2 style="color: #00377b; font-weight:400" id="términos-y-condiciones---tigo-money"><strong>Términos y Condiciones - Tigo Money</strong></h2> ' +
  " <p><strong>SOLICITUD DE ENVÍO Y/O RETIRO DE DINERO Y/O PAGO DE SERVICIOS</strong></p> " +
  " <p>El Usuario por este acto solicita a Distribuidora de Comunicaciones de Oriente, Sociedad Anónima (en adelante " +
  "  “COORSA”), la prestación del servicio TIGO MONEY, que contempla el envío y/o retiro de dinero, y/o recepción de " +
  "  pagos de servicios, y/o productos y cualquier otro que habilite COORSA en un futuro (denominadas conjunta o " +
  "  separadamente como “transacciones” o “transferencias”) Los servicios se prestarán bajo los siguientes términos y " +
  "  condiciones:</p> " +
  " <p>Para efectos del servicio TIGO MONEY la palabras a continuación tendrán los siguiente significados: a) Usuario u " +
  "  Ordenante: Persona que solicita una transacción TIGO MONEY; b) Beneficiario: Persona designada como receptor de " +
  "  los fondos transmitidos; c) Punto de Servicio: Agente Tigo Money o punto de venta autorizado para prestar los " +
  "  servicios de Tigo Money; d) Servicio o Servicios Tigo Money: transferencias o transacciones realizadas a través " +
  "  de un Punto de Servicio; e) Sistema Tigo Money: Conjunto de políticas, procesos y procedimientos necesarios para " +
  "  ofrecer, operar y realizar adecuadamente los servicios Tigo Money; y, f) Transferencia o transacción: Operación " +
  "  por la que se transfiere una cantidad de dinero a un Beneficiario, se excluye a las Remesas y Pagos de Servicio, " +
  "  así como otras que en el futuro se implementen.</p> " +
  " <p><strong>TÉRMINOS Y CONDICIONES GENERALES:</strong></p> " +
  " <ol> " +
  "  <li>Para el uso de los servicios TIGO MONEY el Usuario deberá registrarse desde la WebApp <a " +
  '    href="https://tigomoney.com.gt" style="color: #00c8ff;">https://tigomoney.com.gt</a> o versiones App con su DPI y completando ' +
  "   todos los datos requeridos. Los servicios TIGO MONEY están destinados para guatemaltecos naturales o " +
  "   nacionalizados mayores de edad. Únicamente se permite una billetera por número de DPI.</li> " +
  "  <li>Los puntos de servicio autorizados estarán disponibles en los horarios y en los días de atención que sean " +
  "   establecidos por COORSA. Los usuarios podrán requerir esta información en los Puntos de Atención, en las " +
  "   Agencias Tigo, en las líneas de Atención al usuario (*987 o 2428 0041) y en su página de internet <a " +
  '    href="https://www.tigo.com.gt/tiendas?category=Tigo%20Money" style="color: #00c8ff;">https://www.tigo.com.gt/tiendas?category=Tigo%20Money</a> ' +
  "  </li> " +
  "  <li>El Usuario es responsable de proporcionar los datos correctos del Beneficiario y el número Tigo o código de " +
  "   cuenta a pagar. COORSA no se hace responsable de errores en los datos proporcionados, los cuales quedarán " +
  "   almacenados en el sistema informático. El sistema no permite corroborar la información del titular de la " +
  "   cuenta más allá de las medidas de seguridad entregadas en la contratación del presente servicio, por lo que " +
  "   <strong>EL USUARIO RENUNCIA A REALIZAR CUALQUIER RECLAMO POR EL USO INDEBIDO QUE SE LE DE AL TELÉFONO EN " +
  "    CUANTO A LA INFORMACIÓN DE LAS TRANSFERENCIAS PENDIENTES DE ENTREGA QUE CORRESPONDAN AL MISMO, USO POR " +
  "    EL QUE EL ORDENANTE ASUME CUALQUIER RESPONSABILIDAD.</strong></li> " +
  "  <li>En ningún caso se procesarán reversiones si existiera algún error imputable al Usuario.</li> " +
  "  <li>Los datos personales y cualquier información que describa cada transacción facilitada por el usuario serán " +
  "   archivados y tratados de conformidad y según lo requiera la ley, por lo tanto, COORSA o Comunicaciones " +
  "   Celulares S.A. podrán divulgar cualquier información arriba mencionada, a requerimiento de las autoridades " +
  "   pertinentes.</li> " +
  "  <li>Las transacciones se realizarán en moneda de curso legal y en efectivo. 7. Cada usuario podrá ordenar dentro " +
  "   de los límites mensuales por transacción que de tiempo en tiempo fije COORSA.</li> " +
  "  <li>Podrán existir puntos de servicio para enviar o recibir dinero, con límites por monto mínimo de envío y " +
  "   límites por monto mínimo o máximo para pago de dinero.</li> " +
  "  <li>El usuario acepta y autoriza desde ya pagar a COORSA, adicional a la transacción, la comisión " +
  "   correspondiente por cada transacción operada por COORSA contemplada dentro de los servicios TIGO MONEY. El " +
  "   valor o la forma de determinar las comisiones podrán ser modificadas por COORSA de tiempo en tiempo.</li> " +
  "  <li>Para el caso del servicio de envío/recepción de Remesas internacionales: a. Es requisito que el Beneficiario " +
  "   presente su Documento Personal de Identificación (DPI) en los Puntos de Servicio autorizados para retirar la " +
  "   Remesa. b. El punto de Servicio Tigo Money entregará la remesa al destinatario que se identifique con los " +
  "   datos que le requiera, considerándose como bien hecha la entrega de la remesa a la persona que presente el " +
  "   Documento Personal de Identificación (DPI) y la información requerida sin asumir ninguna responsabilidad por " +
  "   documentación falsa, extraviada o robada. c. En caso de Remesas provenientes del extranjero, COORSA se " +
  "   regirá por las instrucciones recibidas por los Remesadores en el país del Ordenante. El Beneficiario " +
  "   RENUNCIA A RESPONSABILIZAR Y HACER CUALQUIER RECLAMO RELACIONADO CON ESTE TEMA A COORSA.</li> " +
  "  <li>Al firmar el formulario de solicitud, el Usuario comprende y acepta que en todos los servicios la " +
  "   responsabilidad de COORSA se limita a recibir la información para la transacción utilizando la red " +
  "   inalámbrica de Comunicaciones Celulares S.A. y entregar los fondos correspondientes al Beneficiario de " +
  "   conformidad con dicha información.</li> " +
  "  <li>Uso de medios electrónicos: el Usuario acepta expresamente y ratifica que, al enviar la información de " +
  "   cualquier transacción por medio de su aparato celular, está prestando su consentimiento a estos términos y " +
  "   condiciones para la utilización y contratación de los servicios TIGO MONEY, mismos que COORSA podrá " +
  "   modificar de tiempo en tiempo. Manifiesta el usuario que es el único responsable de la información que " +
  "   ingrese a través de su aparato celular y de la autorización que realice a través de este. Así mismo confirma " +
  "   que la función de Comunicaciones Celulares, Sociedad Anónima, en cuanto a las comunicaciones electrónicas " +
  "   que son procesadas por su medio, es únicamente la de servir de operador de una red de telecomunicaciones " +
  "   bajo la Ley General de Telecomunicaciones.</li> " +
  "  <li>Desde ya autorizo voluntariamente a COORSA para que pueda corroborar la veracidad de toda información " +
  "   proporcionada por mi persona, por cualquier medio legal, siendo responsable de lo relativo al delito de " +
  "   perjurio en caso se llegara a constar que la información relacionada es falsa, parcial o totalmente. " +
  "   Asimismo, faculto a COORSA para que pueda crear una base de datos con toda aquella información que se genere " +
  "   dentro del giro de mi relación con COORSA y para que pueda almacenar, distribuir, y/o comercializar la " +
  "   misma. También autorizo para que COORSA pueda generar, recopilar, difundir y/o comercializar reportes o " +
  "   estudios sobre la información recabada, que se relacione con mis relaciones contractuales, crediticias, " +
  "   comerciales, así como sobre mi comportamiento y/o preferencias, que podrá ser utilizada inclusive para " +
  "   ofrecerme bienes y/o servicios. Autorizo voluntariamente a las entidades que prestan servicios de " +
  "   información para que COORSA pueda contratar a dichas entidades la entrega a COORSA de reportes o estudios " +
  "   que contengan información personal. Esta autorización expresa contenida en la presente clausula se da de " +
  "   conformidad con la Ley de Acceso a la lnformación Pública.</li> " +
  "  <li>MEDIANTE LA UTILIZACION DE LOS SERVICIOS TIGO MONEY, A TRAVÉS DE LOS PUNTOS DE SERVICIO O DE CUALQUIER " +
  "   APARATO CELULAR, EL USUARIO TÁCITAMENTE MANIFIESTA ESTAR ENTERADO DE ESTOS TÉRMINOS Y CONDICIONES Y PRESTA " +
  "   SU CONSENTIMIENTO PARA SOMETERSE A LOS MISMOS.</li> " +
  " </ol> " +
  " <hr> " +
  " <p><strong>RECEPCIÓN / DEVOLUCIÓN DE ANTICIPO DE FONDOS POR ENCARGOS AL COMISIONISTA</strong></p> " +
  " <p>EL COMITENTE por este acto entrega a Comunicaciones Corporativas, S. A. (en adelante “COMCORP” o el " +
  "  “COMISIONISTA”) el ANTICIPO DE FONDOS SUPLIDOS AL COMISIONISTA o solicita la devolución de los mismos, bajo los " +
  "  siguientes términos y condiciones del servicio:</p> " +
  " <p><strong>Forma de comunicar los encargos:</strong></p> " +
  " <ol> " +
  "  <li>La comunicación de los encargos y las instrucciones de Comitente al Comisionista se harán mediante " +
  "   comunicaciones electrónicas utilizando la red de telefonía celular administrada y propiedad de la entidad " +
  "   Comunicaciones Celulares, Sociedad Anónima; o utilizando el servicio de internet proporcionado por su " +
  "   compañía proveedora.</li> " +
  "  <li>Los encargos comunicados a través de la red electrónica o de internet antes indicada tiene toda la eficacia, " +
  "   efectos jurídicos, validez y fuerza obligatoria establecidos en la LEY PARA EL RECONOCIMIENTO DE LAS " +
  "   COMUNICACIONES Y FIRMAS ELECTRONICAS Decreto 47-2008 del Congreso de la República.</li> " +
  "  <li>El procedimiento aceptado por el Comitente para comunicar los encargados al Comisionista, para efectos del " +
  "   artículo 17 y 18 del decreto 47-2008 del Congreso de la República, es a través de la secuencia de los " +
  "   siguientes pasos: i. El Comitente utilizará su dispositivo móvil celular habilitado para el servicio que " +
  "   brinda la entidad <strong>Comunicaciones Celulares, Sociedad Anónima u otra compañía de servicios de " +
  "    telefonía</strong> para transmitir las comunicaciones electrónicas consistentes en las instrucciones de " +
  "   los encargos dados al Comitente; <strong>ii Acceso al menú (solo dispositivos Tigo):</strong> en dicho " +
  "   dispositivo móvil deberá acceder al menú identificado como <strong>“TIGO MONEY”</strong> marcando el código " +
  "   <strong>*789#</strong> en el teclado del mismo o por medio de los canales digitales webapp /aplicación " +
  "   disponible. <strong>iii Autenticación de la firma electrónica:</strong> Al acceder a dicho menú deberá " +
  "   autenticar su identidad mediante la utilización de la contraseña única, privada y personal escogida por el " +
  "   Comitente para el efecto. <strong>iv Proveer datos para la realización del encargo:</strong> Una vez el " +
  "   Comitente ha accedido al módulo respectivo deberá escoger uno de los encargos a Comunicar al Comitente, a " +
  "   través de los mecanismos electrónicos que son conocidos por ambas partes y girar sus instrucciones en cuanto " +
  "   al destinatario, naturaleza y monto de la transacción comercial en su nombre.</li> " +
  "  <li><strong>Modificación del Procedimiento: Ambas partes acuerdan que al Comisionista estará facultado para " +
  "    mejorar, modificar o variar el proceso o procedimiento para comunicar los encargos, lo cual deberá " +
  "    hacerlo del conocimiento del Comitente con un plazo razonable para su implementación y por los medios " +
  "    que estime conveniente.</strong></li> " +
  "  <li>El Comitente es responsable de proporcionar los datos correctos del encargo y las instrucciones de este. " +
  "   COMCORP no se hace responsable de errores en los datos proporcionados, los cuales quedarán almacenados en el " +
  "   sistema informático, por lo que <strong>EL COMITENTE RENUNICA A REALIZAR CUALQUIER RECLAMO POR EL USO " +
  "    INDEBIDO QUE SE LE DE AL TELÉFONO EN CUANTO A LA INFORMACIÓN DE LOS ANTICIPOS DE FONDOS A FAVOR DEL " +
  "    COMITENTE, USO POR EL QUE EL COMIENTE ASUME CUALQUIER RESPONSABILIDAD.</strong></li> " +
  "  <li>La comunicación del encargo o instrucciones dados al comisionista son de carácter irrevocable, en ese " +
  "   sentido no podrán revocarse, suspenderse ni modificarse los encargos o encomendados una vez hayan sido " +
  "   transmitidos al Comisionista.</li> " +
  "  <li>Es requisito presentar Documento Personal de Identificación (DPI) en los puntos de atención autorizados para " +
  "   suplir el anticipo de fondos o para retirarlo.</li> " +
  "  <li>Quienes sean Menores de Edad, no podrán utilizar el servicio ni ser Comitentes. Únicamente el Comitente " +
  "   Mayor de Edad, debidamente identificado podrá anticipar fondos por encargos futuros o solicitar su " +
  "   devolución. 5. Los puntos de atención autorizados para recibir los anticipos de dinero o devolverlos por " +
  "   medio del Sistema “Tigo Money” estarán disponibles en los horarios y en los días de atención establecidos " +
  "   por el COMISIONISTA. Los Comitentes podrán requerir esta información en los puntos de atención, en las " +
  "   Agencias Tigo y en las líneas de Atención al cliente (*987 o 2428 0041).</li> " +
  "  <li>Todos los encargos instruidos por el Comitente se realizan en moneda de curso legal y en efectivo.</li> " +
  "  <li>El Comitente ha declarado mediante contrato suscrito con COMCORP el número telefónico habilitado para los " +
  "   efectos de los encargos a instruir. El aparato telefónico relacionado a dicho número deberá ser presentado " +
  "   junto con el documento de identificación personal del Comitente al momento de suplir de fondos al " +
  "   Comisionistas a través de los canales y puntos de servicio antes señalados. Si el Comitente desea cambiar de " +
  "   número telefónico aquí indicado, deberá seguir los pasos para su sustitución establecidos por el " +
  "   Comisionista mediante las formas o formularios que para el efecto se le presenten.</li> " +
  "  <li>El Comitente entiende y comprende que la responsabilidad de COMCORP se limita a recibir la información del " +
  "   encargo y llevarlo a cabo, utilizando para el efecto los medios para ello. Utilizando como único medio la " +
  "   red inalámbrica de Comunicaciones Celulares, S.A. u otra compañía proveedora.</li> " +
  "  <li>El Punto autorizado de Servicio Tigo Money devolverá el anticipo de fondos al Comitente que se identifique " +
  "   con los datos que se le requiera, considerándose como bien hecha la entrega de estos a la persona que " +
  "   presente su Documento Personal de Identificación (DPI) y la información requerida sin asumir ninguna " +
  "   responsabilidad por la documentación falsa, extraviada o robada.</li> " +
  "  <li>El Comitente pagará al Comisionista por la realización de los encargos, de acuerdo con las tarifas " +
  "   establecidas en el CONTRATO DE COMISIÓN MERCANTIL celebrado entre el Comitente y el Comisionista.</li> " +
  "  <li>Los montos de las Comisiones a pagar por el Comitente, establecidas en el CONTRATO DE COMISIÓN MERCANTIL en " +
  "   concepto de contraprestación por los encargos, esta sujetas a cambios por parte del Comisionista, dichos " +
  "   cambios serán comunicados por los medios que este consideré convenientes.</li> " +
  "  <li>Dichas Comisiones se debitarán directamente del anticipo de fondos proveído por el Comitente al Comisionista " +
  "   al momento en que éste último realice el encargo instruido por el Comitente. 16. Uso de medios electrónicos: " +
  "   El Comitente confirma que al enviar la instrucción de cualquier encargo por medio de su teléfono está " +
  "   prestando su consentimiento a estos términos y condiciones. Así mismo confirma que la función de " +
  "   Comunicaciones Celulares, Sociedad Anónima, es la de servir de operador de una red de telecomunicaciones " +
  "   bajo la Ley General de Telecomunicaciones.</li> " +
  "  <li>Desde ya autorizo voluntariamente a COMCORP para que pueda corroborar la veracidad de toda información " +
  "   proporcionada por mi persona, por cualquier medio legal, siendo responsable de lo relativo al delito de " +
  "   perjurio en caso se llegara a constar que la información relacionada es falsa, parcial o totalmente. " +
  "   Asimismo, faculto a COMCORP para que pueda crear una base de datos con toda aquella información que se " +
  "   genere dentro del giro de mi relación con COMCORP y para que pueda almacenar, distribuir, y/o comercializar " +
  "   la misma. También autorizo para que COMCORP pueda generar, recopilar, difundir y/o comercializar reportes o " +
  "   estudios sobre la información recabada, que se relacione con mis relaciones contractuales, crediticias, " +
  "   comerciales, así como sobre mi comportamiento y/o preferencias, que podrá ser utilizada inclusive para " +
  "   ofrecerme bienes y/o servicios. Autorizo voluntariamente a las entidades que prestan servicios de " +
  "   información para que COMCORP pueda contratar a dichas entidades la entrega a COMCORP de reportes o estudios " +
  "   que contengan información personal. Esta autorización expresa contenida en la presente clausula se da de " +
  "   conformidad con la Ley de Acceso a la lnformación Pública.</li> " +
  " </ol> " +
  "</div> ";

export default termsGT;
