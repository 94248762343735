import React, { useEffect, useState } from "react";
import axiosClient from "../commons/axiosClient";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

const LinkAccount = ({ userData, token }) => {
  const [t] = useTranslation("global");
  const [isError, setIsError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [urlRedirect, setUrlRedirect] = useState(null);
  const [messages, setMessage] = useState(null);

  useEffect(() => {
    const linkDevice = () => {
      setIsError(false);
      setSuccess(false);
      setLoading(true);
      Object.assign(axiosClient.defaults.headers, {
        Authorization: token,
      });
      axiosClient
        .post(`${process.env.REACT_APP_MTS_DOMAIN}/callback`, userData)
        .then((response) => {
          setSuccess(true);
          setLoading(false);

          const { url, message } = response.data;
          setUrlRedirect(url);
          setMessage(message);
        })
        .catch((_error) => {
          setIsError(true);
          setLoading(false);
        });
    };
    linkDevice();
  }, [userData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (urlRedirect != null) window.location.href = urlRedirect;
    }, 2000);
    return () => clearTimeout(timer);
  }, [urlRedirect]);

  return (
    <Box data-testid="linkAccount">
      {loading && (
        <Box sx={{ textAlign: "center", m: 1 }}>
          <CircularProgress />
        </Box>
      )}
      {success && (
        <>
          <Box
            sx={{
              textAlign: "center",
              m: 2,
              color: "#00377D",
              fontSize: "h5.fontSize",
            }}
          >
            <Typography variant="text24" gutterBottom>
              {messages}
            </Typography>
          </Box>
        </>
      )}
      {isError && (
        <Box sx={{ textAlign: "center", m: 1, color: "#00377D" }}>
          {t("Message.LinkAccount.Error")}
        </Box>
      )}
    </Box>
  );
};
LinkAccount.propTypes = {
  userData: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};
export default LinkAccount;
