import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { initReactI18next, I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import Routing from "./Routing";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

i18next.use(initReactI18next).init({
  lng: "es",
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});
root.render(
  <StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <Routing>
          <App />
        </Routing>
      </Provider>
    </I18nextProvider>
  </StrictMode>
);

reportWebVitals();
