const termsSV =
  '<div style="font-family: Roboto,sans-serif; font-weight:400; line-height: 1.2; padding: 15px"> ' +
  ' <h3 style="color: #00377b; font-weight:400; font-size: 2.125rem;" id="términos-y-condiciones-para-la-prestación-de-servicios-tigo-money"> ' +
  " Términos y Condiciones para la Prestación de Servicios Tigo Money</h3> " +
  " <p>Al crear su registro de dinero electrónico Tigo Money, el Cliente acepta las condiciones aplicables a los servicios " +
  " de provisión de dinero electrónico, los cuales son ofrecidos dentro del territorio salvadoreño por Sociedad " +
  " Proveedora de Dinero Electrónico Mobile Cash, Sociedad Anónima, que puede abreviarse SPDE Mobile Cash, S.A., quien " +
  " en adelante se denominará Mobile Cash o Tigo Money y opera bajo la marca “Tigo Money”.</p> " +
  " <p><strong>Objeto</strong> " +
  " <br> " +
  " Al suscribir una Solicitud de Registro de Dinero Electrónico, el cliente ha solicitado la apertura de un registro de " +
  " dinero electrónico vinculado a su titularidad y asociado a su documento de identidad. Por lo tanto el Cliente es el " +
  " titular del dinero electrónico que se registre en el mismo. " +
  " </p> " +
  " <p><strong>Uso de Dinero Electrónico</strong> " +
  " <br> " +
  " El Registro de dinero electrónico, le permitirá al Cliente el acceso al Sistema Tigo Money y a todos los usos " +
  " permitidos legalmente para el dinero electrónico inclusive su convertibilidad a efectivo ante un Agente Tigo Money " +
  " previa presentación de su documento de identidad. Los servicios pueden consultarse en los Agentes Tigo Money " +
  ' autorizados, en la página web: <a href="http://www.tigomoney.com.sv" ' +
  '  style="color: #00c8ff;">www.tigomoney.com.sv</a>, o llamando al *611 o 22074000. ' +
  " </p> " +
  " <p><strong>Identificación del Cliente</strong> " +
  " <br> " +
  " Cada vez que el Cliente realice una transacción en un Agente Tigo Money deberá identificarse presentando el original " +
  " de su Documento de Identidad y deberá verificar que la transacción se efectúe como se solicitó al agente mediante el " +
  " mensaje de confirmación que reciba en su dispositivo móvil. El cliente también podrá identificarse en medios " +
  " digitales utilizando sus credenciales. Su identidad digital estará relacionada con su número de teléfono y su correo " +
  " electrónico. El cliente podrá utilizar, posterior a la firma de la solicitud de registro de dinero electrónico, " +
  " firma electrónica simple para autorizar la información contenida en el documento electrónico que esté aprobando. " +
  " </p> " +
  " <p><strong>Canales</strong> " +
  " <br> " +
  " El cliente tendrá a su disposición para la realización de las operaciones a los Agentes Tigo Money autorizados " +
  " debidamente identificados, que podrán ser consultados en: <a " +
  '  href="https://ayuda.tigo.com.sv/hc/es/articles/360045292193-Puntos-Tigo-Money-Tigo-Money" ' +
  '  style="color: #00c8ff;">https://ayuda.tigo.com.sv/hc/es/articles/360045292193-Puntos-Tigo-Money-Tigo-Money</a> o ' +
  " llamando al *611 o 22074000 y los canales USSD, aplicación móvil o aplicación web. Mobile Cash dará el mantenimiento " +
  " correctivo de sus plataformas, y en caso de averías, interrupciones o mal funcionamiento de los servicios por causas " +
  " fortuitas o fuerza mayor, MOBILE CASH reparará en el plazo más breve posible los inconvenientes producidos. " +
  " </p> " +
  " <p><strong>Transacciones y Límites</strong> " +
  " <br> " +
  " Todas las operaciones serán efectuadas en dólares de los Estados Unidos de América o en moneda de curso legal. El " +
  " Cliente acepta como prueba de las operaciones y/o transacciones efectuadas, los registros electrónicos de " +
  " autorización que se originen bajo su titularidad en el Sistema Tigo Money a través de la aplicación móvil o vía el " +
  " menú USSD *555# o a través de cualquier otro dispositivo o interfase que el cliente haya autorizado a través de su " +
  " pin o sus respectivas credenciales. El monto máximo por transacción será de un salario mínimo urbano mensual del " +
  " sector comercio y servicios, y el monto máximo de transacciones acumuladas en un mes y el saldo máximo del Registro " +
  " Tigo Money será de cinco salarios mínimos urbanos mensuales del sector comercio y servicios. Estos límites podrán " +
  " variar de acuerdo a las actualizaciones que realizare el Banco Central de Reserva, de conformidad a lo establecido " +
  " en el Art.5 inc.3° parte final de la LFIF. " +
  " </p> " +
  " <p><strong>Transacciones por medio de BITCOIN</strong> " +
  " <br> " +
  " MOBILE CASH pondrá a disposición de sus clientes transacciones de carga de dinero electrónico por medio de Bitcoin " +
  " así como cualquier otra transacción para la cual fuere autorizada. Las transacciones llevarán implícita la " +
  " utilización de proveedores de servicios Exchange que permitirán la conversión automática de bitcoins a dólares de " +
  " los Estados Unidos de América, para ser acreditados en las billeteras de dinero electrónico de los clientes. Las " +
  " características de los servicios y cargos asociados podrán ser consultadas en <a " +
  '  href="https://www.tigo.com.sv/legales#tigo-money-terminos-y-condiciones" ' +
  '  style="color: #00c8ff;">https://www.tigo.com.sv/legales#tigo-money-terminos-y-condiciones</a>. ' +
  " </p> " +
  " <p><strong>Condiciones de Seguridad</strong> " +
  " <br> " +
  " Con la creación del registro Tigo Money, al cliente se le asigna un PIN provisional, para que luego este cree su " +
  " propio PIN. El Cliente será el responsable de la administración y resguardo de sus credenciales de acceso, ya que " +
  " éstos le han sido asignados con carácter personalísimo, confidencial e intransferible, por lo que su utilización es " +
  " de exclusiva responsabilidad del Cliente, quien correrá con los riesgos derivados por su revelación a terceros. El " +
  " Cliente, asimismo reconoce que la utilización de dicho PIN implica el reconocimiento de todas las transacciones que " +
  " realiza a través del registro Tigo Money especialmente las relacionadas a pagos y compras en comercios afiliados. " +
  " </p> " +
  " <p><strong>Pérdida, Robo o Extravío del Dispositivo Móvil</strong> " +
  " <br> " +
  " En caso de pérdida, robo o extravío del dispositivo móvil vinculado a su Registro Tigo Money, el Cliente será " +
  " responsable de reportarlo de inmediato a la compañía celular a la que pertenezca el dispositivo móvil, y " +
  " posteriormente solicitar el bloqueo temporal del registro Tigo Money al número 22074000 o al *611. El registro de " +
  " dinero electrónico y el dinero electrónico a su favor no están sujetos al dispositivo móvil sino a su registro como " +
  " cliente Tigo Money y permanecen disponibles al cliente a través de su PIN personal. La reactivación del registro " +
  " podrá ser realizada en cualquier momento por el cliente llamando al número 22074000 o al *611. " +
  " </p> " +
  " <p><strong>Bloqueo del Registro Tigo Money</strong> " +
  " <br> " +
  " El cliente podrá solicitar el bloqueo del registro Tigo Money al número 22074000. Para solicitar el desbloqueo del " +
  " registro, el Cliente deberá llamar al 22074000 o al *611, en donde deberá proporcionar información detallada " +
  " relacionada a su identidad personal y solicitar el desbloqueo del registro Tigo Money. " +
  " </p> " +
  " <p><strong>Comisiones</strong> " +
  " <br> " +
  " El cliente pagará las siguientes comisiones como contraprestaciones al servicio recibido: hasta 4% (IVA incluido) " +
  " sobre el monto a retirar en caso corresponda a: i) retiro de dinero recibido de otra cuenta Tigo Money y de remesas " +
  " internacionales; ii) retiro de dinero de cuenta por abono propio. Otros retiros y demás servicios 0%. El monto de " +
  " las comisiones por los servicios podrán variar y serán publicadas de conformidad a lo establecido en la LFIF y " +
  ' estarán disponibles en el link: <a href="https://www.tigo.com.sv/tigo-money/tarifas" ' +
  '  style="color: #00c8ff;">https://www.tigo.com.sv/tigo-money/tarifas</a>, o llamando al *611 o 22074000. La comisión ' +
  " será cobrada a los clientes directamente a través del sistema TIGO MONEY en su cuenta. " +
  " </p> " +
  " <p><strong>Plazo</strong> " +
  " <br> " +
  " Las disposiciones de la solicitud de registro de dinero electrónico tendrán plena vigencia e iniciará a partir de la " +
  " creación del Registro Tigo Money y estarán vigentes hasta que el Cliente solicite la eliminación de su Registro Tigo " +
  " Money o hasta que el mismo sea inactivado por Tigo Money, de acuerdo a la solicitud de registro de dinero " +
  " electrónico. Los primeros 30 días hábiles de uso de servicio se considerarán una activación temporal, hasta que se " +
  " hayan verificado los requisitos establecidos en la cláusula 13. " +
  " </p> " +
  " <p><strong>Activación y Desactivaión del Servicio</strong> " +
  " <br> " +
  " La activación se considerará definitiva hasta que se hayan cumplido los La activación se considerará definitiva " +
  " hasta que se hayan cumplido los requisitos de ley y verificada la información proporcionada, dicha verificación " +
  " podrá realizarse en un periodo de 30 días hábiles posteriores a la suscripción del presente documento. El servicio " +
  " podrá ser desactivado por las siguientes razones: a) A solicitud del cliente, b) Por requerimiento de autoridad " +
  " competente, c) Por sospecha de realización de actividades ilícitas de cualquier naturaleza, especialmente las que " +
  " establece el artículo 6 de la Ley Contra el Lavado de Dinero y Activos, de conformidad al “Proceso de Bloqueo y " +
  " desbloqueo de cuentas de dinero electrónico Tigo Money” el cual puede ser consultado en <a " +
  '  href="https://www.tigo.com.sv/legales" style="color: #00c8ff;">https://www.tigo.com.sv/legales</a> artículo ' +
  " “Políticas y procedimientos Tigo Money”. d) Y si al realizar la verificación de identidad del Cliente resultare " +
  " información incompleta, incorrecta, incongruente o catalogada como de alto riesgo. " +
  " </p> " +
  " <p><strong>Inactividad y Prescripción de los Fondos</strong> " +
  " <br> " +
  " Tigo Money podrá inactivar el registro por falta de uso de más de 60 días, en cuyo caso el cliente siempre podrá " +
  " solicitar el monto registrado a su favor en la cuenta Tigo Money a través de los canales autorizados para este fin, " +
  " hasta la prescripción del mismo de conformidad con la Ley para Facilitar la Inclusión Financiera. " +
  " </p> " +
  " <p><strong>Validación de Datos</strong> " +
  " <br> " +
  " El cliente reconoce la validez de los datos y procedimientos generados por medios telemáticos o aplicación de " +
  " inteligencia informática. El Cliente acepta recibir publicidad y ofertas comerciales de acuerdo a las " +
  " características de su perfil y conforme a lo establecido en el art. 18 n) de la Ley de Protección al Consumidor. " +
  " Tigo Money tendrá los mecanismos necesarios para la protección de los datos de los clientes para la prestación de " +
  " los servicios Tigo Money. " +
  " </p> " +
  " <p><strong>Atención al Cliente</strong> " +
  " <br> " +
  " En el caso de que el Cliente tenga un reclamo o necesite atención al cliente respecto de la prestación de servicios " +
  " podrá realizarla a través del número 22074000 o al <em>611</em> y/o Tiendas Tigo, medios a través de los cuales se " +
  " le brindará tiempo de respuesta de acuerdo al tipo de reclamo presentado. Cualquier diferencia suscitada en la " +
  " solicitud de registro de dinero electrónico, será resuelta de conformidad a las políticas de protección al " +
  ' consumidor que pueden ser consultadas en la página web: <a href="https://www.tigo.com.sv/legales" ' +
  '  style="color: #00c8ff;">https://www.tigo.com.sv/legales</a> artículo “Políticas y procedimientos Tigo Money”. ' +
  " </p> " +
  " <p><strong>Tributos</strong> " +
  " <br> " +
  " El cliente será responsable de pagar el impuesto a la transferencia y a la prestación de servicios (IVA) que le " +
  " corresponda con relación al presente servicio. El cliente acepta que las comisiones a pagar podrán verse afectadas " +
  " por modificaciones o creación de nuevos tributos que fueren aplicables. " +
  " </p> " +
  " <p><strong>Legislación Aplicable y Domicilio</strong> " +
  " <br> " +
  " Las partes acuerdan que las presentes condiciones generales se rigen bajo la legislación de la República de El " +
  " Salvador, y los conflictos derivados de los mismos se sujetan a las reglas de jurisdicción y competencia del Código " +
  " Procesal Civil y Mercantil. " +
  " </p> " +
  ' <h3 style="color: #00377b; font-weight:400; font-size: 2.125rem " ' +
  ' id="términos-y-condiciones-para-la-solicitud-de-registro-de-dinero-electrónico-realizada-por-los-clientes-a-través-de-medios-digitales"> ' +
  " TÉRMINOS Y CONDICIONES PARA LA SOLICITUD DE REGISTRO DE DINERO ELECTRÓNICO REALIZADA POR LOS CLIENTES A TRAVÉS DE " +
  " MEDIOS DIGITALES</h3> " +
  " <p>Tigo Money pone a disposición de sus potenciales clientes (En adelante “el usuario” o “Usted”), el registro de " +
  " dinero electrónico a ser realizado mediante medios digitales, accediendo a su plataforma Tigo Money Web (<a " +
  '  href="https://money.tigo.com.sv/" style="color: #00c8ff;">https://money.tigo.com.sv/</a>) y App de Tigo Money. Al ' +
  " entrar en cualquiera de ambos aplicativos el usuario deberá dar click en “regístrate” y así dará inicio al flujo de " +
  " registro en línea para lo cual deberá completar el respectivo formulario de registro. En el cual se le pedirá llenar " +
  " sus datos personales, tomar fotografía al documento único de identidad y una posterior foto individual.</p> " +
  " <p>Al ingresar su solicitud para la creación del Registro de Dinero Electrónico a través de medios digitales, el " +
  " usuario acepta los siguientes términos y condiciones:</p> " +
  " <p>El Usuario debe ser mayor de edad, proveer información válida, completa y veraz, y mantener dicha información " +
  " actualizada. Usted declara y garantiza a Tigo Money: (a) que tiene plenos poderes y facultades, así como también la " +
  " capacidad legal para aceptar estos Términos y cumplir las obligaciones derivadas de los mismos así como de la " +
  " Solicitud de Registro de dinero electrónico; y (b) que le brindará información válida, completa y veraz a Tigo " +
  " Money. Usted reconoce y acepta que la utilización de los Portales que Tigo Money a puesto a su disposición para " +
  " llevar a cabo el registro de dinero electrónico es personal y será el único responsable de la información que " +
  " proporcione, por lo que reconoce y acepta que será responsable por la información falsa o incorrecta que " +
  " proporcione, incluso cuando la información proporcionada sea de un tercero con desconocimiento de éste o contra su " +
  " voluntad. Por ello, es su responsabilidad utilizar el presente Portal de manera lícita y personal. Usted consiente " +
  " que, de acuerdo a la normativa vigente, Tigo Money tiene la facultad de corroborar la veracidad de toda la " +
  " información proporcionada por usted.</p> " +
  " <p>El usuario declara por este medio que toda la información que ingrese en la referida solicitud es verídica y " +
  " autoriza para que SPDE MOBILE CASH S.A., pueda utilizar dicha información, así como la información que la acompaña " +
  " incluyendo la fotografía del documento de identidad, fotografía personal, información biométrica, firma digital y " +
  " número telefónico para el proceso de verificación y creación del Registro de Dinero Electrónico. </p> " +
  " <p>Tigo Money, se reserva el derecho de dar de baja o denegar la creación de Registros de Dinero Electrónico cuya " +
  " información fuere inconsistente, se determine que los documentos son falsos, que el registro esta siendo utilizado " +
  " para fines ilícitos, entre otras causales que determinaren los presentes términos o la Ley.</p> " +
  ' <h3 style="color: #00377b; font-weight:400; font-size: 2.125rem" id="políticas-de-pagos-con-tarjeta-de-crédito-o-débito">Políticas de Pagos ' +
  " con Tarjeta de Crédito o Débito</h3> " +
  " <p>TTodo cliente que cuente con un Registro de Dinero Electrónico Activo podrá realizar la carga de dinero electrónico " +
  " a su billetera Tigo Money utilizando tarjetas de crédito o débito emitidas en El Salvador bajo las marcas VISA y " +
  " MASTERCARD, llamando únicamente al <strong>2121-8429</strong> del Contact Center de TIGO. </p> " +
  " <p>En caso Tigo Money contaré con otros canales para la realización de carga de dinero electrónico por medio de " +
  " tarjetas de crédito será dado a conocer por este medio al Usuario, lo cual declara entender.</p> " +
  " <p>El monto máximo de cargas permitido a través del Contact Center es de: USD $150 por transacción, un total de " +
  " USD$250 diarios, y un acumulado de $600 por mes; o en su defecto el monto máximo que autorice el banco emisor al " +
  " tarjetahabiente. Los montos máximos de cargas diarias utilizando tarjeta de crédito es sin perjuicio de los límites " +
  " máximos establecidos en la Ley para Facilitar la Inclusión Financiera por montos acumulados, el monto máximo de " +
  " transacciones acumuladas en un mes y el saldo máximo del Registro Tigo Money será de cinco salarios mínimos urbanos " +
  " mensuales del sector comercio y servicios. Las transacciones realizadas en el Contact Center por medio de tarjeta de " +
  " débito o de crédito solo podrán ser realizadas por el Tarjetahabiente titular. Nos reservamos el derecho de rechazar " +
  " cualquier transacción o de restringir las cantidades de estas sin explicar los motivos, lo cual se indicará al " +
  " cliente. Las tarjetas aceptadas son Visa y Mastercard emitidas en bancos de El Salvador. Al realizarse la carga " +
  " exitosa correspondiente, se notificará al cliente al teléfono asociado al registro Tigo Money.</p> " +
  " <p><strong>Privacidad y seguridad:</strong> Toda la información que provee el cliente al momento de registrarse será " +
  " tratada con absoluta confidencialidad y privacidad. Tigo Money no podrá compartir esta o ningún tipo de información " +
  " relacionada a cualquier transacción a terceros no involucrados a menos que tenga autorización del cliente. </p> " +
  " <p><strong>Conformidad del usuario:</strong> Se constituye un acuerdo legal entre el cliente y MOBILE CASH dándose por " +
  " entendido que el cliente es quien ha declarado ser y es el propietario de los documentos de identidad y tarjetas de " +
  " crédito o débito utilizados en la o las transacciones realizadas, por lo que el mal uso de documentación será " +
  " constitutiva de las acciones legales pertinentes a que hubiere lugar, asimismo declara que la información " +
  " proporcionada es veraz y que el origen y destino de los fondos es lícito y sin relación alguna con los delitos del " +
  " art. 6 de la Ley Contra el Lavado de Dinero y Activos.</p> " +
  " <p>El cliente acepta que antes de realizar la presente transacción a través del Contact Center, ha revisado, entendido " +
  " y acordado los términos expuestos. En caso de inconformidad, el cliente deberá abstenerse de proporcionar " +
  " información personal o crediticia, así como de levantar algún requerimiento comercial.</p> " +
  ' <h3 style="color: #00377b; font-weight:400; font-size: 2.125rem" ' +
  ' id="términos-y-condiciones-para-cargas-de-dinero-electrónico-por-medio-de-bitcoins">TÉRMINOS Y CONDICIONES PARA ' +
  " CARGAS DE DINERO ELECTRÓNICO POR MEDIO DE BITCOINS</h3> " +
  " <p>Todo cliente que cuente con un Registro de Dinero Electrónico activo podrá realizar la carga de dinero electrónico " +
  " en dólares de los Estados Unidos de América a su billetera Tigo Money haciendo uso de Bitcoins, a través de la " +
  " interfaz que Tigo Money pondrá a su disposición en los siguientes medios: money.tigo.com.sv y Tigo Money App (ambos " +
  " en adelante “el Portal”), los cuales le permitirán generar las solicitudes de carga a su billetera, cuyo monto será " +
  " expresado en dólares de los Estados Unidos de América, para su posterior autorización de pago por medio de su " +
  " Billetera Bitcoin, para esto el cliente deberá ingresar en el Portal su usuario y correo electrónico asociado a su " +
  " Billetera Bitcoin, lo cual desplegará un código QR al cual podrá acceder para autorizar la transacción pertinente. " +
  " Luego de autorizada la transacción el cliente recibirá en su billetera de dinero electrónico el monto expresado en " +
  " dólares de los Estados Unidos de América, aun cuando la sesión del cliente en el Portal hubiere vencido. Para que la " +
  " transacción sea efectiva la billetera bitcoin utilizada, deberá tener en saldo la cantidad equivalente a los dólares " +
  " de los Estados Unidos de América que se han solicitado cargar a la billetera Tigo Money más el cobro de la comisión " +
  " o cargos que correspondan.</p> " +
  " <p>Nota: El servicio de carga de dinero electrónico por medio de bitcoin lleva implícito la utilización de proveedores " +
  " de servicios Exchange que permitirán la conversión automática de bitcoins a dólares de los Estados Unidos de " +
  " América, para ser acreditados en las billeteras de dinero electrónico de los clientes. Este servicio podrá generar " +
  " una tasa de cambio a la cual se le agregará un 1.05% de comisión. Adicionalmente podrán haber otros cargos que " +
  " podrán variar dependiendo de la billetera bitcoin utilizada y el valor cambiario del bitcoin al momento de la " +
  " transacción, dichos costos asociados están fuera del control de Tigo Money.</p> " +
  " <p>El monto máximo de transacciones diarias será de un salario mínimo urbano mensual del sector comercio y servicios y " +
  " el monto máximo de transacciones acumuladas en un mes y el saldo máximo del Registro Tigo Money será de cinco " +
  " salarios mínimos urbanos mensuales del sector comercio y servicios. El cliente deberá estar atento del cumplimiento " +
  " de los montos máximos de las transacciones diarias y acumuladas que alcanzare con las transacciones efectuadas desde " +
  " su billetera electrónica de conformidad con los límites establecidos. En caso de que al iniciar y solicitar el " +
  " procesamiento de una transacción de carga de dinero electrónico por medio de bitcoins ésta supere los límites antes " +
  " establecidos, el cliente acepta que Tigo Money no acredite en su billetera de dinero electrónico el monto total de " +
  " la transacción, lo cual dará lugar a la devolución del monto en dólares de los Estados Unidos de América al que " +
  " equivalían los bitcoins al momento que se intentó desarrollar la operación. Para iniciar el proceso de devolución " +
  " consulta en el portal la Política de Reclamos.</p> " +
  " <p>Las transacciones solo podrán ser realizadas por el titular de la billetera bitcoin por lo que al completar la " +
  " transacción el cliente declara que la billetera bitcoin utilizada es de su propiedad. Al realizarse la carga exitosa " +
  " correspondiente, se notificará al cliente al número de teléfono asociado al registro Tigo Money. En caso el cliente " +
  " solicitare alguna reversión de la transacción efectuada está deberá ser solicitada por los canales de atención al " +
  " cliente de Tigo Money en un plazo máximo de sesenta (60) días y si del análisis se determina que esta procede, será " +
  " realizada en dólares de los Estados Unidos de América según le fue acreditado en su billetera de dinero electrónico. " +
  " </p> " +
  " <p><strong>Conformidad del usuario:</strong> Al completar las transacciones en el portal, el usuario confirma y " +
  " ratifica que es quien ha declarado ser y es el propietario de los documentos de identidad y de la/s billetera/s " +
  " bitcoin utilizada/s en la/s transacciones realizadas, por lo que en caso de falsedad en su declaración será " +
  " responsable de las acciones legales pertinentes a que hubiere lugar</p> " +
  ' <h3 style="color: #00377b; font-weight:400; font-size: 2.125rem" id="prestación-de-servicios-de-colecturía-a-través-de-agentes-tigo-money"> ' +
  " PRESTACIÓN DE SERVICIOS DE COLECTURÍA A TRAVÉS DE AGENTES TIGO MONEY</h3> " +
  " <p>Pasos que realiza un usuario para proceder a realizar pagos de productos o servicios a través de un agente Tigo " +
  " Money:</p> " +
  " <ol> " +
  " <li>Cliente verifica si el colector se encuentra dentro de los comercios o instituciones afiliadas a Tigo Money. Los " +
  '  Colectores pueden ser consultados en <a href="http://www.ayuda.tigo.com.sv" ' +
  ' style="color: #00c8ff;">www.ayuda.tigo.com.sv</a>, opción Pago de Facturas. <br> </li> ' +
  ' <li>Cliente acude a un Agente Tigo Money los cuales pueden ser consultados en <a href="http://www.tigo.com.sv" ' +
  ' style="color: #00c8ff;">www.tigo.com.sv</a>, adicional puedes enviar un mensaje al 482 y enviar la palabra ' +
  "  Tigomoney unido y recibirás los agentes más cercanos a tu ubicación.</li> " +
  " </ol> " +
  " <p>Cliente indica el servicio y colector al que desea realizar el pago: " +
  " Servicios:</p> " +
  " <ul> " +
  " <li>Ventas de productos </li> " +
  " <li>Financieras </li> " +
  " <li>Proyectos residenciales </li> " +
  " <li>Aseguradoras </li> " +
  " <li>Donaciones </li> " +
  " <li>Otros</li> " +
  " </ul> " +
  ' <ol start="3"> ' +
  " <li>Cliente menciona el número de referencia de su colector:</li> " +
  " </ol> " +
  " <ul> " +
  " <li>NPE</li> " +
  " <li>Número de teléfono del cliente </li> " +
  " <li>Número de Anexo</li> " +
  " <li>Código de cliente</li> " +
  " </ul> " +
  ' <ol start="4"> ' +
  " <li>Cliente indica si el pago es Parcial o Total y la cantidad que desea pagar.<br> </li> " +
  " <li>Agente Tigo Money solicita número telefónico del cliente para poder remitirle el comprobante de pago. (Aplican " +
  "  términos y condiciones de Aviso de Privacidad Tigo Money).<br> </li> " +
  " <li>Cliente recibe comprobante de pago al número telefónico indicado por medio de mensaje de texto. <br></li> " +
  " </ol> " +
  ' <h3 style="color: #00377b; font-weight:400; font-size: 2.125rem" id="aviso-de-privacidad">AVISO DE PRIVACIDAD</h3> ' +
  ' <h3 style="color: #00377b; font-weight:400; font-size: 2.125rem" id="tigo-money">TIGO MONEY.</h3> ' +
  " <p><strong>SOCIEDAD PROVEEDORA DE DINERO ELECTRONICO MOBILE CASH, SOCIEDAD ANONIMA</strong>, que puede abreviarse " +
  " <strong>SPDE MOBILE CASH, S.A.</strong>, (en adelante “Tigo Money”, “nosotros” o “nuestro”), como entidad " +
  " responsable del tratamiento de los datos personales de los usuarios del portal web <a " +
  '  href="http://www.money.tigo.com.sv" style="color: #00c8ff;">www.money.tigo.com.sv</a> y <a ' +
  '  href="http://www.tigomoney.com.sv" style="color: #00c8ff;">www.tigomoney.com.sv</a> (“el Portal”), así como de los ' +
  " canales para el proceso de solicitud de registro de dinero electrónico por medio digitales y demás servicios Tigo " +
  " Money, reconoce la importancia de proteger la privacidad y confidencialidad de los datos de los usuarios (en " +
  " adelante “el usuario” o “usted”), y le ofrece este Aviso de Privacidad (el “Aviso”), diseñado en cumplimiento con " +
  " nuestras políticas globales de protección de datos, y en cumplimiento con las leyes y normas concordantes de El " +
  " Salvador. </p> " +
  " <p>A través del Portal, Tigo Money puede recolectar cierta información sobre usted. Este Aviso le informa acerca del " +
  " tratamiento de sus datos personales, en particular (1) qué datos recopilamos sobre usted, (2) cómo los recopilamos, " +
  " (3) con qué finalidad, (4) cómo puede acceder a ellos o rectificarlos, (5) con quién se comparten, (6) por cuánto " +
  " tiempo se conservan, (7) qué ley aplica, y (8) cuáles son los datos de contacto de Tigo Money, como responsable del " +
  " tratamiento de sus datos personales. </p> " +
  " <p><strong>Usted autoriza a Tigo Money a que sus datos personales, incluidos los datos de pago y financieros o los " +
  "  utilizados para enviarle publicidad, sean tratados bajo los términos del presente Aviso.</strong> En caso de " +
  " conflicto entre las disposiciones del Aviso y otras políticas o términos y condiciones disponibles a los usuarios a " +
  " través de otros medios, prevalecerá este Aviso. Para conocer los términos aplicables al uso del Portal, le sugerimos " +
  " consulte nuestros &lt;&lt;Términos y Condiciones&gt;&gt;.</p> " +
  " <p>Es posible que Tigo Money realice cambios al Aviso de manera periódica. En el evento en que realicemos cambios " +
  " sustanciales al Aviso, nosotros publicaremos dichos cambios a través del Portal. Usted podrá determinar cuándo fue " +
  ' la última revisión del Aviso en el "historial de versiones" al final del documento. </p> ' +
  " <p><strong>1. ¿Qué datos personales recopilamos sobre usted?</strong></p> " +
  " <p>Con el fin de ofrecerle productos o servicios, o acceso a nuestro Portal, Tigo Money puede recopilar los siguientes " +
  " datos personales:</p> " +
  " <ul> " +
  " <li><strong>información de registro:</strong> su nombre, número de teléfono, dirección de correo electrónico, " +
  "  documentos de identidad, fotografía personal, información de pago y financiera, dirección de facturación o usuario " +
  "  y contraseña.</li> " +
  " <li><strong>información sobre el uso del Portal:</strong> la dirección de red y el sistema operativo de su " +
  "  computadora, tipo de navegador utilizado, el sitio web desde el cual se vinculó a nuestra página, su actividad en " +
  "  nuestro Portal, así como el historial de visualización, la hora y la fecha en la cual lo visitó y compró productos " +
  "  y servicios a través del Portal.</li> " +
  " <li><strong>información sobre su ubicación.</strong></li> " +
  " <li><strong>información de aplicaciones móviles</strong> según los sitios web visitados y las aplicaciones " +
  "  descargadas en la red Tigo Money.</li> " +
  " </ul> " +
  " <p>Asimismo, Tigo Money le informa que no solicita ni procesa, dentro de su conocimiento, datos personales de menores " +
  " de 18 años.</p> " +
  " <p><strong>2. ¿Cómo recopilamos sus datos personales?</strong></p> " +
  " <p>Sus datos personales los obtenemos o bien porque usted nos los proporciona directamente (ej. se registra a través " +
  " de nuestro Portal o se suscribe para recibir noticias e información de Tigo Money, o nos contacta por cualquier otra " +
  " situación vía correo electrónico, teléfono, fax, o por medio escrito o personal en cualquiera de nuestros locales " +
  " comerciales u oficinas), o porque los obtenemos a través de terceros, o a través de cookies u otras tecnologías. " +
  " </p> " +
  " <p>Las “cookies” y tecnologías similares son un pequeño fichero de letras y cifras que se almacena en el navegador del " +
  " usuario al acceder a determinadas páginas web, y que Tigo Money puede utilizar con el objetivo de mejorar su " +
  " experiencia y navegación en el Portal, obtener información " +
  " estadística sobre sus hábitos de navegación, y ofrecerles publicidad adaptada a sus preferencias, entre otros. Las " +
  " “cookies” pueden ser de sesión, que se eliminarán al terminar la sesión, o persistentes, que permanecerán en su " +
  " ordenador o dispositivo durante un periodo de tiempo determinado. Modificando las preferencias de su explorador web, " +
  " usted tiene la opción de aceptar todas la “cookies”, de ser notificado cuando se instalan, o de deshabilitarlas. No " +
  " obstante, si usted elige rechazar las “cookies”, es posible que algunas funciones del Portal no estén disponibles " +
  " para su utilización o que su funcionamiento no sea óptimo.</p> " +
  " <p><strong>3. ¿Con qué finalidad tratamos sus datos personales?</strong></p> " +
  " <p>Tigo Money puede tratar sus datos personales para las siguientes finalidades:</p> " +
  " <ul> " +
  " <li><strong>Adecuada prestación de los servicios:</strong> Comprende toda actividad dirigida a analizar y " +
  "  desarrollar la factibilidad y ejecución del servicio del Portal, en aspectos tales como: determinación de " +
  "  consumos, mantenimiento y mejoramiento de servicios y del Portal web, atención al cliente, personalización del " +
  "  contenido, servicios y ofertas, planes comerciales, satisfacción del cliente, creación de bases de datos, análisis " +
  "  de información y datos, creación de indicadores clave de rendimiento de aplicación (KPI), facturación, seguridad, " +
  "  garantizar el control de calidad y, en general, toda información indispensable para cumplir con nuestros contratos " +
  "  de productos o servicios, la regulación y la normatividad vigente aplicable.</li> " +
  " <li><strong>Fines Comerciales de Tigo Money:</strong> Comprende toda actividad encaminada a presentar ofertas, " +
  "  promociones, productos, anuncios, publicidad, oportunidades, sorteos, campañas, programas de lealtad, " +
  "  fidelización, retención de clientes y, en general, información de productos y servicios nuevos, ya solicitados o " +
  "  contratados, o que puedan ser de interés de los clientes y usuarios.</li> " +
  " <li><strong>Relacionamiento con público de interés.</strong> Comprende toda actividad orientada al manejo de las " +
  "  relaciones con nuestras afiliadas y subsidiarias, accionistas, autoridades y comunidad en general, de conformidad " +
  "  con el marco jurídico vigente, en desarrollo de la gestión empresarial para el adecuado cumplimiento del objeto " +
  "  social.</li> " +
  " <li><strong>Fines legales:</strong> comprende todo tratamiento justificado por la necesidad de cumplir con los " +
  "  requisitos legales aplicables, como son el atender un requerimiento de una entidad gubernamental o administrativa " +
  "  en ejercicio de sus funciones o por orden escrita de autoridad judicial competente, o para proteger los derechos, " +
  "  la propiedad o la seguridad de Tigo Money, nuestros clientes, empleados y el público en general.</li> " +
  " </ul> " +
  " <p><strong>4. ¿Cómo puede acceder o rectificar sus datos personales?</strong></p> " +
  " <p>Usted puede elevar consultas con relación a sus datos personales, así como solicitar el acceso o rectificación de " +
  " sus datos personales a Tigo Money. Puede contactarnos a través de la información de contacto disponible al final de " +
  " este Aviso.</p> " +
  " <p><strong>5. ¿Con quién compartimos sus datos personales?</strong></p> " +
  " <p>En cumplimiento con la normativa vigente, Tigo Money solicita su consentimiento para comunicar o transferir sus " +
  " datos personales a terceros proveedores (por ejemplo, para almacenamiento y/o análisis de datos), a otras entidades " +
  " filiales a Tigo Money, específicamente a TELEMOVIL EL SALVADOR, S.A. DE C.V. o en caso de una fusión, adquisición, " +
  " venta de activos de la compañía o transición del servicio a otro proveedor. Además de lo anterior, Tigo Money puede " +
  " comunicar o transferir sus datos por orden escrita de autoridad judicial competente o cuando así sea permitido o " +
  " requerido en cumplimiento con la ley. Asimismo, Tigo Money puede compartir o transferir sus datos personales con " +
  " otras jurisdicciones que cuentan con leyes de protección de datos diferentes a las establecidas en El Salvador.</p> " +
  " <p><strong>6. ¿Por cuánto tiempo se guardan sus datos personales?</strong></p> " +
  " <p>Tigo Money guarda sus datos personales únicamente por el período de tiempo que sea necesario para cumplir con la " +
  " finalidad por la cual se recopilaron, y conforme a lo requerido por la regulación local vigente.</p> " +
  " <p><strong>7. Ley Aplicable y Jurisdicción</strong></p> " +
  " <p>La interpretación, uso, alcance y terminación de este Aviso se rige por las leyes de El Salvador y, en caso de " +
  " disputa, se somete a los jueces y tribunales ubicados en la ciudad de San Salvador.</p> " +
  " <p><strong>8. Datos de Contacto de Tigo Money</strong></p> " +
  " <p><strong>Nombre:</strong> SPDE MOBILE CASH, S.A.<br> " +
  " <strong>NIT:</strong> 0614-030311-101-6<br> " +
  " <strong>Dirección:</strong> Km. 16.5 Carretera al Puerto de La Libertad, Tuscania Business Park, Campus Tigo, " +
  " Zaragoza, La Libertad, El Salvador.<br> " +
  " <strong>Domicilio:</strong> El Salvador<br> " +
  " </p> " +
  " <p><strong>9. Historial de Versiones</strong></p> " +
  ' <table id="table-sv1QVS5sFadPd1tIjA2Im4gh-0" style="border:1px solid #e5eaf2; background-color: #fff; line-height: 1.5; ' +
  " width: 100%; " +
  "  border: 1px solid #e5eaf2; " +
  "  background-color: #fff; " +
  "  border-collapse: separate; " +
  "  text-indent: initial; " +
  "  border-spacing: 2px; " +
  '  display: table;"> ' +
  " <thead> " +
  "  <tr> " +
  ' <th class="sort is-active" data-text="Versión" id="table-sv1QVS5sFadPd1tIjA2Im4gh-0-0" ' +
  '  data-table="#table-sv1QVS5sFadPd1tIjA2Im4gh-0" data-th="0" data-order="asc" style=" border-bottom: 2px solid #00377b; color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;"> ' +
  '  <div class="d-flex"> ' +
  '  <span class="mr-auto">Versión</span> ' +
  "  </div> " +
  " </th> " +
  ' <th class="sort" data-text="Fecha de Cambio" id="table-sv1QVS5sFadPd1tIjA2Im4gh-0-1" ' +
  '  data-table="#table-sv1QVS5sFadPd1tIjA2Im4gh-0" data-th="1" data-order="" style=" border-bottom: 2px solid #00377b; color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;"> ' +
  '  <div class="d-flex"> ' +
  '  <span class="mr-auto">Fecha de Cambio</span> ' +
  "  </div> " +
  " </th> " +
  ' <th class="sort" data-text="Descripción" id="table-sv1QVS5sFadPd1tIjA2Im4gh-0-2" ' +
  '  data-table="#table-sv1QVS5sFadPd1tIjA2Im4gh-0" data-th="2" data-order="" style=" border-bottom: 2px solid #00377b; color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;"> ' +
  '  <div class="d-flex"> ' +
  '  <span class="mr-auto">Descripción</span> ' +
  "  </div> " +
  " </th> " +
  "  </tr> " +
  " </thead> " +
  " <tbody> " +
  "  <tr> " +
  ' <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">1</td> ' +
  ' <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">7 de Abril, 2020</td> ' +
  ' <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Creación del Aviso</td> ' +
  "  </tr> " +
  "  <tr> " +
  ' <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">2</td> ' +
  ' <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">3 de septiembre, 2021</td> ' +
  ' <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Cambio en cláusula 5 del Aviso</td> ' +
  "  </tr> " +
  " </tbody> " +
  " </table> " +
  "</div> ";

export default termsSV;
