const termsBO =
  '<div style="font-family: Roboto,sans-serif; font-weight:400; color: #787878; line-height: 1.5; padding: 15px; padding-left:0px;"> ' +
  ' <h4 style="color: #00c8ff; font-weight:400; " align="center"><strong>TÉRMINOS Y CONDICIONES DEL SERVICIO E-FECTIVO ESPM S.A. – TIGO MONEY</strong></h4> ' +
  " <ol> " +
  "  <li><strong><em>OBJETO DEL SERVICIO TIGO MONEY. </em></strong><strong><em>-</em></strong><em> Prestar un " +
  " Servicio financiero que ofrece acceso mediante un número de línea telefónica donde el cliente puede " +
  " abrir una cuenta de pago exclusivamente en moneda nacional. Esta cuenta o billetera móvil permite " +
  " realizar: transacciones en puntos de atención financiera o banca electrónica, transacciones de pago en " +
  " comercios afiliados y todas las transacciones publicadas en el Tarifario de Servicios expuestas en la " +
  " cláusula 6 de este contrato,conforme a disposiciones legales y reglamentarias vigentes, así como " +
  " políticas de la empresa, que se encuentran publicados para el cliente, en la página web de Tigo ( </em> " +
  '   <em><a href="https://ayuda.tigo.com.bo/hc/es/articles/360052883834" style="color: #00c8ff;">CENTRO DE AYUDA</a>) dentro de la opción ' +
  " Tigo Money </em> " +
  "  </li> " +
  "  <li><strong><em>ACTIVACIÓN</em></strong><strong><em> DEL SERVICIO Y CONDICIONES DE USO. -</em></strong><em>Para " +
  " estar afiliado al servicio, el cliente debe ser mayor de edad y tener una línea activa de cualquier " +
  " operadora de telefonía móvil.<br> " +
  " El cliente podrá abrir una cuenta de pago mediante el autoregistro desde su dispositivo móvil ingresando " +
  " por diferentes canales de autogestión comunicado en nuestro </em><a " +
  ' href="https://ayuda.tigo.com.bo/hc/es/articles/360046767454--C%C3%B3mo-puedo-crear-una-cuenta-Tigo-Money-" style="color: #00c8ff;"><em>CENTRO ' +
  "  DE AYUDA</em></a><em>&nbsp; o apersonándose a oficinas Tigo para solicitar su afiliación portando su " +
  " documento de identidad.</em></li> " +
  "  <em>El cliente que se afilie al servicio firmando un contrato físico se regirá a los limites operacionales del " +
  "   cliente nivel 2 (cláusula 5 de este contrato).</em><br> " +
  "  <em>El cliente que se afilie mediante el proceso de autoregistro permanecerá con límite operacional nivel " +
  "   1(cláusula 5 de este contrato); una vez validada su identidad mediante el proceso establecido por E-FECTIVO " +
  "   ESPM S.A., el cliente pasará a nivel 2 (cláusula 5 de este contrato) </em><br> " +
  "  <em>Para validar la identidad del cliente, éste deberá al menos realizar un servicio de Retiro de Dinero en un " +
  "   Punto Tigo Money o pasar por oficinas Tigo solicitando la ampliación de su nivel, en ambos casos presentando " +
  "   su documento de identidad.</em><br> " +
  "  <em>Los fondos almacenados en la billetera móvil de un cliente no constituyen un depósito, por lo que no generan " +
  "   intereses y se encuentra respaldado en su totalidad (100%) por fideicomisos constituidos en entidades " +
  "   financieras regulada por ASFI.</em><br> " +
  "  <em>Todas las transacciones se consideran válidas, legítimas y autorizadas por el propio cliente, por lo que no " +
  "   pueden ser desconocidas, negadas, revertidas o anuladas por quien las generó o por quien las " +
  "   recibió.</em><br> " +
  "  <em>Un cliente Tigo Money accede al servicio mediante un dispositivo móvil, donde el cliente ya define un código " +
  "   de ingreso de cuatro dígitos secretos o lo que llamamos PIN (número de identificación personal), al momento " +
  "   de la creación de la billetera móvil. Este PIN sustituye a la firma autógrafa del cliente para todos los " +
  "   fines de Ley, será requerido al momento de la activación y en cada transacción que realice y que podrá " +
  "   cambiarlo cuantas veces lo desee siguiendo los procedimientos de E-FECTIVO ESPM S.A.</em><br> " +
  "  <em>Al hacer uso del servicio Tigo Money, el cliente declara que la información consignada durante el proceso de " +
  "   afiliación es fidedigna y que es el titular y portador del documento de identificación consignado.</em> " +
  "  <li><strong><em>OPERACIONES AUTORIZADAS. -</em></strong><strong></strong></li> " +
  "  <ul> " +
  "   <li><em>Cargas o abonos de dinero electrónico a una Billetera Móvil.</em></li> " +
  "   <li><em>Pagos por la compra de bienes o servicios.</em></li> " +
  "   <li><em>Efectivizaciones o retiros de dinero electrónico desde una Billetera Móvil.</em></li> " +
  "   <li><em>Transferencia de dinero electrónico de cuentas de pago a otras cuentas de pago, líneas de crédito o " +
  "  para depósito a cuentas corrientes o cuentas de ahorro y viceversa.</em></li> " +
  "   <li><em>Envío de dinero desde la billetera móvil a un carné de identidad.</em></li> " +
  "   <li><em>Consulta sobre saldos, transacciones y otras relacionadas con el servicio</em></li> " +
  "   <li><em>Otras operaciones que sean autorizadas por la ASFI.</em></li> " +
  "  </ul> " +
  "  <p><em>Las cargas de dinero (abonos) y efectivizaciones de dinero (convertir en físico el dinero electrónico), " +
  " podrán realizarse a través de los puntos de atención financiera habilitados a nivel nacional por " +
  " E-FECTIVO ESPM S.A, previa presentación del documento de identificación del cliente.</em><br> " +
  "   <em>Los Puntos de Atención Financiera habilitados por E-FECTIVO ESPM S.A. así como los servicios disponibles " +
  " a cargo de éstos, se encuentran detallados en la página web de Tigo opción TIGO MONEY, y en los canales " +
  " de acceso al servicio comunicados en </em><a " +
  ' href="https://ayuda.tigo.com.bo/hc/es/articles/115013240947" style="color: #00c8ff;"><em>CENTRO DE AYUDA</em></a><em>.</em> ' +
  "  </p> " +
  "  <li><strong><em>SERVICIOS ADICIONALES. -</em></strong></li> " +
  "  <ul> " +
  "   <li><em>Modificación y/o actualización de Datos personales</em></li> " +
  "   <li><em>Bloqueo/desbloqueo de Billetera Móvil</em></li> " +
  "   <li><em>Emisión de Extracto de Cuenta</em></li> " +
  "   <li><em>Impresión de facturas</em></li> " +
  "   <li><em>Cambio de PIN por intentos fallidos</em></li> " +
  "   <li><em>Cierre de Billetera Móvil </em></li> " +
  "   <li><em>Cambio de datos para facturación</em></li> " +
  "   <li><em>Débito automático para el pago de bienes y servicios</em></li> " +
  "  </ul> " +
  "  <p><em>Para acceder a los servicios descritos, el CLIENTE deberá apersonarse por las oficinas o los canales " +
  " habilitados por E-FECTIVO ESPM S.A. a nivel nacional y presentar previamente su documento de " +
  " identificación. Las oficinas y canales habilitados serán debidamente comunicados a través de la página " +
  " web. </em><br> " +
  "   <em>Para el servicio de débito automático, el cliente recibirá un mensaje y el servicio quedará habilitado " +
  " una vez introduzca su PIN en su dispositivo móvil en señal de aceptación. Esto opera cuando el cliente " +
  " cuenta con saldo suficiente en su Billetera Móvil y dentro de los límites autorizados.</em> " +
  "  </p> " +
  "  <li><strong><em>LÍMITES TRANSACCIONALES. -</em></strong><em> El cliente podrá realizar transacciones por un " +
  " monto límite por operación y que se encuentra definido por el Banco Central de Bolivia.<br> " +
  " Adicionalmente, E-FECTIVO ESPM S.A. aplicará límites acumulados diarios, semanales y mensuales de " +
  " acuerdo con el perfil de riesgo del cliente. Estos limites se encuentran comunicados en </em><a " +
  ' href="https://ayuda.tigo.com.bo/hc/es/articles/360046753674-Montos-m%C3%ADnimos-y-m%C3%A1ximos-por-transacci%C3%B3n-y-nivel-Tigo-Money" style="color: #00c8ff;"><em>CENTRO ' +
  "  DE AYUDA</em></a> " +
  "   <br><em>El usuario “autoregistrado” (cliente nivel 1) NO podrá realizar transferencias bancarias ni retiros " +
  " en Cajeros Tigo Money, hasta que complete su proceso de registro (cliente nivel 2)</em> " +
  "  </li> " +
  "  <li><strong><em>COMISIONES – TARIFARIO. -</em></strong><em> E-FECTIVO ESPM S.A. cobrará a su cliente cargos y " +
  " comisiones, publicados oficialmente en: oficinas de Atención al cliente de Tigo, Puntos Tigo Money, " +
  " Aplicación Móvil, Aplicación Web y en la opción Tigo Money de la página web: </em><a " +
  ' href="http://www.tigo.com.bo" style="color: #00c8ff;"><em>www.tigo.com.bo</em></a><em> y </em><a ' +
  ' href="https://www.tigo.com.bo/tigo-money/tarifas" style="color: #00c8ff;"><em>Tarifas</em></a></li> ' +
  "  <li><strong><em>&nbsp;SERVICIOS HABILITADOS POR TIPO DE OPERADORA TELEFÓNICA Y CANAL DE ACCESO. " +
  "  -</em></strong><em>El Cliente tendrá habilitados los SERVICIOS habilitados por CANALES y dependiendo " +
  " la operadora a la que pertenezca su línea telefónica asociada a su billetera móvil, estos servicios se " +
  " encuentran publicados en </em> <a " +
  ' href="https://ayuda.tigo.com.bo/hc/es/articles/360047382273" style="color: #00c8ff;"><em>CENTRO DE AYUDA</em></a> ' +
  "   <br><em>Las facturas por los cargos y comisiones cobrados por E-FECTIVO ESPM están disponibles para el " +
  " cliente en todos los Puntos de Atención Financiera, habilitados como Puntos Tigo Money que se encuentran " +
  " detallados en la página web de Tigo Money, en la aplicación web, en la aplicación móvil y marcando *555# " +
  " opción “Punto más cercano”</em> " +
  "  </li> " +
  "  <li><strong><em>PLAZO. -</em></strong><em>Se puede tener la billetera móvil de manera indefinida, pero E-FECTIVO " +
  " ESPM S.A.podrá inhabilitar la cuenta antes, si no cumple los términos y condiciones de este contrato, " +
  " también podrá inhabilitar su cuenta por razones justificadas en sus políticas internas de inactividad o " +
  " por otras que se considere admitidas y por medidas de prevención en materia de lavado de dinero y " +
  " financiamiento al terrorismo, en cumplimiento de la regulación vigente.</em></li> " +
  "  <li><strong><em>DERECHOS Y OBLIGACIONES DEL CLIENTE. –</em></strong></li> " +
  "  <p><em><u>DERECHOS DEL CLIENTE</u><br> " +
  " 1) Recibir trato equitativo, sin discriminación por razones de edad, género, raza, religión o identidad " +
  " cultural.<br> " +
  " 2) Recibir información fidedigna, amplia, íntegra, clara, comprensible, oportuna y accesible sobre las " +
  " características del servicio. <br> " +
  " 3) Formular reclamos y efectuar consultas, peticiones y solicitudes a través de los canales " +
  " correspondientes.<br> " +
  " 4) Gozar de confidencialidad, salvo excepciones contenidas en la Ley de Servicios Financieros.<br> " +
  " 5) Poder finalizar anticipadamente su relación con E-FECTIVO ESPM S.A.<br> " +
  " 6) Poder beneficiarse con servicios adicionales asociados a la Billetera Móvil ofrecidos por E-FECTIVO " +
  " ESPM S.A.<br> " +
  " 7) Gozar de otros derechos reconocidos por disposiciones legales y reglamentarias.</em><br> " +
  "   <em><u>OBLIGACIONES DEL CLIENTE</u></em><br> " +
  "   <em>1) Proporcionar información correcta y veraz respecto a su identidad, tanto en la afiliación del " +
  " servicio, como en el uso de este mientras se mantenga vigente.<br> " +
  " 2) Usar en forma personal su cuenta de pago, responsabilizándose por todas las transacciones que realice " +
  " con ésta, no admitiendo a terceros a actuar en su nombre para realizar transacciones a través del " +
  " servicio Tigo Money con fondos o bienes provenientes de actividades ilícitas.</em><br> " +
  "   <em>3) Verificar y brindar correctamente los números de destino y los montos a cargar, transferir y " +
  " efectivizar al operador del Punto de Atención Financiera.<br> " +
  " 4) Mantener vigente su línea de telefonía móvil mientras el servicio Tigo Money este activo. <br> " +
  " 5) Reintegrar a E-FECTIVO ESPM S.A los valores que se acrediten de manera equivocada en su cuenta de " +
  " pago. <br> " +
  " 6) Cumplir con las políticas, procedimientos y los plazos establecidos por E-FECTIVO ESPM S.A., " +
  " relacionados con el funcionamiento del servicio, que se encuentran publicados en la página web de Tigo, " +
  " dentro de la opción Tigo Money (</em><a " +
  ' href="https://ayuda.tigo.com.bo/hc/es/articles/360052883834" style="color: #00c8ff;"><em>CENTRO DE AYUDA</em></a><em>)</em><br> ' +
  "   <em>7) Asegurar que los fondos o valores enviados y recibidos a través del servicio Tigo Money estarán " +
  " dentro de los límites establecidos y éstos no tendrán un origen o destino ilícito que se encuentren " +
  " relacionados con los delitos generadores de Lavado de Dinero y de Activos y/o Financiamiento al " +
  " Terrorismo establecidos en la regulación local aplicable.<br> " +
  " 8) Suministrar información fidedigna y actualizada.</em><br> " +
  "   <em>9)</em> <em>Realizar transacciones en moneda local, siendo únicamente válidas las reconocidas por el " +
  " Banco Central de Bolivia</em> " +
  "  </p> " +
  "  <li><strong><em>DERECHOS Y OBLIGACIONES DE E-FECTIVO. - </em></strong><em>Son derechos y obligaciones de " +
  " E-FECTIVO ESPM S.A:<strong></strong></em> " +
  "   <p><em><u>DERECHOS DE E-FECTIVO ESPM SA.<br> " +
  "  </u>1) Recibir información fidedigna y oportuna del cliente.<br> " +
  "  2) Percibir el pago por las comisiones pactadas.</em><br> " +
  " <em><u>OBLIGACIONES DE E-FECTIVO ESPM SA.</u></em><br> " +
  " <em>1) Entregar un comprobante al CLIENTE por cada operación realizada en los Puntos de Atención " +
  "  Financiera. <br> " +
  "  2) Publicar en lugares visibles en cada uno de los Puntos de Atención Financiera y otros medios de " +
  "  difusión los horarios de atención, las modalidades de uso, los montos máximos permitidos por " +
  "  transacción, los cargos y comisiones por mantenimiento y uso del servicio de billetera móvil.<br> " +
  "  3) Atender los reclamos verbales y/o escritos por causa de un desacuerdo, perjuicio, daño o agravio " +
  "  en el uso del servicio o por denuncias de robo, clonación o pérdida.<br> " +
  "  4) Comunicar al cliente respecto al débito y las razones que motivan el mismo, cuando se reviertan " +
  "  valores que han sido acreditados de manera errónea en su cuenta de pago.</em> " +
  "   </p> " +
  "  </li> " +
  "  <li><strong><em>RESPONSABILIDAD DEL CLIENTE. -</em></strong><em> Cualquier operación errónea efectuada por el " +
  " cliente a través del servicio Tigo Money, será de responsabilidad exclusiva del CLIENTE, quedando " +
  " E-FECTIVO ESPM S.A. liberado de toda restitución o responsabilidad alguna por dicha operación. </em> " +
  "  </li> " +
  "  <p><em>Sin embargo, hasta antes de la efectivización del monto enviado y de acuerdo con el procedimiento " +
  " institucional aprobado para este efecto, E-FECTIVO ESPM S.A. realizará las gestiones que estén a su " +
  " alcance para que el destinatario de los fondos erróneamente transferidos pueda restituir los mismos al " +
  " cliente.</em><br> " +
  "   <em>El cliente exime a E-FECTIVO ESPM. de toda responsabilidad que se derive por cualquier tipo de " +
  " información errónea, falsa o inexacta que hubiere presentado o de cualquier violación legal, mientras se " +
  " mantenga la relación contractual. </em><br> " +
  "   <em>Asimismo, el cliente declara que a la fecha no se le ha comprobado judicialmente participación en " +
  " actividades relacionadas con el narcotráfico y delitos conexos, de lavado de dinero, de activos y " +
  " financiamiento al terrorismo, tanto en la jurisdicción nacional o en el extranjero.</em> " +
  "  </p> " +
  "  <li><strong><em>USO DEL PIN Y CÓDIGO DE SEGURIDAD. –</em></strong></li> " +
  "  <p><em>El uso del PIN para realizar las operaciones con la Billetera Móvil sustituye a la firma autógrafa del " +
  " cliente para todos los fines de Ley.</em><br> " +
  "   <em>Toda transacción realizada por el cliente será considerada válida, legítima y autorizada por el propio " +
  " CLIENTE.</em><br> " +
  "   <em>En canales digitales (App y WebApp) se adiciona el CÓDIGO DE SEGURIDAD o DOBLE FACTOR DE AUTENTICACIÓN " +
  " (2FA) para completar transacciones monetarias.</em><br> " +
  "   <em>El cliente debe tomar las medidas de seguridad necesarias para resguardar su Número de Identificación " +
  " Personal (PIN) y CÓDIGO DE SEGURIDAD; y como único responsable de su uso y confidencialidad, se obliga a " +
  " no revelarlo a terceras personas y cambiar el PIN frecuentemente.</em><br> " +
  "   <em>El cliente podrá solicitar a E-FECTIVO ESPM S.A. el cambio de PIN de acuerdo con a los procedimientos y " +
  " políticas internas de la empresa vigentes. (publicadas en el </em><a " +
  ' href="https://ayuda.tigo.com.bo/hc/es/articles/360038643194-Tips-de-seguridad-para-utilizar-tu-cuenta-Tigo-Money" style="color: #00c8ff;"><em>CENTRO ' +
  "  DE AYUDA</em></a><em>, dentro de la página web de Tigo: </em><a " +
  ' href="http://www.tigo.com.bo" style="color: #00c8ff;">www.tigo.com.bo</a><em> opción TIGO MONEY)</em><br> ' +
  "   <em>Si su saldo supera al monto máximo permitido para ‘reseteo’ mediante canales no presenciales, deberá " +
  " dirigirse a oficinas con su Carné de Identidad a solicitar el ‘reseteo’ de su clave o PIN.</em> " +
  "  </p> " +
  "  <li><strong><em>RESTRICCIONES DEL SERVICIO. -</em></strong><em> E-FECTIVO ESPM S.A podrá suspender o restringir " +
  " en forma temporal o definitiva el uso de la Billetera Móvil o la cuenta o cuentas asociadas a la misma " +
  " por las siguientes razones:</em></li> " +
  "  <ol> " +
  "   <li><em>Por razones de seguridad, cuando se produzcan tres intentos fallidos y consecutivos en el ingreso " +
  "  del PIN o clave secreta. </em></li> " +
  "   <li><em>Ante la eventual detección de transacciones inusuales que puedan afectar al cliente, previa " +
  "  comunicación al mismo conforme a procedimiento de E-FECTIVO ESPM S.A.</em></li> " +
  "   <li><em>Otras restricciones dispuestas por autoridades judiciales o administrativas mediante la Autoridad de " +
  "  Supervisión del Sistema Financiero ASFI.</em></li> " +
  "  </ol> " +
  "  <p><em>Para solicitar el desbloqueo de los casos 1 y 2, el cliente deberá apersonarse a cualquiera de las " +
  " oficinas de atención al cliente habilitadas a nivel nacional por E-FECTIVO ESPM S.A. debiendo presentar " +
  " su documento de identidad.</em><br> " +
  "   <em>Para el caso 3, la restricción se levantará con orden de la misma autoridad judicial o administrativa " +
  " que impuso esta limitación. </em> " +
  "  </p> " +
  "  <li><strong><em>PROCEDIMIENTO DE REPORTE Y BLOQUEO POR EXTRAVÍO, ROBO O HURTO. - </em></strong><em>El cliente, " +
  " en caso de ser víctima de pérdida, sustracción, hurto, robo o apropiación indebida, del dispositivo " +
  " móvil mediante el cual se opera este servicio, debe notificar inmediatamente a E-FECTIVO ESPM S.A, a " +
  " través de la línea gratuita con el fin de bloquear la cuenta de pago. La falta de notificación libera a " +
  " E-FECTIVO ESPM S.A. de toda responsabilidad. </em></li> " +
  "  <li><strong><em>GARANTÍA</em></strong><strong><em> DEL DINERO ELECTRÓNICO. -</em></strong><em> El dinero " +
  " electrónico almacenado en la cuenta de pago del cliente se encuentra respaldado en su totalidad por un " +
  " fideicomiso constituido en una entidad de intermediación financiera regulada por la ASFI.</em></li> " +
  "  <li><strong><em>FALLECIMIENTO DEL TITULAR. -</em></strong><em> En caso de fallecimiento del cliente, los " +
  " herederos podrán disponer de los fondos existentes en la cuenta de pago, previa presentación de la " +
  " declaratoria de herederos, acorde a las leyes bolivianas vigentes.</em></li> " +
  "  <li><strong><em>MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES. -</em></strong><em> Cualquier modificación a los " +
  " presentes Términos y Condiciones será de conocimiento del cliente, mediante comunicación en prensa o en " +
  ' la opción Tigo Money de la página web: </em><a href="http://www.tigo.com.bo" style="color: #00c8ff;">www.tigo.com.bo</a></li> ' +
  "  <li><strong><em>AUTORIZACIÓN</em></strong><strong><em> EXPRESA. -</em></strong><em> El cliente autoriza a " +
  " E-FECTIVO ESPM S.A a solicitar y verificar su información ante autoridades competentes e instituciones " +
  " públicas o privadas.<br> " +
  " Asimismo, autoriza expresamente a E-FECTIVO ESPM S.A. a enviar información acerca de características y " +
  " condiciones de los premios, promociones u ofertas vigentes relacionadas con E-FECTIVO ESPM S.A. mediante " +
  " correo electrónico, llamadas telefónicas, mensajes de texto o medios similares a su dispositivo " +
  " móvil.</em></li> " +
  "  <li><strong><em>CONSENTIMIENTO Y ACEPTACIÓN. –</em></strong><em> El consentimiento y aceptación de las " +
  " condiciones del servicio se realiza al momento de firmar este presente contrato o en el momento que el " +
  " cliente introduce su PIN en el registro virtual (que sustituye la firma autógrafa), manifestando su " +
  " libre, pleno y absoluto consentimiento con los términos y condiciones del servicio, cualquier cambio y/o " +
  " modificación en los términos y condiciones del servicio serán comunicadas y publicadas en la página web: " +
  '   </em><a href="http://www.tigo.com.bo" style="color: #00c8ff;">www.tigo.com.bo</a><em> opción TIGO MONEY – TÉRMINOS Y CONDICIONES; ' +
  " para su conocimiento y aprobación.Acepta y se compromete a su fiel y estricto cumplimiento sin necesidad " +
  " de ningún otro requerimiento.</em></li> " +
  " </ol> " +
  "</div> ";

export default termsBO;
