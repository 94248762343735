import React, { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import GenericBackdrop from "./commons/GenericBackdrop";
import axiosClient from "./commons/axiosClient";
import { SnackBar } from "./commons/alert";
import Theme from "../themes/Theme";
import tigo from "../img/tigo.svg";
import close from "../img/close.svg";
import dataPerCountry from "../dataPerCountry.json";
import isNumber from "../utils/validateExpression";
import DOMPurify from "dompurify";
import termsBO from "../components/termsConditions/bo";
import termsHN from "../components/termsConditions/hn";
import termsGT from "../components/termsConditions/gt";
import termsPY from "../components/termsConditions/py";
import termsSV from "../components/termsConditions/sv";

import {
  Typography,
  TextField,
  Stack,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LinkAccount from "./link/LinkAccount";

const Login = () => {
  const [t] = useTranslation("global");

  // custom hook that builds on useLocation to parse the query string
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const [queryString, setQueryString] = useState();

  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");
  const [pin, setPin] = useState({
    password: "",
    showPassword: false,
  });

  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openTerms, setOpenTerms] = React.useState(false);
  const [termsCountry, setTermsCountry] = useState();

  const handleOpenTerms = (country) => {
    if (country === "BO") {
      setTermsCountry(termsBO);
    } else if (country === "GT") {
      setTermsCountry(termsGT);
    } else if (country === "HN") {
      setTermsCountry(termsHN);
    } else if (country === "PY") {
      setTermsCountry(termsPY);
    } else if (country === "SV") {
      setTermsCountry(termsSV);
    }

    setOpenTerms(true);
  };
  const handleCloseTerms = () => setOpenTerms(false);

  const [token, setToken] = useState(null);

  const PARAGUAY = "PY";
  const PARAGUAY_FORMAT = "9XXXXXXXX";

  const [formatNumber, setFormatNumber] = useState("");
  const [validNumber, setValidNumber] = useState({
    inputMode: "numeric",
    pattern: "[0-9]*",
  });

  const [dataCountry, setDataCountry] = useState({
    flag: "",
    code: "",
    country: "",
    termConditions: "",
  });

  useEffect(() => {
    let domain = window.location.hostname;
    if (!domain.includes("localhost")) domain = domain.split(".")[3];

    setDataCountry(dataPerCountry[domain]);
    setCode(dataPerCountry[domain].country + " " + dataPerCountry[domain].code);

    if (dataPerCountry[domain].country === PARAGUAY) {
      setFormatNumber(PARAGUAY_FORMAT);
      setValidNumber({ inputMode: "numeric", pattern: "[1-9][0-9]*" });
    }
  }, []);

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === "number") setNumber(value);
    if (name === "pin") setPin({ ...pin, [prop]: value });
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();

    setPin({
      ...pin,
      showPassword: !pin.showPassword,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let requestId = query.get("request_id");
    let merchantId = query.get("id");

    if (!requestId || !merchantId)
      return SnackBar(t("General.Message.MissingParameter"), "warning");

    setQueryString({
      request_id: requestId,
      merchant_id: merchantId,
    });

    handleLoaderOpen();
    axiosClient
      .post(
        `${process.env.REACT_APP_MTS_DOMAIN}/login`,
        JSON.stringify({
          user: number.trim(),
          password: pin.password,
        })
      )
      .then((response) => {
        setToken(response.data.access_token);
        handleLoaderClose();
        handleOpen();
      })
      .catch((_) => {
        handleLoaderClose();
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  };

  function typePassword() {
    if (pin.password.length === 0) return t("Login.Dual");
    else if (pin.password.length <= 4 && isNumber(pin.password))
      return t("Login.Pin");
    else return t("Login.Password");
  }

  return (
    <ThemeProvider theme={Theme}>
      <GenericBackdrop open={loader} />
      <Stack
        direction="column"
        sx={{ width: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        <Stack mt={4}>
          <img src={tigo} alt="TigoMoney" width="200px" />
        </Stack>
        <Stack
          direction="column"
          mt={4}
          sx={{ backgroundColor: "#fff", borderRadius: "20px" }}
          padding={4}
        >
          <Stack justifyContent="center" alignItems="center" mt={4} mb={4}>
            <Typography variant="title" align="center" gutterBottom>
              {t("Login.Title")}
            </Typography>
          </Stack>
          <form
            onSubmit={(event) => {
              handleSubmit(event);
            }}
          >
            <Stack direction="row" spacing={4}>
              <TextField
                disabled
                id="outlined-pais"
                label={t("Login.CountryCode")}
                value={code}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={dataCountry.flag}
                        alt={dataCountry.country}
                        width="20px"
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{ input: { color: "#06367A", fontWeight: "400" } }}
              />
              <TextField
                required
                autoComplete="username"
                id="outlined-number"
                name="number"
                label={t("Login.MobileNumber")}
                onChange={handleChange()}
                placeholder={formatNumber}
                inputProps={validNumber}
                sx={{ input: { color: "#06367A", fontWeight: "400" } }}
              />
            </Stack>
            <FormControl
              sx={{ width: "100%", margin: "4vh 0" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                {typePassword()}
              </InputLabel>
              <OutlinedInput
                required
                autoComplete="current-password"
                id="outlined-adornment-password"
                name="pin"
                type={pin.showPassword ? "text" : "password"}
                value={pin.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      data-testid="ShowPass"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {pin.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("Login.Dual")}
                sx={{ input: { color: "#06367A", fontWeight: "400" } }}
              />
            </FormControl>
            <Stack mt={4} mb={4}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: "100%",
                  height: "48px",
                }}
              >
                {t("Login.Enter")}
              </Button>
            </Stack>
            <Stack mt={4} justifyContent="center" alignItems="center">
              <Typography variant="legend" paragraph>
                {t("Login.Terms")}
              </Typography>
              <Button
                sx={{
                  fontFamily: "Roboto",
                  textDecoration: "underline",
                  fontSize: "11px",
                  justifyContent: "center",
                  padding: "0px",
                  color: "#4C5866",
                }}
                onClick={() => handleOpenTerms(dataCountry.country)}
              >
                {t("Login.SeeTerms")}
              </Button>
              <Dialog
                fullWidth
                maxWidth="100%"
                open={openTerms}
                onClose={handleCloseTerms}
                scroll="paper"
              >
                <DialogActions sx={{ padding: "0px", width: "100%" }}>
                  <Button
                    onClick={handleCloseTerms}
                    sx={{ paddingRight: "0px" }}
                  >
                    <img src={close} alt="closeModal" />
                  </Button>
                </DialogActions>
                <DialogContent>
                  <DialogContentText>
                    <div
                      style={{
                        backgroundColor: "#fafafa",
                        marginTop: "0px",
                        fontFamily: "Roboto,sans-serif",
                        color: "#787878",
                        fontSize: "1rem",
                        fontWeight: "400",
                        textAlign: "justify"
                      }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(termsCountry),
                      }}
                    ></div>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </Stack>
          </form>
        </Stack>
      </Stack>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {!open ? null : <LinkAccount userData={queryString} token={token} />}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};
export default Login;
