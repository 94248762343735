const user = {
  user_info: {},
};

const metaDataReducer = (state = user, action) => {
  if (action.type === "UPDATE_USER")
    return { ...state, user_info: action.payload };
  else return state;
};

export default metaDataReducer;
