const termsHN =
  '<div style="font-family: Roboto,sans-serif; font-weight:400; padding: 15px"> ' +
  ' <h2 style="color: #00377b; font-weight:400" id="contrato-de-adhesion-usuario-para-los-servicios-tigo-money">CONTRATO DE ADHESION USUARIO PARA LOS SERVICIOS ' +
  "  TIGO MONEY</h2> " +
  " <p>Nosotros: <strong>A) Santiago Benedit</strong>, mayor de edad, casado, Licenciado en Ciencias Económicas y " +
  "  Empresariales, de nacionalidad española, con carné de extranjero residente número 01-0812-2017-02293, Registro " +
  "  Tributario Nacional, número 08018017913930, y de este domicilio, actuando en mi condición de Gerente General de " +
  "  la Sociedad Mercantil <strong>“DINERO ELECTRÓNICO S.A. (DINELSA)”</strong>, sociedad inicialmente constituida " +
  "  bajo el nombre <em>“SERVICIOS PRODUCTOS Y NEGOCIOS S.A DE C.V. (SERPRONSA S.A. DE C.V.)”</em>, según Instrumento " +
  "  Público número tres (3) de fecha ocho (8) de diciembre de dos mil once (2011), otorgada ante los oficios del " +
  "  Notario Público Samuel Valladares Sosa e inscrita bajo el Asiento número <strong>11823</strong> de la Matrícula " +
  "  número <strong>2523812</strong> del Libro de Comerciantes Sociales del Registro Mercantil de Francisco Morazán, " +
  "  Centro Asociado del Instituto de la Propiedad, con Registro Tributario Nacional número 08019012495554 modificada " +
  "  su Escritura Constitutiva mediante Instrumento Público número ciento veintisiete (127) de fecha quince (15) de " +
  "  noviembre del año dos mil diecinueve, autorizado bajo los oficios del Notario Público Oscar Armando Melara " +
  "  Facussé, y debidamente inscrito bajo el asiento <strong>57422</strong> de la Matrícula número " +
  "  <strong>2523812</strong> del Libro de Comerciantes Sociales del Registro Mercantil de Francisco Morazán, Centro " +
  "  Asociado al Instituto de la Propiedad; sociedad debidamente autorizada para operar como “Institución No Bancaria " +
  "  Que Brinda Servicios de Pago Utilizando Dinero Electrónico” de acuerdo a la Resolución No. 494-11/2019 de fecha " +
  "  seis (6) de noviembre del año dos mil diecinueve emitida por el Banco Central de Honduras (BCH); representación " +
  "  que acredita con el Instrumento Público número veintiuno (21) de fecha siete (7) de marzo del año dos mil " +
  "  diecisiete (2017), otorgado ante los oficios del Notario Público Oscar Armando Melara Facussé e inscrito bajo el " +
  "  Asiento número <strong>39204</strong> de la Matrícula número <strong>2523812</strong> del Libro de Comerciantes " +
  "  Sociales del Registro Mercantil de Francisco Morazán, Centro Asociado al Instituto de la Propiedad; así como con " +
  "  el Instrumento Público número veintinueve (29), de fecha veintisiete (27) de marzo del año dos mil diecisiete " +
  "  (2017) contentivo de un Poder Especial, autorizado ante los oficios del Notario Público Oscar Armando Melara " +
  "  Facussé, e inscrito bajo el Asiento número <strong>39630</strong> de la Matrícula número " +
  "  <strong>2523812</strong> del Libro de Comerciantes Sociales del Registro Mercantil de Francisco Morazán, Centro " +
  "  Asociado al Instituto de la Propiedad en donde consta que tiene amplias y suficientes facultades para celebrar " +
  "  actos y contratos de esta clase, que para los efectos de este contrato se denominará “DINELSA”; y por otra " +
  "  parte, <strong>B)</strong> el “USUARIO”; acordamos celebrar el presente “Contrato de Usuario para los Servicios " +
  "  Tigo Money”, en virtud del cual <strong>DINELSA</strong>, prestará sus servicios de billetera electrónica a " +
  "  favor del <strong>USUARIO</strong>, a través de la plataforma tecnológica conocida como “__<em>TIGO " +
  "   MONEY</em>__”, dentro del territorio nacional de Honduras, de acuerdo a los siguientes términos y " +
  "  condiciones:</p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-primera-definiciones">CLÁUSULA PRIMERA: DEFINICIONES.</h3> ' +
  " <p>Para efectos del presente contrato y conforme al “Reglamento para la Autorización y Funcionamiento de las " +
  "  Instituciones No Bancarias que brindan servicios de pago utilizando Dinero Electrónico” se entenderá por:</p> " +
  " <p><strong>Activación de Billetera Electrónica</strong>: Significa el procedimiento que permite a un participante " +
  "  registrarse ante el Circuito de Transacciones Móviles (CTM) de una Institución no Bancaria que brinda Servicios " +
  "  de Dinero Electrónico (INDEL) para poder hacer uso de los servicios regulados en el “Reglamento para la " +
  "  Autorización y Funcionamiento de las Instituciones No Bancarias que brindan servicios de pago utilizando Dinero " +
  "  Electrónico” contenido en el Acuerdo No. 01/2016, emitido por el Directorio del Banco Central de Honduras (BCH) " +
  "  en fecha 11 de febrero del año 2016.</p> " +
  " <p><strong>Agencia</strong>: Significa la persona jurídica que opera en el territorio nacional, autorizado por la " +
  "  INDEL para adquirir y distribuir dinero electrónico o convertirlo en dinero físico (billetes y monedas). La " +
  "  agencia podrá establecer su propia red de centros de transacción. </p> " +
  " <p><strong>Banco Central de Honduras (BCH)</strong>: Significa la Institución encargada de formular, desarrollar y " +
  "  ejecutar la política monetaria, crediticia y cambiaria del país y de organizar, reglamentar, y vigilar el " +
  "  funcionamiento del sistema de pagos en el país.</p> " +
  " <p><strong>Billetera Electrónica</strong>: Significa el registro monetario electrónico en la base de datos de la " +
  "  INDEL que permita a sus usuarios realizar transacciones con Dinero electrónico mediante el uso de dispositivos " +
  "  móviles.</p> " +
  " <p><strong>Centro de Transacción Autorizado (CTA)</strong>: Significa la Persona Jurídica autorizada por una INDEL " +
  "  para brindar el servicio de transacción de dinero electrónico a los usuarios.</p> " +
  " <p><strong>Circuito de Transacción Móviles (CTM)</strong>: Significa el conjunto de instrumentos, mecanismos, " +
  "  procedimientos y normas para el almacenamiento y transferencia de dinero electrónico en tiempo real, a través de " +
  "  dispositivos móviles, únicamente dentro de la red de agencias, centro de transacción autorizados, comercios " +
  "  afiliados y usuarios de una misma INDEL.</p> " +
  " <p><strong>Comisión Nacional de Bancos y Seguros (CNBS)</strong>: Es la entidad que supervisa las actividades " +
  "  financieras, de seguros, previsionales, de valores y demás relacionados con el manejo, aprovechamiento e " +
  "  inversión de los recursos del público, y entre otros aspectos vigila que estas cuenten con sistemas de " +
  "  prevención de lavado de activos y financiamiento del terrorismo, niveles de patrimonio adecuado para " +
  "  salvaguardas su solvencia, buenas prácticas para la administración de los riesgos inherentes a las actividades " +
  "  que realizan las instituciones supervisadas.</p> " +
  " <p><strong>Comisión Nacional de Telecomunicaciones (CONATEL)</strong>: Significa la Entidad Gubernamental encargada " +
  "  de regular y fiscalizar la explotación y operación de las telecomunicaciones en el país.</p> " +
  " <p><strong>Días</strong>: Para los efectos del presente contrato se entenderá este término como días hábiles, salvo " +
  "  en los casos en los que expresamente se definan como días calendario. Son días calendario todos los 365 días con " +
  "  que cuenta el año, y los días hábiles en cambio, son todos aquellos días que no son festivos, es decir, aquellos " +
  "  días comunes no festivos en los cuales deben desempeñarse regularmente funciones laborales.</p> " +
  " <p><strong>Dinero Electrónico</strong>: Significa el valor monetario exigible a la INDEL, de conformidad con el " +
  "  monto pagado que reúne las siguientes características; i) Almacenado en una billetera Electrónica. ii) Aceptado " +
  "  como medio de pago por personas naturales o jurídicas, iii) Emitido por un valor igual a los fondos requeridos, " +
  "  iv) Convertible a dinero en efectivo en cualquier momento v) No constituye depósito vi) No genera intereses vii) " +
  "  Esta registrado en los pasivos de la INDEL.</p> " +
  " <p><strong>Dispositivo Móvil</strong>: Significa el instrumento que permite acceder al CTM de una INDEL para pagar " +
  "  bienes y servicios, almacenar y transferir dinero electrónico.</p> " +
  " <p><strong>Empresa Afiliada</strong>: Significa la persona jurídica de naturaleza pública o privada que hace uso de " +
  "  los servicios que presta la INDEL para facilitar sus gestiones de cobro, pagos a proveedores, sueldos, salarios " +
  "  u otras compensaciones, a través de dinero electrónico.</p> " +
  " <p><strong>Fideicomiso</strong>: Negocio jurídico de confianza, en virtud del cual se atribuye a las instituciones " +
  "  autorizadas para operar como fiduciarios, la titularidad dominical sobre ciertos bienes, con la limitación, de " +
  "  carácter obligatorio, de realizar solo aquellos actos exigidos para cumplimiento del fin lícito y determinado al " +
  "  que se destinen. El fideicomiso implica la cesión de los derechos o la traslación del dominio de los bienes a " +
  "  favor del fiduciario.</p> " +
  " <p><strong>Institución no Bancaria que brinda servicios a través de Dinero Electrónico (INDEL)</strong>: Significa " +
  "  la Persona Jurídica de naturaleza pública o privada que ofrece los servicios de transferencias de fondos y " +
  "  operaciones de pago de bienes o servicios, mediante el uso de dispositivos móviles en forma de dinero " +
  "  electrónico.</p> " +
  " <p><strong>Manual de Procedimiento y Operación del CTM</strong>: Significa el conjunto de políticas y procedimientos " +
  "  aprobados por el Consejo de Administración u órgano equivalente de la INDEL, en el que se establecen los " +
  "  mecanismos de administración y funcionamiento del CTM.</p> " +
  " <p><strong>Manual de los Participantes del CTM</strong>: Significa el conjunto de políticas y procedimientos " +
  "  aprobados por el Consejo de Administración u órgano equivalente de la INDEL, en el que se establecen los " +
  "  requisitos de autorización, rol específico y funciones de cada participante dentro del CTM, así como las " +
  "  acciones a seguir en caso de dudas respecto a sus derechos y obligaciones. </p> " +
  " <p><strong>Número de Autorización Transacción (NAT)</strong>: Significa el código alfanumérico que identifica cada " +
  "  transacción realizada en el CTM que permite monitorearla, con el fin de que el participante cuente con un " +
  "  respaldo de cada movimiento realizando en su billetera electrónica. </p> " +
  " <p><strong>Participante</strong>: Significa la agencia, centro de transacción autorizado, empresa afiliada y usuario " +
  "  que participa en el CTM.</p> " +
  " <p><strong>Remesa</strong>: Significa cualquier orden de pago consistente en efectivo, giro, cheques, giro personal " +
  "  o cualquier otra forma de transferencia de dinero, incluyendo las transferencias de dinero por medios " +
  "  electrónicos, cable, teléfono o por cualquier otro medio disponible de dinero, que realiza Banco del País, S.A. " +
  "  (Banpais) como entidad remesadora, a solicitud de un ordenante, para ser entregados al <strong>USUARIO</strong> " +
  "  como beneficiario designado por dicho ordenante.</p> " +
  " <p><strong>Sistema de Gestión de Riesgos y Control Interno</strong>: Significa el conjunto de políticas, " +
  "  procedimientos y técnicas de administración de riesgos y control establecidas por la INDEL para asegurar una " +
  "  organización administrativa eficiente y confiable, que permita la apropiada identificación y administración de " +
  "  los riesgos que enfrenta en sus operaciones y actividades en el cumplimiento de las disposiciones legales que le " +
  "  son aplicables, incluyendo estándares de conducta, integridad y ética de todos los empleados participantes en " +
  "  las operaciones.</p> " +
  " <p><strong>Usuario(s)</strong>: Significa(n) la(s) persona(s) natural(es) que utiliza(n) los servicios de la INDEL a " +
  "  través de las agencias o centros de transacción autorizados.</p> " +
  " <p><strong>Reglamento Para La Autorización Y Funcionamiento De Las Instituciones No Bancarias Que Brindan Servicio " +
  "   De Pago Utilizando Dinero Electrónico (INDEL) / Reglamento</strong>: Significa el instrumento legal emitido " +
  "  por el Directorio del Banco Central de Honduras (BCH) que tiene como objeto regular la autorización y " +
  "  funcionamiento de las instituciones no bancarias, privadas y públicas, que realicen transferencias y operaciones " +
  "  de pago de bienes y servicios mediante el uso de dispositivos móviles, con recurso de sus usuarios, " +
  "  transformados en dinero electrónico, en el territorio nacional. </p> " +
  " <p>Es entendido entre las PARTES que las definiciones anteriores son los aplicables a este contrato, de conformidad " +
  "  con el “Reglamento para la Autorización y Funcionamiento de las Instituciones No Bancarias que Brindan Servicios " +
  "  de Pago Utilizando Dinero Electrónico, en el artículo 2, de tal forma que le aplicarán cualquier otra " +
  "  definición, presente o futura que sea emitida por el Banco Central de Honduras (BCH), y/o la Comisión Nacional " +
  "  de Bancos y Seguros (CNBS). </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-segunda-objeto-y-alcance-del-contrato">CLÁUSULA SEGUNDA: Objeto y Alcance del Contrato.</h3> ' +
  " <p>En virtud del presente Contrato, <strong>DINELSA</strong> prestará sus servicios de BILLETERA ELECTRÓNICA a favor " +
  "  del <strong>USUARIO</strong>, a través de la plataforma tecnológica conocida como “__TIGO MONEY__” dentro del " +
  "  territorio nacional de Honduras, de acuerdo con los términos y condiciones plasmados en el presente Contrato. En " +
  "  tal sentido, <strong>DINELSA</strong> asignará una Billetera Electrónica a nombre del <strong>USUARIO</strong>, " +
  "  y asimismo hará entrega de una clave secreta denominada CONTRASEÑA, que le permitirá al <strong>USUARIO</strong> " +
  "  el acceso a todas las opciones que ofrece el servicio de <em>Tigo Money</em>.</p> " +
  " <p>Las condiciones de uso rigen la apertura, el uso y cierre de la Billetera Electrónica y otros servicios " +
  "  relacionados que se mencionan en el presente documento. En conjunto con la Política de Privacidad y otros " +
  "  términos y condiciones aquí mencionados, constituyen la relación contractual y legal entre el " +
  "  <strong>USUARIO</strong> y <strong>DINELSA</strong>.</p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-tercera-características-y-condiciones-de-la-billetera-electrónica">CLÁUSULA TERCERA: ' +
  "  Características y Condiciones de la Billetera Electrónica.</h3> " +
  " <p><strong>3.1. Características de la Billetera Electrónica</strong>: La Billetera Electrónica tendrá las " +
  "  características que se enumeran a continuación:</p> " +
  " <p><strong>1-</strong> El <strong>USUARIO</strong> acepta y entiende que la Billetera Electrónica es un registro " +
  "  monetario electrónico en la base de datos de Tigo Money que le permite realizar transacciones con Dinero " +
  "  Electrónico. " +
  "  <strong>2-</strong> El Dinero Electrónico reúne las siguientes características: <em><strong>(i)</strong></em> Es " +
  "  aceptado como medio de pago; <em><strong>(ii)</strong></em> Será emitido por un valor igual a los fondos " +
  "  requeridos; <strong><em>(iii)</em></strong> Es convertible a dinero en efectivo en cualquier momento; " +
  "  <strong><em>(iv)</em></strong> Los saldos acreditados no constituyen depósito; <strong><em>(v)</em></strong> Los " +
  "  saldos a favor del USUARIO no están sujetos al pago de intereses; y, <strong><em>(vi)</em></strong> Los saldos " +
  "  serán expresados en moneda de curso legal en Honduras, es decir, Lempiras. " +
  "  <strong>3-</strong> El Dinero Electrónico de la Billetera Electrónica del <strong>USUARIO</strong> se emite de " +
  "  conformidad con el “Reglamento para la Autorización y Funcionamiento de las Instituciones No Bancarias que " +
  "  Brindan Servicios de Pago Utilizando Dinero Electrónico” (en adelante el “Reglamento”) y sus reformas. " +
  "  <strong>4-</strong> La Billetera Electrónica no tiene fecha de vencimiento; sin embargo, la cuenta podrá ser " +
  "  declarada “inactiva” si transcurren ciento ochenta (180) días consecutivos, en que el USUARIO no realice o " +
  "  registre operación o movimiento alguno. " +
  "  <strong>5-</strong> Los saldos existentes en las Billeteras Electrónicas que tengan un período de inactividad de " +
  "  veinte (20) años pasarán a favor del Estado de Honduras, conforme lo establecido en el “Reglamento para la " +
  "  Autorización y Funcionamiento de Instituciones No Bancarias que brindan servicios de pago utilizando Dinero " +
  "  Electrónico”.<br><strong>6-</strong> Se podrá registrar únicamente una Billetera Electrónica por Usuario en Tigo " +
  "  Money. La Billetera Electrónica pertenece a la persona o entidad legal que está registrada como titular de esta. " +
  "  <strong>7-</strong> El <strong>USUARIO</strong> se regirá y realizará transacciones dentro de los límites de " +
  "  saldos máximos y acumulados que por resolución determine de tiempo en tiempo el Directorio de Banco Central de " +
  "  Honduras (BCH). " +
  "  <strong>8-</strong> Se previene al <strong>USUARIO</strong> no compartir su CONTRASEÑA por su seguridad. El " +
  "  <strong>USUARIO</strong>, para todos los efectos legales a que haya lugar, expresamente reconoce y acepta el " +
  "  carácter personal e intransferible de la Billetera Electrónica, así como la expresada confidencialidad o " +
  "  secretividad de la CONTRASEÑA y correrá con los riesgos derivados por su revelación a terceros. " +
  "  <strong>9-</strong> <strong>DINELSA</strong> podrá establecer y cobrar al cliente las comisiones que defina, en " +
  "  concepto de utilización de los diferentes servicios ofrecidos. Dichas comisiones serán difundidas y estarán " +
  "  disponibles para los clientes y usuarios a través de los diferentes medios de comunicación electrónicos o no, " +
  "  que determine <strong>DINELSA</strong>. Podrán ser cobradas a los mismos en los puntos de venta o pagados " +
  "  directamente por los clientes a través de la plataforma electrónica propiedad de <strong>DINELSA</strong> " +
  "  denominada “<em>Tigo Money</em>”. " +
  " </p> " +
  " <p><strong>3.2. Condiciones de Uso de la Billetera Electrónica</strong>: El uso de la Billetera Electrónica por " +
  "  parte del Usuario, deberá realizarse en cumplimiento de las siguientes condiciones:</p> " +
  " <p><strong>1.</strong> El uso de la Billetera Electrónica estará sujeto a los montos límites siguientes: " +
  "  <strong><em>(i)</em></strong> El Saldo Máximo que deberá registrar la Billetera Electrónica será de QUINCE MIL " +
  "  LEMPIRAS (L.15,000.00). En ningún momento dicha Billetera Electrónica podrá tener un saldo mayor al señalado; y, " +
  "  <strong><em>(ii)</em></strong> El Monto Máximo de movimientos transaccionales en créditos y débitos acumulados " +
  "  en el mes será de TREINTA MIL LEMPIRAS (L. 30,000.00). Por lo tanto, no se permitirá un total de movimientos " +
  "  transaccionales en créditos y débitos acumulados en el mes por un monto máximo al previamente establecido. Por " +
  "  lo tanto, el <strong>USUARIO</strong> reconoce y acepta, que no podrá hacer uso de la Billetera Electrónica " +
  "  cuando pretenda sobrepasar los montos límites establecidos en la presente Cláusula. " +
  "  <strong>2.</strong> El uso de la Billetera Electrónica estará sujeto a un máximo de CIEN (100) transacciones al " +
  "  mes, observando los límites monetarios establecidos en el inciso que antecede. Por lo tanto, el " +
  "  <strong>USUARIO</strong> reconoce y acepta, que no podrá hacer uso de la Billetera Electrónica por un número de " +
  "  transacciones mayor la cantidad máxima establecidas en la presente Cláusula. " +
  "  <strong>3.</strong> Conforme lo dispuesto en el “Reglamento para la Autorización y Funcionamiento de las " +
  "  Instituciones No Bancarias que Brindan Servicios de Pago utilizando Dinero Electrónico”, los saldos a favor del " +
  "  <strong>USUARIO</strong> en la Billetera Electrónica no estarán sujetos al pago de intereses. " +
  "  <strong>4.</strong> 4. Los montos entregados por el <strong>USUARIO</strong> en efectivo, así como los saldos a " +
  "  su favor, están garantizados por un Fideicomiso, de conformidad con el “Reglamento para la Autorización y " +
  "  Funcionamiento de las Instituciones No Bancarias que Brindan Servicios de Pago utilizando Dinero Electrónico”. " +
  "  <strong>5.</strong> <strong>DINELSA</strong> prestará en el territorio nacional y en moneda nacional, los " +
  "  servicios de activación de Billetera Electrónica, conversión de dinero físico por Dinero Electrónico y " +
  "  viceversa, consulta de saldos, verificación del historial de transacciones, envío/retiro de dinero a cualquier " +
  "  Billetera Electrónica, transferencias y pago entre Usuarios, compra de bienes y servicios con Dinero " +
  "  Electrónico, compra de recargas de tiempo aire, así como cualquier otro servicio que sea puesto a disposición a " +
  "  través de este servicio. <strong>DINELSA</strong> podrá emitir a solicitud del <strong>USUARIO</strong>, " +
  "  historiales de transacciones realizadas y de saldos en forma impresa o electrónica, conforme sea solicitado por " +
  "  el USUARIO, mediante la plataforma electrónica de <strong>DINELSA</strong>. " +
  "  <strong>6.</strong> En caso de robo, hurto, pérdida, adulteración, fraude, extorsión u otros eventos respecto de " +
  "  la Billetera Electrónica del <strong>USUARIO</strong>, éste deberá llamar a Servicio al Cliente de " +
  "  <strong>DINELSA</strong>, marcando <b><i><strong>*611</strong> </i></b>, o bien presentarse en una Agencia Tigo " +
  "  a realizar el respectivo reporte. El retraso en la notificación afectará la seguridad de la Billetera " +
  "  Electrónica del <strong>USUARIO</strong> y podría resultar en la pérdida de los fondos acreditados de la " +
  "  Billetera Electrónica sin responsabilidad de <strong>DINELSA</strong>. En los casos fraude, extorsión y actos " +
  "  criminales, deberá adicionalmente ponerse en contacto con la Policía Nacional e interponer su respectiva " +
  "  denuncia. " +
  "  <strong>7.</strong> El <strong>USUARIO</strong> acepta como prueba fidedigna de las operaciones y/o " +
  "  transacciones efectuadas, los registros electrónicos que se originen bajo su CONTRASEÑA, en cualquier " +
  "  dispositivo habilitado al efecto, reconociendo como veraces dichos registros resguardados en sistema. " +
  "  <strong>8.</strong> <strong>DINELSA</strong> y sus agentes autorizados se reservan el derecho de no prestar sus " +
  "  servicios, cuando se tengan indicios de estar relacionadas con actividades prohibidas o cuando exista " +
  "  requerimiento de las autoridades competentes. " +
  "  <strong>9.</strong> El <strong>USUARIO</strong> declara expresamente que los fondos enviados o recibidos son de " +
  "  su propiedad, que no provienen ni se vinculan, directa o indirectamente con actividades relacionadas a los " +
  "  delitos de lavado de dinero y/o financiamiento del terrorismo o cualquier otra actividad ilícita. Asimismo, " +
  "  asume la responsabilidad por el uso indebido o inadecuado del servicio establecido a su favor. " +
  "  <strong>10.</strong> El <strong>USUARIO</strong> acepta las siguientes condiciones para las acreditaciones a su " +
  "  Billetera Electrónica realizadas por medio de tarjeta de crédito y/o débito: (i) Se pueden realizar " +
  "  acreditaciones únicamente con tarjetas de crédito y/o débito marca Visa y Mastercard. (ii) No se harán " +
  "  devoluciones por acreditaciones realizadas con tarjeta de crédito y/o débito. (iii) Se permite realizar 2 " +
  "  transacciones al día utilizando la misma tarjeta, teléfono y/o correo electrónico. (iv) Se permite realizar " +
  "  acreditaciones por un máximo de L 4,500 (cuatro mil quinientos Lempiras) al día utilizando la misma tarjeta, " +
  "  teléfono y/o correo electrónico. (v) Se permite realizar acreditaciones hasta un monto máximo de L 15,000 " +
  "  (quince mil Lempiras) al mes. (vi) No es permitido realizar más de una acreditación del mismo monto de forma " +
  "  consecutiva. " +
  "  <strong>11.</strong> El <strong>USUARIO</strong> acepta que la descarga y acreditación de remesas " +
  "  internacionales está únicamente disponible para <strong>USUARIOS</strong> hondureños, mayores de dieciocho (18) " +
  "  años que tengan tarjeta de identidad válida emitida por el Registro Nacional de las Personas. Se pueden realizar " +
  "  descargas de remesas internacionales enviadas a través de Western Union (Airpak) y Moneygram y gestionadas por " +
  "  Banco del País, S.A.. Al recibir una remesa enviada a través de Moneygram, el <strong>USUARIO</strong> " +
  "  expresamente acepta los Términos y Condiciones de Moneygram que están disponibles en <a " +
  '   href="http://corporate.moneygram.com/terms" target="_blank">http://corporate.moneygram.com/terms</a> y las ' +
  "  condiciones de privacidad contenidas en el Aviso de Privacidad Moneygram en <a " +
  '   href="http://global.moneygram.com/privacypolicies" ' +
  '   target="_blank">http://global.moneygram.com/privacypolicies</a>. Al firmar el presente contrato el USUARIO ' +
  "  declara que ha leído y aceptado los términos y condiciones así como las condiciones de privacidad anteriores. " +
  " </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-cuarta-comisiones">CLÁUSULA CUARTA: Comisiones.</h3> ' +
  " <p><strong>4.1.</strong> Por los servicios ofrecidos por <strong>DINELSA</strong> a través de su plataforma “Tigo " +
  "  Money” serán sujetos de comisiones establecidas por <strong>DINELSA</strong> (en lo sucesivo, las “Comisiones”). " +
  "  Las Comisiones se calcularán en base a un porcentaje o monto fijo sobre el valor de la transacción, según sea el " +
  "  caso. Las Comisiones aplicables a la fecha de suscripción del presente Contrato, se detallan en el ANEXO A del " +
  "  presente contrato, el cual forma parte integral del mismo. </p> " +
  " <p><strong>4.2.</strong> Las comisiones pagaderas por el <strong>USUARIO</strong> se deducirán del monto disponible " +
  "  en su Billetera Electrónica. Las comisiones aplicables por transacción se cobrarán al momento de realizarse la " +
  "  transacción. Por medio del presente contrato, el <strong>USUARIO</strong> autoriza a <strong>DINELSA</strong> a " +
  "  realizar las deducciones correspondientes por comisión respeto de las transacciones que realice.</p> " +
  " <p><strong>4.3.</strong> Si el saldo de la Billetera Electrónica fuese insuficiente para cubrir el monto de la " +
  "  transacción más la comisión, <strong>DINELSA</strong> podrá negar la transacción. </p> " +
  " <p><strong>4.4.</strong> El <strong>USUARIO</strong> acepta y reconoce que para el correcto e ininterrumpido uso de " +
  "  la Billetera Electrónica, la misma no podrá registrar saldos negativos o saldos insuficientes. </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-quinta-apertura-de-la-billetera-electrónica">CLÁUSULA QUINTA: Apertura de la Billetera Electrónica. ' +
  " </h3> " +
  " <p><strong>5.1.</strong> Para poder hacer uso de los servicios de Billetera Electrónica, el <strong>USUARIO</strong> " +
  "  deberá registrar sus datos en la base de datos de <strong>DINELSA</strong>. Como parte del proceso de registro, " +
  "  deberá aceptar las Condiciones de Uso contempladas en el presente Contrato, mediante su suscripción, y la " +
  "  Política de Privacidad de <strong>DINELSA</strong>, debiendo contar con la capacidad legal de aceptar las " +
  "  mismas. </p> " +
  " <p><strong>5.2.</strong> El <strong>USUARIO</strong> deber ser mayor de dieciocho (18) años y residente en Honduras " +
  "  al abrir su Billetera Electrónica. El <strong>USUARIO</strong> por este acto declara ser mayor de dieciocho (18) " +
  "  años y residente en Honduras. </p> " +
  " <p><strong>5.3.</strong> El <strong>USUARIO</strong> declara que los datos ingresados y proporcionados a DINELSA " +
  "  para la creación y uso de la Billetera Electrónica son correctos y verídicos. </p> " +
  " <p><strong>5.4.</strong> <strong>DINELSA</strong> podrá solicitar en cualquier momento en el uso de la Billetera " +
  "  electrónica, actualización de la información del <strong>USUARIO</strong>. </p> " +
  " <p><strong>5.5.</strong> El <strong>USUARIO</strong> declara que reconoce y acepta que <strong>DINELSA</strong> se " +
  "  reservará el derecho de cancelar o denegar el acceso a la Billetera Electrónica de acuerdo a las Políticas de " +
  "  Prevención de Lavado de Activos y en cumplimiento a las leyes emitidas para tal efecto. </p> " +
  ' <h3 style="color: #00377b; font-weight:400" ' +
  '  id="cláusula-sexta-medidas-de-seguridad-que-deberán-ser-adoptadas-por-el-usuario-para-manejo-de-su-billetera-electrónica"> ' +
  "  CLÁUSULA SEXTA: Medidas de Seguridad que deberán ser adoptadas por el Usuario para manejo de su Billetera " +
  "  Electrónica.</h3> " +
  " <p>Por medio del presente Contrato, <strong>DINELSA</strong> informa al <strong>USUARIO</strong>, y el " +
  "  <strong>USUARIO</strong> acepta cumplir con las medidas de seguridad sugeridas para el manejo de su Billetera " +
  "  Electrónica, que a continuación se exponen:</p> " +
  " <p><strong>1.</strong> El <strong>USUARIO</strong> tomará todas las medidas razonables para mantener segura y " +
  "  resguardada su CONTRASEÑA en todo momento. El personal de <strong>DINELSA</strong> en ningún momento le pedirá " +
  "  que proporcione su CONTRASEÑA, ni que lo proporcione a terceros " +
  "  <strong>2.</strong> Se recomienda al <strong>USUARIO</strong> cambiar su CONTRASEÑA periódicamente (por lo menos " +
  "  una vez cada tres (3) meses) con el fin de minimizar el riesgo de violación de la seguridad de su Billetera " +
  "  Electrónica. " +
  "  <strong>3.</strong> Se recomienda al <strong>USUARIO</strong> no elegir una CONTRASEÑA que sea fácil de adivinar " +
  "  o fácilmente identificable con su información personal. Se recomienda al <strong>USUARIO</strong> no permitir " +
  "  que terceras personas puedan ver el ingreso de su CONTRASEÑA para iniciar operaciones con su Billetera " +
  "  Electrónica. " +
  "  <strong>4.</strong> Ante algún indicio o sospecha que la CONTRASEÑA del <strong>USUARIO</strong> ha sido " +
  "  comprometido, se recomienda al <strong>USUARIO</strong> cambiar su CONTRASEÑA de forma inmediata. Si ha sufrido " +
  "  un robo, hurto, pérdida, adulteración, fraude, extorsión o cualquier otro evento negativo que afecte su " +
  "  seguridad, el <strong>USUARIO</strong> deberá llamar a Servicio al Cliente de DINELSA, marcando " +
  "  <b><i><strong>*611</strong> </i></b> desde su celular, o bien presentarse en una Agencia Tigo a realizar el " +
  "  respectivo reporte. El retraso en la notificación afectará la seguridad de su Billetera Electrónica y podría " +
  "  resultar en la pérdida de los fondos acreditados de la Billetera Electrónica sin responsabilidad de " +
  "  <strong>DINELSA</strong>. En los casos de fraude, extorsión y actos criminales, deberá adicionalmente ponerse en " +
  "  contacto con la Policía Nacional e interponer su respectiva denuncia. " +
  "  <strong>5.</strong> <strong>DINELSA</strong> podrá poner a disposición del <strong>USUARIO</strong>, servicios " +
  "  que requieran medidas de seguridad adicionales, con los cuales el <strong>USUARIO</strong> deberá de conocer una " +
  "  vez notificados. " +
  " </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-séptima-cierre-de-la-billetera-electrónica">CLÁUSULA SÉPTIMA: Cierre de la Billetera Electrónica. ' +
  " </h3> " +
  " <p>El <strong>USUARIO</strong> podrá cerrar su Billetera Electrónica en cualquier momento visitando las Agencias de " +
  "  <strong>DINELSA</strong>, identificadas como “Agencias Tigo”. Si el <strong>USUARIO</strong> dispone de Dinero " +
  "  Electrónico en su Billetera Electrónica al momento de cierre, se le solicitará realizar el retiro de los fondos " +
  "  mediante su conversión a dinero en efectivo. </p> " +
  " <p>Asimismo, <strong>DINELSA</strong> se reserva el derecho de realizar las validaciones pertinentes y necesarias en " +
  "  materia de prevención de lavado de activos, financiamiento al terrorismo u otras actividades ilícitas y por tal " +
  "  motivo puede cerrar la Billetera Electrónica del Usuario en cualquier momento durante la relación con el " +
  "  <strong>USUARIO</strong>, sin responsabilidad de parte de <strong>DINELSA</strong> por tal cierre. </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-octava-política-de-confidencialidad-y-privacidad">CLÁUSULA OCTAVA: Política de Confidencialidad y ' +
  "  Privacidad.</h3> " +
  " <p>Usted otorga su consentimiento previo, expreso e informado a DINERO ELECTRÓNICO, S.A., DINELSA, para el " +
  "  tratamiento de sus datos personales, incluyendo datos biométricos referentes a sus huellas dactilares, voz, " +
  "  imagen de su rostro u otros datos similares, así como de sus datos crediticios y financieros conforme a la " +
  "  legislación vigente y al Aviso de Privacidad de TIGO publicado en el siguiente link: " +
  '  <a href="https://www.tigo.com.hn/legales#tigo-politicas-de-privacidad" ' +
  '   target="_blank">https://www.tigo.com.hn/legales#tigo-politicas-de-privacidad</a>, SI_______ X _______ NO ' +
  " </p> " +
  " <p>Usted de manera voluntaria declara y acepta que:(a) la finalidad de la recolección y el tratamiento de sus datos " +
  "  personales incluirá, entre otros, la prestación de los servicios contratados, ofrecimiento de productos y " +
  "  servicios, envío de comunicaciones relacionadas con los servicios y productos, promociones comerciales, mejora " +
  "  del servicio, encuestas de satisfacción, estudio de crédito, gestión de cobro, servicio de atención al cliente, " +
  "  soporte técnico u otros servicios relacionados, ya sea que se presten directamente o a través de terceros, " +
  "  programas de cumplimiento asociados a la prevención y detección de lavado de activos y financiamiento al " +
  "  terrorismo, así como aquellas finalidades descritas en el Aviso de Privacidad de TIGO. En todo caso, con la " +
  "  aceptación de los servicios, se autoriza a TIGO para realizar el tratamiento de todos los datos necesarios para " +
  "  la debida prestación de los servicios; (b) la finalidad de la recolección y el tratamiento de sus datos " +
  "  biométricos, es solamente para (i) la validación de su identidad por parte de TIGO en los procesos de venta o " +
  "  postventa u otorgamiento de créditos, con el fin de prevenir fraude, lavado de activos, financiación al " +
  "  terrorismo y otras actividades ilegales en cumplimiento con la Ley Especial de Lavado de Activos y otras leyes " +
  "  vigentes en el país, y (ii) permitirle su autenticación electrónica, siempre que Usted así lo decida, en ciertos " +
  "  productos y servicios ofrecidos por TIGO; (c) conoce los derechos que le asisten de acuerdo a la legislación " +
  "  vigente y como ejercerlos conforme a lo establecido en el Aviso de Privacidad de TIGO; (d)puede revisar el Aviso " +
  "  de Privacidad de TIGO publicado en el siguiente link: <a " +
  '   href="https://www.tigo.com.hn/legales#tigo-politicas-de-privacidad" ' +
  '   target="_blank">https://www.tigo.com.hn/legales#tigo-politicas-de-privacidad</a>, para conocer acerca de ' +
  "  nuestras prácticas para el tratamiento de los datos personales, el cual podrá ser modificado o actualizado en " +
  "  cualquier momento por parte de TIGO, a través de su portal web en Internet.</p> " +
  " <p>Asimismo, usted autoriza expresamente a TIGO para que solicite y/o verifique sus datos crediticios en la Central " +
  "  de Riesgos y demás buros de crédito privados en cualquier tiempo mientras se encuentre vigente la relación " +
  "  contractual con TIGO, suministre sus datos personales a terceros proveedores de servicios o productos o socios " +
  "  comerciales encargados del tratamiento de los datos, y a las entidades afiliadas y subsidiarias de TIGO u otras " +
  "  compañías pertenecientes al mismo grupo empresarial, dentro y fuera de Honduras con fines relacionados a la " +
  "  prestación del servicio y a lo indicado en el Aviso de Privacidad de TIGO.</p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-novena-responsabilidades-del-usuario">CLÁUSULA NOVENA: Responsabilidades del USUARIO.</h3> ' +
  " <p>En virtud de la suscripción del presente Contrato, el <strong>USUARIO</strong> se compromete a cumplir con lo " +
  "  siguiente:</p> " +
  " <p><strong>1.</strong> Si cualquier pérdida de CONTRASEÑA, robo hurto o cualquier otro evento que comprometa la " +
  "  seguridad de la Billetera Electrónica, no es notificado a <strong>DINELSA</strong> de forma inmediata, el " +
  "  <strong>USUARIO</strong> será responsable de las pérdidas incurridas hasta el momento en que notifique a " +
  "  <strong>DINELSA</strong> de tal evento. " +
  "  <strong>2.</strong> Si una vez emitido el estado de cuenta de la Billetera Electrónica, el " +
  "  <strong>USUARIO</strong> no objeta o cuestiona las transacciones que estime no autorizadas o ejecutadas mediante " +
  "  el uso de su CONTRASEÑA, y no comunique tal objeción o cuestionamiento a <strong>DINELSA</strong> dentro de un " +
  "  plazo de 60 días a partir de la fecha de la transacción, se entenderá que el <strong>USUARIO</strong> acepta " +
  "  dicho estado de cuenta. Sin perjuicio de lo anterior, se le recomienda al <strong>USUARIO</strong> verificar su " +
  "  historial transaccional de forma periódica. De detectar transacciones no autorizadas, el " +
  "  <strong>USUARIO</strong> deberá contactar al Servicio al Cliente de <strong>DINELSA</strong>, marcado " +
  "  <b><i><strong>*611</strong> </i></b> desde su celular, o presentarse en una Agencia Tigo o en caso de cualquier " +
  "  pregunta o inquietud. " +
  "  <strong>3.</strong> El <strong>USUARIO</strong> acepta y reconoce que la obligación de <strong>DINELSA</strong> " +
  "  en virtud del presente Contrato, se limita a proporcionarle una Billetera Electrónica y servicios de pago " +
  "  relacionados, y no implica ninguna afirmación ni garantía en relación con la calidad, seguridad o legalidad de " +
  "  ningún servicio pactado entre el Usuario y un tercero. " +
  "  <strong>4.</strong> El <strong>USUARIO</strong> acepta y reconoce que <strong>DINELSA</strong> no será " +
  "  responsable por la valorización ni el pago de impuestos/gravámenes ni otros cargos que pudiesen surgir por una " +
  "  transacción entre el <strong>USUARIO</strong> y un tercero. Por lo tanto, el <strong>USUARIO</strong> deberá " +
  "  asumir cualquier cargo que pudiese surgir por cualquier transacción que efectúe con terceros. " +
  "  <strong>5.</strong> El <strong>USUARIO</strong> acepta defender, reembolsar o compensar, y eximir a DINELSA y al " +
  "  resto de empresas del grupo corporativo al cual pertenece, ante cualquier reclamación, demanda, gastos o costos " +
  "  (incluidos los honorarios legales, multas o penalizaciones) en los que incurra DINELSA o empresas relacionadas o " +
  "  afectadas, que hayan surgido por un incumplimiento del USUARIO respecto de las obligaciones contenidas de este " +
  "  Contrato, de cualquier ley o reglamentación aplicable o del uso indebido de los servicios por parte del USUARIO. " +
  "  Esta disposición se mantendrá vigente tras la finalización de la relación comercial entre el " +
  "  <strong>USUARIO</strong> y <strong>DINELSA</strong>. " +
  "  <strong>6.</strong> El <strong>USUARIO</strong> se compromete a utilizar el proceso de Atención de Reclamos " +
  "  descrito en la CLÁUSULA DÉCIMA QUINTA del presente Contrato " +
  " </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-décima-obligaciones-y-responsabilidades-de-dinelsa-indel">CLÁUSULA DÉCIMA: Obligaciones y ' +
  "  responsabilidades de DINELSA (INDEL).</h3> " +
  " <p>Son obligaciones de <strong>DINELSA</strong>, en su condición de “Institución No Bancaria que Brinda Servicios de " +
  "  Pago Utilizando Dinero Electrónico (INDEL), las establecidas en el Artículo 14 del “Reglamento para la " +
  "  Autorización y Funcionamiento de las Instituciones No Bancarias que Brindan Servicios de Pago utilizando Dinero " +
  "  Electrónico”, las cuales se compromete a dar fiel y exacto cumplimiento, en la medida aplicable a la relación " +
  "  contractual con el <strong>USUARIO</strong>. Asimismo, se compromete a dar fiel cumplimiento y respetar los " +
  "  derechos que dicho Reglamento otorgue a los usuarios de servicios de pago utilizando dinero electrónico, y demás " +
  "  normativa que al efecto emita el Banco Central de Honduras (BCH), y/o la Comisión Nacional de Bancos y Seguros " +
  "  (CNBS). </p> " +
  " <p><strong>10.1.</strong> En caso que se realice un debito no autorizado por el <strong>USUARIO</strong> por error " +
  "  de <strong>DINELSA</strong>, se le devolverá el pago incluidas las comisiones de forma inmediata y a petición " +
  "  del USUARIO. Se exceptúan los casos o no aplicará tal devolución en los siguientes casos:</p> " +
  " <p><strong>10.2.</strong> En caso en que se realice una transacción no autorizada personalmente por el " +
  "  <strong>USUARIO</strong> que no ha mantenido las medidas de seguridad de acuerdo con lo establecido en el " +
  "  presente Contrato. En este caso, el <strong>USUARIO</strong> se responsabiliza por la pérdida de el/los montos " +
  "  de las transacciones realizadas. Apegarse al procedimiento de Atención de Reclamos descrito en la cláusula " +
  "  DECIMA QUINTA del presente contrato.</p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-décima-primera-terminación-del-contrato">CLÁUSULA DÉCIMA PRIMERA: Terminación del Contrato.</h3> ' +
  " <p>El presente Contrato y los servicios que conlleva el mismo, podrán ser terminados de la siguiente manera: </p> " +
  " <p><strong>1.</strong> <strong>DINELSA</strong> podrá cancelar la Billetera Electrónica del <strong>USUARIO</strong> " +
  "  en cualquier momento, derivado de un incumplimiento del <strong>USUARIO</strong> de cualquier disposición " +
  "  contenida en el presente Contrato. " +
  "  <strong>2.</strong> <strong>DINELSA</strong> estará facultada para suspender o cancelar la cuenta del " +
  "  <strong>USUARIO</strong>, en cualquier momento, sin necesidad de previo aviso y sin responsabilidad de su parte, " +
  "  de ocurrir cualquiera de los siguientes casos: " +
  "  • Incumplimiento cualquiera de las condiciones de uso de la Billetera Electrónica, contenido en el presente " +
  "  Contrato; o cualquier otra condición aplicable a los servicios ofrecidos por <strong>DINELSA</strong>. " +
  "  • Si viola, o existe duda razonable en que se ha violado cualquier ley o reglamento aplicable a los servicios de " +
  "  Billetera Electrónica; o, " +
  "  • Si existe duda razonable de que existen transacciones o actividades fraudulentas, de lavado de activos, " +
  "  financiamiento al terrorismo u otras actividades ilícitas. " +
  " </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-décima-segunda-cambios-en-las-condiciones-de-uso">CLÁUSULA DÉCIMA SEGUNDA: Cambios en las ' +
  "  Condiciones de Uso.</h3> " +
  " <p>Estas Condiciones de Uso, así como cualquier término y condiciones adicionales que pudiesen aplicar, están " +
  "  sujetos a cambios. Los cambios se considerarán aplicables sin necesidad previa aceptación del " +
  "  <strong>USUARIO</strong>, debiendo <strong>DINELSA</strong> únicamente notificarlos al <strong>USUARIO</strong>, " +
  "  haciendo uso de los canales de comunicación que ha determine <strong>DINELSA</strong>. Cualquier modificación, " +
  "  entrará en vigencia treinta (30) días después de la fecha de notificación del cambio. Si el " +
  "  <strong>USUARIO</strong> se opusiere a los cambios en las condiciones de uso, se le aplicará la cancelación " +
  "  respectiva de su Billetera Electrónica aplicando lo establecido en el presente Contrato. </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-décima-tercera-fideicomiso">CLÁUSULA DÉCIMA TERCERA: Fideicomiso.</h3> ' +
  " <p>En virtud de lo establecido en el “Reglamento para la Autorización y Funcionamiento de Instituciones No Bancarias " +
  "  que brindan servicios de pago utilizando Dinero Electrónico”, <strong>DINELSA</strong> declara y hace del " +
  "  conocimiento del <strong>USUARIO</strong>, que los montos en efectivo entregados por el " +
  "  <strong>USUARIO</strong>, así como los saldos que se encuentren a su favor, se encuentran garantizados por un " +
  "  Fideicomiso Mixto de Garantía y Administración, establecido ante una Institución Bancaria del Sistema Financiero " +
  "  Hondureño. </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-décima-cuarta-prácticas-anticorrupción-y-cumplimiento">CLÁUSULA DÉCIMA CUARTA: Prácticas ' +
  "  Anticorrupción y Cumplimiento.</h3> " +
  " <p>Las PARTES, incluido cualquiera de sus representantes o agentes, así como cualquier compañía afiliada, declaran y " +
  "  garantizan que: " +
  "  <strong>14.1</strong> No han participado, no participan y no van a participar en ninguna acción que viole el " +
  "  “Foreign Corrupt Practices Act (FCPA)” de los Estados Unidos de América, el “UK Bribery Act” de 2010, o " +
  "  cualquier otra ley o regulación que sea aplicable en el territorio donde las partes tengan sus negocios. " +
  "  <strong>14.2</strong> No han realizado, no han acordado, ni prometido realizar y no van a realizar, acordar o " +
  "  prometer realizar, directa o indirectamente, cualquier pago a cualquier proveedor, cliente, entidad " +
  "  gubernamental, candidato político, funcionario público, funcionario de una entidad internacional pública, o a " +
  "  cualquier otra persona que esté en una posición que le permita afectar el negocio con el fin de influenciar " +
  "  cualquier decisión por parte de dicha persona para obtener una ventaja indebida. " +
  "  <strong>14.3</strong> No han entregado, no han acordado ni prometido entregar y no van a entregar, acordar o " +
  "  prometer entregar, directa o indirectamente, cualquier beneficio o algo de valor a cualquier proveedor, cliente, " +
  "  entidad gubernamental, candidato político, funcionario público, funcionario de una entidad internacional " +
  "  pública, o a cualquier otra persona que esté en una posición que le permita afectar el negocio con el fin de " +
  "  influenciar cualquier decisión por parte de dicha persona para obtener una ventaja indebida; y " +
  "  <strong>14.4</strong> No han realizado y no van a realizar ninguna contribución o reembolso a algún candidato " +
  "  político de cualquier nivel o a un funcionario público extranjero que: (i) pueda implicar daños o sanciones de " +
  "  índole civil, penal o un litigio o procedimiento administrativo a la otra parte; (ii) que de no haberse " +
  "  entregado en el pasado, pudiese haber generado un efecto material adverso en los activos, negocios u operaciones " +
  "  presentes de la otra parte; o bien, (iii) que de no continuarse entregando en el futuro, se esperaría que " +
  "  generase un efecto material adverso en los activos, negocios u operaciones de la otra parte. " +
  "  <strong>14.5</strong> Cualquier incumplimiento de estas obligaciones por parte del <strong>USUARIO</strong> bajo " +
  "  este contrato, facultará al <strong>DINELSA</strong> para dar por terminado el presente contrato, notificando " +
  "  tal situación al <strong>USUARIO</strong>. " +
  "  <strong>14.6</strong> El USUARIO declara por derecho propio que: " +
  "  1. a) Que la información consignada en el Registro de Dinero Electrónico es fidedigna y que es el titular y " +
  "  portador del documento de identificación consignado. " +
  "  2. b) Que los fondos o valores que enviará y recibirá mensualmente a través del servicio Tigo Money estarán " +
  "  dentro de los límites establecidos y éstos no tendrán un origen o destino ilícito que se encuentren relacionados " +
  "  con los delitos generadores de Lavado de Dinero y de Activos y/o Financiamiento al Terrorismo establecidos en la " +
  "  regulación local aplicable. " +
  "  3. c) Que no admitirá que terceros actuando en su nombre realicen transacciones a través del servicio Tigo Money " +
  "  con fondos o bienes provenientes de actividades ilícitas. " +
  "  4. d) Que a la fecha no se le ha comprobado judicialmente participación en actividades relacionadas con el " +
  "  narcotráfico y delitos conexos, de lavado de dinero, de activos y financiamiento al terrorismo, tanto en la " +
  "  jurisdicción nacional o en el extranjero. " +
  "  5. e) Que exime a la Sociedad Dinero Electrónico, S.A, de toda responsabilidad que se derive por cualquier tipo " +
  "  de información errónea, falsa o inexacta que hubiere presentado o de cualquier violación legal, mientras se " +
  "  mantenga la relación contractual. " +
  " </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-décima-quinta-atención-de-reclamos-del-usuario">CLÁUSULA DÉCIMA QUINTA: Atención de Reclamos del ' +
  "  USUARIO</h3> " +
  " <p>En caso de existir algún reclamo en relación con el Servicio proporcionado por <strong>DINELSA</strong>, el " +
  "  <strong>USUARIO</strong> se hará servir del siguiente procedimiento para la resolución de este: </p> " +
  " <p><strong>15.1.</strong> En primera instancia, el <strong>USUARIO</strong> reportará todo reclamo en relación a los " +
  "  servicios que proporciona <strong>DINELSA</strong> al número de Servicio al Cliente marcando " +
  "  <b><i><strong>*611</strong> </i></b> desde su dispositivo celular. </p> " +
  " <p><strong>15.2.</strong> Una vez el reclamo haya sido recibido por <strong>DINELSA</strong>, esta resolverá la " +
  "  consulta o el reclamo presentada por el <strong>USUARIO</strong> dentro de treinta (30) días calendario contados " +
  "  a partir de la fecha en que se registró el reclamo.</p> " +
  " <p><strong>15.3.</strong> Si, tras recibir la resolución por parte de <strong>DINELSA</strong>, el " +
  "  <strong>USUARIO</strong> se encuentra inconforme con dicha resolución, el <strong>USUARIO</strong> podrá llenar " +
  "  el formulario de reclamo de la Comisión Nacional de Bancos y Seguros (CNBS) que <strong>DINELSA</strong> tendrá " +
  "  disponible en sus agencias. Ante dicho formulario DINELSA deberá dar respuesta en diez (10) días hábiles. </p> " +
  " <p><strong>15.4.</strong> Si transcurridos estos diez (10) días hábiles <strong>DINELSA</strong> no emite una " +
  "  respuesta o si emite una respuesta y el <strong>USUARIO</strong> se encuentra aún inconforme con la misma, el " +
  "  <strong>USUARIO</strong> podrá elevar su petición a una entidad externa como la Gerencia de Protección al " +
  "  Usuario Financiero. </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-décima-sexta-fuero-y-solución-de-controversias">CLÁUSULA DÉCIMA SEXTA: Fuero y Solución de ' +
  "  Controversias.</h3> " +
  " <p>El presente contrato, así como las obligaciones y derechos que de él se desprendan se regirán por las leyes de la " +
  "  República de Honduras. Las partes convienen expresamente que, en caso de controversia en relación con la " +
  "  ejecución, interpretación y el cumplimiento del presente contrato y cualquiera de sus regulaciones supletorias, " +
  "  cualquiera que fuere la causa alegada, se resolverá por la vía amistosa, esta solución se deberá de resolver en " +
  "  un plazo de treinta días (30). En caso de que no se haya llegado a una solución por la vía amistosa, las partes " +
  "  se someterán al procedimiento de arbitraje en Derecho establecido en la Ley de Conciliación y Arbitraje, " +
  "  contenida en el Decreto Legislativo 161-2000 de fecha 17 de octubre de 2000, y publicada el 14 de febrero de " +
  "  2001 y de conformidad a las reglas del Centro de Conciliación y Arbitraje de la Cámara de Comercio e Industria " +
  "  de Tegucigalpa. Las partes determinan que el arbitraje tendrá una duración de un (1) mes, para lo cual en la " +
  "  nominación del tribunal arbitral prefieren que sean tres (03) árbitros los cuales serán nombrados uno por cada " +
  "  una de LAS PARTES y el otro por la Cámara de Comercio e Industrias de Tegucigalpa. Cada una de las partes " +
  "  correrá con sus gastos de arbitraje, debiendo las mismas cubrir con los honorarios del árbitro nombrado en los " +
  "  términos de este contrato y demás gastos de arbitraje en partes iguales; no obstante, lo anterior, la parte que " +
  "  resulte vencida en dicho proceso arbitral deberá rembolsar en su totalidad a la parte vencedora todos honorarios " +
  "  y gastos en que haya incurrido con motivo del proceso. El arbitraje será en derecho. El laudo arbitral que emita " +
  "  en virtud del proceso el tribunal arbitral será definitivo e inapelable, siendo por lo tanto el mismo de " +
  "  ejecución inmediata.</p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-décima-séptima-interpretación">CLÁUSULA DÉCIMA SÉPTIMA: Interpretación.</h3> ' +
  " <p>Las partes suscriben el presente Contrato y anexos con la simple voluntad que sea válido y efectivo en su " +
  "  totalidad; no obstante, si alguna de sus cláusulas o disposiciones resultaré nula o inválida, o existiera algún " +
  "  problema de interpretación, dicho evento no perjudicará el resto del Contrato y anexos, todo lo cual permanecerá " +
  "  vigente. </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="cláusula-décima-octava-aceptación">CLÁUSULA DÉCIMA OCTAVA: Aceptación.</h3> ' +
  " <p>Ambas partes declaran que se comprometen recíprocamente a cumplir las obligaciones de este contrato de buena fe, " +
  "  entendiéndose que buena fe es la completa ausencia de obras fraudulentas, de engaño, reserva mental de todo " +
  "  tipo, astucia o viveza, anteponiendo ante todo la honestidad, la lealtad y el espíritu de responsabilidad del " +
  "  comerciante en los negocios propios.</p> " +
  " <p>Asimismo, declaran que Este contrato deja sin ningún valor ni efecto a todo entendimiento, ofertas, discusiones, " +
  "  negociaciones o contratos con sus respectivos Anexos o adendums que las partes hayan suscrito y aceptado en " +
  "  fechas anteriores al presente. " +
  "  En fe de todo lo anteriormente expresado, ambas partes están de acuerdo en todas y cada una de las cláusulas de " +
  "  este Contrato.</p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="anexo-uno">ANEXO UNO</h3> ' +
  " <p><em><strong>Cálculo, Periodicidad y Forma De Pago de las Comisiones " +
  "    como Contraprestación del Servicio</strong></em></p> " +
  " <p>El presente ANEXO forma parte integral del CONTRATO DE USUARIO suscrito con <strong>DINELSA</strong>; dentro del " +
  "  cual se acuerdan que las comisiones para <strong>EL USUARIO</strong> por las transacciones de Billetera " +
  "  Electrónica son las descritas en el presente ANEXO UNO. Por lo tanto, de conformidad con la Política Comercial " +
  "  de DINELSA, las comisiones que se aplicarán a <strong>EL USUARIO</strong> por cada tipo de servicio son las " +
  "  detalladas a continuación:</p> " +
  ' <h4 style="color: #00377b; font-weight:400" id="primero-tabla-de-estructura-de-precios-y-detalle-de-comisiones">PRIMERO: Tabla de Estructura de Precios y ' +
  "  Detalle de Comisiones.</h4> " +
  '<table id="table-hn5hGxRjaHbfWDexTTCr9rIA-0" style="border:1px solid #e5eaf2; background-color: #fff; line-height: 1.5;  width: 100%;' +
  "border: 1px solid #e5eaf2;" +
  "background-color: #fff;" +
  "border-collapse: separate;" +
  "text-indent: initial;" +
  "border-spacing: 2px;" +
  'display: table;">' +
  '<thead align="left" >' +
  "    <tr>" +
  '<th style="border-bottom: 2px solid #00377b;  color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;" data-text="Transacción" id="table-hn5hGxRjaHbfWDexTTCr9rIA-0-0"' +
  '    data-table="#table-hn5hGxRjaHbfWDexTTCr9rIA-0" data-th="0" data-order="asc"' +
  '    style="cursor: pointer;">Transacción</th>' +
  '<th style="border-bottom: 2px solid #00377b;  color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;">Comisión</th>' +
  "    </tr>" +
  "</thead>" +
  "<tbody>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Acreditaciones con Tarjeta de Crédito y/o Debito (Cash In)</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.0.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Acreditaciones de remesas recibidas a través de banco del país S.A</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.0.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Colecturías</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.0.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">ENEE</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.10.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Envíos dinero electrónico (Cash In)</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.0.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">HONDUTEL</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.3.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Recepción de dinero electrónico (Cash Out)</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L 1 – L 1,999.99 6%' +
  "    <br>L 2,000.00 – L 3,999.99 5%" +
  "    <br>L 4,000.00 en adelante 4%" +
  "</td>" +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Recepción de dinero electrónico (CASH OUT)' +
  "    desde Billetera, Banca, ADS y Remesas Internacionales." +
  "</td>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">2.5% sobre valor a retirar</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">RECO</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.25.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">SANAA/Aguas Municipales</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.3.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Tigo Postpago</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.0.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Tigo Residencial</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.0.00</td>' +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Transferencias de Billetera Electrónica a Cuenta Bancaria</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Banco: L.0.00' +
  "    <br>Tigo Money: 2.5% sobre el valor transferido" +
  "</td>" +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Transferencias de Cuenta Bancaria a Billetera Electrónica</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Banco: de L20.00 a L.30.00' +
  "    <br>Tigo Money: L0.00" +
  "</td>" +
  "    </tr>" +
  "    <tr>" +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Transferencias entre Usuarios</td>' +
  '<td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">L.1.00</td>' +
  "    </tr>" +
  "    <tr>" +
  "    </tr>" +
  "</tbody>" +
  "    </table>" +
  ' <h3 style="color: #00377b; font-weight:400" id="segundo-cálculo-de-comisiones">SEGUNDO: Cálculo de Comisiones</h3> ' +
  " <p>Los cálculos de Comisiones se realizarán de la siguiente manera:</p> " +
  " <p><strong>1-</strong> <em><strong>Envíos de Dinero Electrónico</strong></em>: la transacción de envió de dinero no " +
  "  constituye costo para el Usuario.<br> " +
  "  <strong>2-</strong> <em><strong>Retiros</strong></em>: esta comisión es calculada como un porcentaje del total " +
  "  del monto a retirar.<br> " +
  "  <strong>3-</strong> <em><strong>Integración Bancaria</strong></em>: En transferencias de Cuenta Bancaria a " +
  "  Billetera Electrónica, Tigo Money no realiza ningún cobro. En transferencias de Billetera Electrónica a Cuenta " +
  "  Bancaria, esta comisión es calculada como un porcentaje del valor a transferir.<br> " +
  "  <strong>4-</strong> <em><strong>Servicios Públicos</strong></em>: esta comisión es un monto fijo de acuerdo al " +
  "  servicio que se realizará el pago.<br> " +
  "  <strong>5-</strong> <em><strong>Gestiones de Cobros para Empresas Afiliadas</strong></em>: esta comisión es un " +
  "  monto fijo de acuerdo al servicio que se realizará el pago.<br> " +
  "  <strong>6-</strong> <em><strong>Comisiones TM</strong></em>: Esta comisión es aplicable aquellos usuarios que se " +
  "  afilien al perfil TM+ de DINELSA. El cálculo de estas comisiones se realizará conforme lo establecido en las " +
  "  condiciones vigentes y aceptadas por los usuarios para dicho perfil, las que forman parte integral del este " +
  "  Anexo y por consiguiente del presente contrato. " +
  " </p> " +
  ' <h3 style="color: #00377b; font-weight:400" id="tercero">TERCERO</h3> ' +
  " <p>Este ANEXO con su tabla de comisiones podrá ser modificado por parte de <strong>DINELSA</strong> cuando así lo " +
  "  estime conveniente, notificando <strong>EL USUARIO</strong> con 15 días de anticipación de dicho cambio. Para " +
  "  efectos de esta comunicación, DINELSA publicará cualquier modificación del presente Anexo en su portal web, <a " +
  '   href="https://www.tigo.com.hn" target="_blank">https://www.tigo.com.hn</a></p> ' +
  " <p>En fe de todo lo anteriormente expresado, estando de acuerdo <strong>EL USUARIO</strong> en todas y cada una de " +
  "  las cláusulas de este ANEXO, <strong>EL USUARIO</strong> acepta las condiciones expuestas.</p> " +
  "</div> ";

export default termsHN;
