import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  components: {
    MuiCheckbox: {
      checked: {
        backgroundColor: "#0067ED",
      },
      colorPrimary: {
        color: "#0067ED",
        "&$checked": {
          color: "#0067ED",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "#00C8FF",
            color: "#FFF",
            fontFamily: "Readex Pro",
            fontSize: "16px",
            fontWeight: "600",
            borderRadius: "100px",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            height: "4.2vh",
            borderRadius: "1vh",
            border: "0.10vh solid #FFC10E",
            color: "#343C46",
            textTransform: "none",
          },
        },
      ],
    },
  },
  typography: {
    allVariants: {
      fontFamily: ["Readex Pro"].join(","),
      textTransform: "none",
      color: "#404A56",
    },
    title: {
      fontFamily: "Readex Pro",
      fontSize: "42px",
      fontWeight: "600",
      color: "#06367A",
    },
    subTitle: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#404A56",
    },
    text12_4: {
      fontFamily: "Readex Pro",
      fontWeight: "400",
      fontSize: "12.44px",
      color: "#00377B",
    },
    text14: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#404A56",
    },
    text18: {
      fontWeight: "600",
      fontSize: "18px",
      color: "#404A56",
    },
    text24: {
      fontFamily: "Readex Pro",
      fontWeight: "400",
      fontSize: "24px",
      color: "#00377D",
    },
    body: {
      fontSize: "16px",
      fontWeight: "300",
      color: "#565656",
    },
    checkbox: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#565656",
    },
    textPathDoc: {
      fontWeight: "400",
      fontSize: "18px",
      color: "#404A56",
    },
    textMethodDoc: {
      fontWeight: "600",
      fontSize: "12px",
      color: "#FFFFFF",
    },
    textDescDoc: {
      fontWeight: "300",
      fontSize: "14px",
      color: "#404A56",
    },
    legend: {
      fontFamily: "Roboto",
      fontWeight: "500",
      fontSize: "12px",
      color: "#4C5866",
    },
  },
});

export default Theme;
