const termsPY =
  '<div style="font-family: Roboto,sans-serif; font-weight:400; color: #787878; line-height: 1.5; padding: 15px"> ' +
  '  <h1 style="color: #00377b; font-weight:400" id="términos-y-condiciones-tigo-money"> ' +
  " Términos y condiciones Tigo Money " +
  "  </h1> " +
  "  <p> " +
  " Entre Mobile Cash Paraguay S.A. (en adelante Mobile Cash) y el Cliente, cuyo " +
  " nombre o razón social y demás datos de identificación y contacto, así como " +
  " el lugar y fecha del presente, se consignan en los campos correspondientes, " +
  " se celebra el presente contrato de prestación de servicios el cual se regirá " +
  " por las siguientes cláusulas que las partes a continuación acuerdan: " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" style="color: #00377b; font-weight:400" id="primera-objeto">Primera: Objeto</h3> ' +
  "  <p> " +
  " El presente contrato tiene por objeto establecer las condiciones de la " +
  " prestación del servicio Tigo Money por parte de Mobile Cash en beneficio del " +
  " Cliente. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="segunda-definiciones">Segunda: Definiciones</h3> ' +
  "  <p> " +
  " A efectos de este documento deberán considerarse las definiciones que a " +
  " continuación se enuncian: " +
  "  </p> " +
  "  <ol> " +
  " <li> " +
  " Mobile Cash: entidad de medios de pagos electrónicos (EMPE) con licencia " +
  " para operar expedida por el Banco Central del Paraguay del 30 de marzo del " +
  " 2015, que presta los servicios de la billetera Tigo Money. " +
  " </li> " +
  " <li> " +
  " Billetera electrónica: cuenta no bancaria en la que se almacena el dinero " +
  " electrónico y es utilizada para realizar las transacciones autorizadas " +
  " legalmente. " +
  " </li> " +
  " <li> " +
  " Cliente: persona física o jurídica que se suscribe con éxito a los " +
  " servicios de Mobile Cash mediante el presente contrato. El término " +
  ' "Cliente" incluirá a todo aquel que ejerza una representación o actúe bajo ' +
  " la dirección del titular de este contrato. " +
  " </li> " +
  " <li> " +
  " Conversión o Cash In: operación a través de la cual se transforma dinero " +
  " en efectivo a dinero electrónico por un importe igual al que se recibe, " +
  " deducidas las comisiones que correspondan, almacenándose el valor " +
  " monetario en un dispositivo o medio electrónico. " +
  " </li> " +
  " <li> " +
  " Reconversión o Cash Out: operación a través de la cual se transforma " +
  " dinero electrónico a dinero en efectivo, pudiendo deducirse comisiones " +
  " dependiendo del origen del dinero electrónico y del servicio utilizado por " +
  " el Cliente. " +
  " </li> " +
  " <li> " +
  " Acreditación: recepción de fondos en una cuenta de billete electrónico por " +
  " medios distintos a los de una conversión. " +
  " </li> " +
  " <li> " +
  " Clave de Seguridad: constituye la firma electrónica del Cliente para el " +
  " acceso a su billetera electrónica. Cuando el Cliente se suscribe por " +
  " primera vez al servicio, recibe una provisoria de forma automática la cual " +
  " debe ser modificada por el Cliente por otra de su propia y libre " +
  " escogencia, la cual en todos los casos será un dígito de 4 números. " +
  " </li> " +
  " <li> " +
  " SIM card: es la tarjeta o chip provisto al Cliente por Telefónica Celular " +
  " del Paraguay S.A. para permitirle acceder y utilizar los servicios " +
  " prestados por Mobile Cash. " +
  " </li> " +
  " <li> " +
  " Fideicomiso de garantía: contrato suscrito en el marco de la Ley N° 921/96 " +
  " “De Negocios Fiduciarios” entre Mobile Cash y un Fiduciario, según el cual " +
  " se encuentra garantizado todo saldo con que el Cliente cuente a su favor " +
  " de conformidad a lo que establece la Resolución 6/2020 o la que la " +
  " reemplace del Banco Central del Paraguay. " +
  " </li> " +
  "  </ol> " +
  '  <h3 style="color: #00377b; font-weight:400" id="tercera-ámbito-de-aplicación">Tercera: Ámbito de aplicación</h3> ' +
  "  <p> " +
  " El Cliente entiende y acepta que los saldos con que cuente a su favor no " +
  " constituyen depósitos bancarios asegurados por el Fondo de Garantía de " +
  " Depósitos administrado por el Banco Central del Paraguay, y, por lo mismo, " +
  " no devienen ni devendrán intereses o frutos civiles a su favor. Sin embargo, " +
  " los saldos a favor del Cliente o un tercero estarán garantizados por el " +
  " fideicomiso de garantía al cierre de las operaciones de cada día. " +
  "  </p> " +
  "  <p> " +
  " Asimismo, para operar con el servicio ofrecido por Mobile Cash, el Cliente " +
  " deberá suscribir sus datos personales ingresando desde su teléfono celular " +
  " con línea proveída por Tigo al *555#, a través de la web Tigo Money y la App " +
  " Tigo Money para dispositivos Android. Los datos requeridos son: " +
  "  </p> " +
  "  <p>a. Documento de Identidad Paraguayo <br />b. Fecha de nacimiento</p> " +
  "  <p> " +
  " Posterior al registro el cliente deberá ingresar en la web de registro Tigo " +
  " Money donde le solicitará sus datos, así como también la foto de la Cédula " +
  " Paraguaya Vigente. " +
  "  </p> " +
  "  <p> " +
  " a. Nombres y apellidos completos <br />b. Documento de identidad <br />c. " +
  " Nacionalidad <br />d. Actividad económica <br />e. Número de teléfono " +
  " <br />f. Localidad <br />g. Domicilio " +
  "  </p> " +
  "  <p> " +
  " En caso de que el cliente no puede autorregistrase podrá acercarse hasta un " +
  " Punto de Venta habilitado o a una Oficina de Tigo para presentar su cédula " +
  " de identidad paraguaya. Los representantes de atención al cliente deberán " +
  " utilizar la herramienta digital de registro web habilitada para capturar la " +
  " imagen de la cédula de identidad del Cliente y dar cumplimiento a los " +
  " requerimientos regulatorios locales establecidos. El Cliente deberá " +
  " presentar su cédula de identidad para el registro en un plazo máximo de " +
  " hasta 21 días. Transcurrido el plazo de 21 días, Mobile Cash podrá bloquear " +
  " la billetera electrónica del usuario hasta tanto el mismo registre todos los " +
  " datos de identidad que son requeridos por la normativa. " +
  "  </p> " +
  "  <p> " +
  " Para conocer más detalles del paso a paso del registro vía web, favor " +
  " <a " +
  ' href="https://ayuda.tigo.com.py/hc/es/articles/360040097313--C%C3%B3mo-realizar-el-registro-digital-de-mi-c%C3%A9dula-?_ga=2.75851926.280688831.1599147885-1589265533.1599147885" ' +
  ' style="color: #00c8ff;">ingresar aquí</a ' +
  " > " +
  "  </p> " +
  "  <p> " +
  " Será responsabilidad del cliente la verificación satisfactoria de la " +
  " transacción y el control del saldo en su billetera una vez finalizada la " +
  " operación. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="cuarta-límites-transaccionales">Cuarta: Límites transaccionales</h3> ' +
  "  <p> " +
  " El Cliente entiende y acepta que en cumplimiento de la Resolución N° 6/2020 " +
  " o la que la reemplace del Banco Central del Paraguay, Mobile Cash ha " +
  " incorporado los siguientes límites transaccionales a su servicio Tigo Money: " +
  "  </p> " +
  "  <ol> " +
  " <li> " +
  " <strong>Billetera Electrónica Tigo Money:</strong> <br />a. El titular " +
  " solo podrá tener una billetera electrónica a través de Mobile Cash. " +
  " <br />b. El saldo no podrá superar, en ningún momento, el equivalente a " +
  " tres (3) salarios mínimos para actividades diversas no especificadas de la " +
  " Capital de la República. <br />c. El valor monetario de las conversiones y " +
  " acreditaciones en cuenta no podrá exceder, por mes calendario, el " +
  " equivalente a los tres (3) salarios mínimos para actividades diversas no " +
  " especificadas de la Capital de la República. " +
  " </li> " +
  " <li> " +
  " <strong>Giros Tigo:</strong> <br />a. El monto de las transacciones del " +
  " remitente no podrá superar por mes calendario el importe equivalente a " +
  " cuarenta (40) jornales mínimos para actividades diversas no especificadas " +
  " de la Capital de la República. <br />b. El monto de las transacciones " +
  " recibidas por el beneficiario no podrá superar por mes calendario el " +
  " importe equivalente a cuarenta (40) jornales mínimos para actividades " +
  " diversas no especificadas de la Capital de la República. " +
  " </li> " +
  " <li> " +
  " <strong>Transferencias y Envíos (billetera a billetera):</strong> <br />a. " +
  " El monto de las transacciones del remitente no podrá superar por mes " +
  " calendario el importe equivalente a tres (3) salarios mínimos para " +
  " actividades diversas no especificadas de la capital de la República. " +
  " <br />b. El monto de las transacciones recibidas por el beneficiario no " +
  " podrá superar por mes calendario el importe equivalente a tres (3) " +
  " salarios mínimos para actividades diversas no especificadas de la capital " +
  " de la República. " +
  " </li> " +
  "  </ol> " +
  "  <p> " +
  " Los limites transaccionales especificados en la presente cláusula podrán " +
  " sufrir modificaciones en virtud a nueva normativa emitida por la SEPRELAD " +
  " y/o el BCP, en cuyo caso Mobile Cash dará aviso al Cliente a través de " +
  " medios comprobables. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="quinta-precio">Quinta: Precio</h3> ' +
  "  <p>Los precios de las comisiones se enuncian en el Anexo I del contrato.</p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="sexta-facturación-y-estatus-de-contribuyente"> ' +
  " Sexta: Facturación y estatus de contribuyente " +
  "  </h3> " +
  "  <p> " +
  " El Cliente que ha realizado un envío, o una reconversión o Cash Out, o ha " +
  " comprado saldo de servicios de telecomunicaciones en beneficio de líneas " +
  " conectadas a la red de Telefónica Celular del Paraguay S.A., podrá solicitar " +
  " su comprobante acercándose a una oficina de Tigo o llamando al *555 o al " +
  " 0981 555000. Las solicitudes de facturas por este tipo de transacciones " +
  " deberá ser realizadas en las oficinas de atención al cliente de Mobile Cash, " +
  " o llamando al *555, estando sujeta su entrega a un tiempo de respuesta que " +
  " será determinado por Mobile Cash. El Cliente que ha realizado un giro Tigo " +
  " desde alguno de los puntos de venta de Mobile Cash, deberá solicitar en el " +
  " mismo la factura correspondiente a la operación realizada. En todos los " +
  " casos, los tickets o facturas serán emitidas por el monto íntegro de las " +
  " comisiones cobradas. EL Cliente se compromete a hacer entrega de copia de " +
  " toda la documentación impositiva que indique su condición de contribuyente, " +
  " quedando expresamente establecido que en caso contrario éste acepta ser " +
  " considerado como consumidor final no inscripto a los efectos del IVA y del " +
  " Impuesto a la Renta. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="séptima-reversión">Séptima: Reversión</h3> ' +
  "  <p> " +
  " Cuando existan errores en las transacciones por culpa imputable a Mobile " +
  " Cash y/o quien por su cuenta y orden realice transacciones de Carga de " +
  " Dinero, Giros, Transferencias y Envíos, se podrán reversar siempre y cuando " +
  " el destino tenga el 100 % (cien por ciento) del dinero acreditado. Si este " +
  " utilizó una parte, NO se podrá realizar la reversión de un monto parcial. Al " +
  " reversar una transacción de Giro, Transferencia o Envío, el monto a retornar " +
  " al origen comprende del monto total enviado y la comisión abonada (IVA " +
  " incluido). Se podrán realizar reversiones de los servicios de Carga de " +
  " Dinero, Giros, Transferencias y Envíos en un período de hasta 60 (sesenta) " +
  " días posterior a la fecha de la transacción. " +
  "  </p> " +
  "  <p><strong>Solicitudes de reversiones según el servicio</strong></p> " +
  "  <ul> " +
  " <li> " +
  " Solicitudes de reversiones de los servicios de Carga de dinero y Giro " +
  " únicamente podrán ser realizados en el Punto de Venta o Caja de una " +
  " Oficina Tigo donde se efectuó la transacción. " +
  " </li> " +
  " <li> " +
  " Solicitudes de reversiones de los servicios realizados desde la billetera " +
  " electrónica, Transferencias y Envíos podrán realizarse llamando al *555 " +
  " 0981 55500 o acercándose a una Oficina de Tigo. " +
  " </li> " +
  " <li> " +
  " Solicitudes de reversiones de Pagos de Servicios Básicos, ANDE, COPACO y " +
  " ESSAP, podrán procesarse si el pedido fue realizado única y exclusivamente " +
  " en el día del Pago, hasta el horario de las 15:00 Horas. Pasado dicho " +
  " horario el cliente deberá realizar su reclamo por Mesa Entrada de la " +
  " Entidad prestadora del servicio, presentando su comprobante que puede " +
  " imprimirlo en el siguiente enlace " +
  ' <a href="https://www.pagoexpress.com.py/ticket/" style="color: #00c8ff;" ' +
  "  >https://www.pagoexpress.com.py/ticket/</a " +
  " >. El Cliente podrá comunicarse ante cualquier consulta a nuestros canales " +
  " de atención al *555, 0981 555000 o acercarse a una Oficina de Tigo. " +
  " </li> " +
  " <li> " +
  " Solicitudes de reversiones o anulaciones de Pagos de Cuotas, Facturas, " +
  " Préstamos o Aportes de Entidades Externas, NO podrán realizarse salvo " +
  " autorización de la Entidad prestadora del servicio dentro del período del " +
  " mes en que se efectuó el pago, pasado dicho periodo. El Cliente deberá " +
  " realizar su reclamo en la Entidad prestadora del servicio abonado. El " +
  " Cliente podrá realizar sus consultas en nuestros canales de atención al " +
  " *555, 0981 555000 o acercándose a una Oficina de Tigo. " +
  " </li> " +
  " <li> " +
  " Errores en la transacción de retiro de dinero en que el Cajero Automático " +
  " no emitió del efectivo pero si debitó el dinero de la billetera, el " +
  " cliente deberá elevar su consulta o solicitud de devolución según " +
  " corresponda al *555, 0981 555000 o acercarse a una Oficina de Tigo. " +
  " </li> " +
  " <li> " +
  " Errores en la transacción de pago en comercio en POS, donde el dispositivo " +
  " no emitió el voucher pero sí se procesó el débito del dinero de la " +
  " billetera, el cliente deberá elevar su consulta o solicitud de devolución, " +
  " según corresponda, al *555, 0981 555000 o acercarse a una Oficina de Tigo. " +
  " </li> " +
  " <li> " +
  " No se podrán reversar en ningún caso las compras de Minicargas o " +
  " Paquetigos ofrecidos para la venta por Telefónica Celular del Paraguay " +
  " S.A., realizadas desde el *555#, App Tigo Money, *222# App Tigo Shop, " +
  " Portal Tigo. " +
  " </li> " +
  " <li> " +
  " No se podrán reversar pagos realizados desde una web externa donde está " +
  " habilitado el Botón de Pagos de Tigo Money para recaudación de sus " +
  " servicios. El Cliente deberá comunicarse ante cualquier consulta o reclamo " +
  " directamente con la Entidad Recaudadora. " +
  " </li> " +
  "  </ul> " +
  '  <h3 style="color: #00377b; font-weight:400" id="octava-seguridad">Octava: Seguridad</h3> ' +
  "  <p> " +
  " Al activar el servicio de billetera electrónica de Tigo Money, la plataforma " +
  " tecnológica a través de la cual se presta los servicios Tigo Money proveerá " +
  " al Cliente una clave numérica provisoria de manera automática que deberá ser " +
  " cambiada por una nueva clave personal a elección del Cliente. Esta clave " +
  " personal no debe ser revelada por el Cliente a ninguna persona pues " +
  " constituye la firma electrónica del Cliente, que, a tenor de lo previsto en " +
  " la Ley N° 4017/2010 “De Validez Jurídica de la Firma Electrónica, la Firma " +
  " Digital, los Mensajes de Datos y el Expediente Electrónico”, tiene el mismo " +
  " valor que su firma holográfica, asumiendo el Cliente total, plena e " +
  " ilimitada responsabilidad civil, penal y administrativa por los usos que se " +
  " hagan de la misma. Mobile Cash no se hace responsable en ningún caso de lo " +
  " que pudiera suceder si el Cliente provisiona a terceras partes sus datos " +
  " personales, Clave Provisoria, teléfono asociado a la billetera o la Tarjeta " +
  " de Tigo Money asociada a la billetera. " +
  "  </p> " +
  "  <p> " +
  " <strong " +
  " >En ningún caso y bajo ninguna circunstancia Mobile Cash, sus " +
  " dependientes, empleados, contratistas, agentes, puntos de ventas adheridos " +
  " solicitarán mediante llamada telefónica, mensaje de texto, correo " +
  " electrónico o equivalentes la clave de seguridad del cliente.</strong " +
  " > " +
  "  </p> " +
  "  <p> " +
  " El Cliente es responsable de proteger su clave transaccional y no deberá " +
  " compartirla ni dejarla en un lugar visible con acceso a terceras partes para " +
  " que no pueda ser leída (por ejemplo, grabadas en el teléfono, en la pantalla " +
  " de inicio, etc.), de manera a evitar riesgos de fraudes. Se recomienda no " +
  " dejar los teléfonos ni la tarjeta de Tigo Money desatendidos o accesibles " +
  " para terceros, lo correcto sería mantener con un código de acceso al " +
  " teléfono con la línea que posee asociada la billetera electrónica. El " +
  " Cliente es responsable por las pérdidas resultantes de su incapacidad para " +
  " proteger su Clave de seguridad y/o proteger el acceso a su billetera " +
  " electrónica, en cualquiera de sus interfaces, teléfono *555#, Tigo Money App " +
  " o Tarjeta Tigo Money vinculada a su billetera. " +
  "  </p> " +
  "  <p> " +
  " El Cliente es libre de elegir la clave de seguridad que desee. La clave " +
  " consta de 4 (cuatro) dígitos. No podrá establecerse como clave de seguridad " +
  " números consecutivos como 1234 o 4321, números repetidos como 1111 o 3333, y " +
  " tampoco se aceptan letras ni caracteres especiales. Se recomienda establecer " +
  " una clave difícil de predecir y no elegir una clave de seguridad que tenga " +
  " aproximación a sus datos personales como la fecha de nacimiento o número de " +
  " documento de identidad. " +
  "  </p> " +
  "  <p> " +
  " En caso de que el Cliente considere que su clave se encuentre comprometida, " +
  " será responsable de modificarla cuando sea necesario y debe estar atento " +
  " ante riesgos de ingeniería social (por ejemplo, llamadas de extorsión o de " +
  " promociones inexistentes no comunicadas por Mobile Cash). En caso de que no " +
  " se realice dicha modificación, se encuentran comprometidos los fondos " +
  " disponibles en su billetera electrónica, que podrán ser utilizados sin su " +
  " consentimiento. Se recomienda realizar el cambio de clave con frecuencia, de " +
  " manera evitar los riesgos de fraude o estafa. " +
  "  </p> " +
  "  <p> " +
  " En caso de pérdida, sustracción, hurto o robo del SIM card de la línea " +
  " telefónica vinculada con la billetera electrónica Tigo Money, el Cliente " +
  " titular es responsable de comunicar lo acontecido llamando inmediatamente al " +
  " *611 o al 0981 611611, a fin de solicitar el bloqueo de la misma. " +
  "  </p> " +
  "  <p> " +
  " Si el Cliente ingresa erróneamente 3 (tres) veces seguidas la Clave su " +
  " billetera electrónica, en cualquiera de sus interfaces, *555#, App Tigo " +
  " Money o Tarjeta Tigo Money asociada a la billetera, esta quedara bloqueada " +
  " por seguridad, para desbloquear la billetera el Cliente titular de misma " +
  " deberá comunicarse al *611, 0981 611611 o debe acercarse a las oficinas de " +
  " Tigo, para solicitar el envío de una nueva Clave. " +
  "  </p> " +
  "  <p> " +
  " Ante casos de actividad sospechosa o ilegal en relación al servicio, reporta " +
  " lo acontecido a nuestros canales de al *611 o al 0981 611611. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="novena-puntos-tigo-money">Novena: Puntos Tigo Money</h3> ' +
  "  <p> " +
  " El Cliente podrá realizar conversiones o Cash In y reconversiones o Cash Out " +
  " en los puntos de venta Tigo Money, y en los cajeros automáticos habilitados " +
  " por Mobile Cash. Los puntos de venta para giros o recepción de giros, " +
  " conversiones o Cash In o reconversiones o Cash Out, estarán disponibles en " +
  " los horarios y días acordados entre los mismos y Mobile Cash, pudiendo el " +
  " Cliente requerir esta información en las oficinas de Mobile Cash, desde la " +
  " aplicación móvil o llamando al *611 o 0981 611611. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="décima-resumen-de-transacciones">Décima: Resumen de transacciones</h3> ' +
  "  <p> " +
  " El Cliente podrá acceder a la información de sus transacciones ingresando al " +
  " *555# opción 7. Mi Billetera, opción 3. Consulta tus últimas 3 " +
  " transacciones, paso siguiente el sistema solicitará el ingreso de la clave " +
  " de seguridad para poder visualizar las transacciones realizadas desde su " +
  " billetera, para consulta de su saldo, podrá verificar ingresando al *555# " +
  " opción 0 Mi Saldo, paso siguiente el sistema solicitará el ingreso de la " +
  " clave de seguridad para poder visualizar el saldo disponible en billetera. " +
  "  </p> " +
  "  <p> " +
  " También a través de la web Tigo Money y la App Tigo Money para dispositivos " +
  " Android el cliente tiene disponible el detalle de sus últimas transacciones " +
  " del mes y su saldo disponible en línea. El uso de la aplicación móvil (Smart " +
  " App) consumirá saldo del paquete de datos del Cliente. " +
  "  </p> " +
  "  <p> " +
  " El cliente recibirá una notificación vía mensaje de texto del remitente 55 " +
  " posterior a cada transacción culminada exitosamente realizada en cualquiera " +
  " de sus canales, *555#, App Tigo Money o web Tigo Money, en cada notificación " +
  " se mostrará el detalle de la transacción realizada, Monto de la operación y " +
  " N° de referencia como comprobante de la transacción. " +
  "  </p> " +
  "  <p> " +
  " Para casos en que el cliente desee un detalle de sus transacciones de un " +
  " período más extendido, deberá acercarse a oficinas de Mobile Cash el Titular " +
  " de la billetera a solicitar por escrito previa presentación de su documento " +
  " nacional de identidad vigente. Dicha solicitud solo podrá realizar el " +
  " titular de la billetera, no así de la línea. " +
  "  </p> " +
  "  <p> " +
  " El cliente titular de la billetera es responsable de verificar sus " +
  " transacciones realizadas y sus saldos posterior a cada transacción " +
  " confirmada con su clave de seguridad. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="undécima-tarjeta-tigo-money">Undécima: Tarjeta Tigo Money</h3> ' +
  "  <p> " +
  " La activación de su billetera electrónica permitirá al Cliente acceder a la " +
  " Tarjeta Tigo Money que estará vinculada a dicha billetera electrónica, de " +
  " modo tal a permitirle simplificar sus procesos de pagos móviles en todos los " +
  " establecimientos comerciales adheridos a la Red Dinelco, así como la " +
  " reconversión o cash-out en la red de cajeros automáticos de Dinelco, " +
  " empleando a dicho efecto la misma Clave asociada a su billetera electrónica. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="duodécima-autorizaciones">Duodécima: Autorizaciones</h3> ' +
  "  <p>El Cliente autoriza suficientemente a Mobile Cash:</p> " +
  "  <ol> " +
  " <li> " +
  " Abrir una cuenta básica de ahorro por cuenta y orden del Cliente, en " +
  " VISION BANCO S.A.E.C.A. (cuyos términos y condiciones se encuentran " +
  " disponibles en la página web " +
  " <a " +
  '  href="https://www.visionbanco.com/files/contrato-cuenta-basica-ahorro.pdf" ' +
  '  style="color: #00c8ff;">https://www.visionbanco.com/files/contrato-cuenta-basica-ahorro.pdf</a ' +
  " >) a efectos de dar cumplimiento a la Resolución 6/2020 del Banco Central " +
  " del Paraguay en lo que refiere a los saldos inactivos o en exceso a los " +
  " límites transaccionales aludidos en la cláusula cuarta, incluso cuando las " +
  " transacciones realizadas por el Cliente no cumplan con las condiciones " +
  " establecidas en la citada Resolución. El cliente solo podrá tener una " +
  " cuenta básica en la Entidad según Resolución 25/2013 del Banco Central del " +
  " Paraguay. " +
  " </li> " +
  " <li> " +
  " Que pacte con la entidad financiera las condiciones aplicables a la " +
  " referida cuenta básica de ahorro, como así también a que realice cuantos " +
  " débitos y créditos sean necesarios en su billetera electrónica a efectos " +
  " de dar cumplimiento a lo previsto en la Resolución 6/2020 del Banco " +
  " Central del Paraguay y en este contrato. El Cliente, también autoriza a " +
  " que, ante movimientos de saldos en la cuenta básica de ahorro, se realicen " +
  " los débitos y créditos que sean necesarios en ésta, a los efectos de que " +
  " dichos saldos sean reflejados en la billetera electrónica vinculada. " +
  " </li> " +
  " <li> " +
  " Los saldos en billetera electrónica que no registren movimiento durante al " +
  " menos 180 días contados desde la última transacción deberán ser " +
  " transferidos a la cuenta básica de ahorro que se trate. " +
  " </li> " +
  " <li> " +
  " Cuando se realizara un giro y el mismo no fuera acreditado en una " +
  " billetera electrónica nominada por el beneficiario en un plazo de 10 " +
  " (diez) días contados desde el momento en que fue transferido, deberá ser " +
  " retornado a la billetera electrónica del remitente o titular. A dicho " +
  " efecto, el Cliente en su carácter de remitente autoriza a Mobile Cash para " +
  " que abra una cuenta básica de ahorro por cuenta y orden suya, a efectos de " +
  " depositar allí los saldos que no hayan sido entregados al beneficiario. " +
  " </li> " +
  " <li> " +
  " Facilitar información adicional para el tratamiento justificado por la " +
  " necesidad de cumplir con los requisitos legales aplicables, como son el " +
  " atender un requerimiento de una entidad gubernamental o administrativa en " +
  " el ejercicio de sus funciones o por orden de autoridad judicial " +
  " competente, o para proteger los derechos, la propiedad o la seguridad de " +
  " Mobile Cash. Mobile Cash no comercializa sus datos a terceros y se ciñe a " +
  " lo dispuesto en materia operativa en los límites establecidos dentro de su " +
  " <a " +
  '  href="https://www.tigo.com.py/legales#terminos-y-condiciones-uso-de-web" style="color: #00c8ff;" ' +
  '  title="Aviso Privacidad" ' +
  "  >Aviso de Privacidad.</a " +
  " > " +
  " </li> " +
  "  </ol> " +
  "  <p> " +
  " En cualquiera de los casos en que por cualquier motivo Mobile Cash no pueda " +
  " abrir la cuenta de depósito por cuenta y orden del Cliente, o se cumplieran " +
  " las reglas de inactividad sin que existiera una cuenta básica de ahorro que " +
  " pudiera ser utilizada, el Cliente autoriza suficientemente a Mobile Cash a " +
  " transferir al fideicomiso de garantía los saldos, debiendo el Cliente a " +
  " partir de ese momento reclamar los mismos al fiduciario que administre el " +
  " mismo, que, a su vez, podrá emitir una orden de pago que se encontrará " +
  " disponible para el Cliente. " +
  "  </p> " +
  "  <p> " +
  " Ante la ocurrencia de los eventos aquí mencionados, Mobile Cash dará aviso " +
  " al Cliente a través de algún medio debidamente comprobable especificando los " +
  " datos relativos a la cuenta y transacción efectuada en nombre del Cliente. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="decimotercera-interrupción-del-servicio"> ' +
  " Decimotercera: Interrupción del servicio " +
  "  </h3> " +
  "  <p> " +
  " Mobile Cash no será responsable ni podrá ser sujeto a reclamo por " +
  " incumplimiento o deficiencias del servicio Tigo Money que sean consecuencia " +
  " de problemas técnicos que surjan como resultado de caso fortuito o fuerza " +
  " mayor, extinción de la licencia, o cualquier otro acto de autoridad. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="decimocuarta-responsabilidad">Decimocuarta: Responsabilidad</h3> ' +
  "  <p> " +
  " Mobile Cash no asume frente al Cliente ninguna responsabilidad por daños y " +
  " perjuicios emergentes de este contrato, incluido lucro cesante, ni por " +
  " cualquier daño directo o indirecto, consecuencial, moral, especial o " +
  " incidental alguno sea o no relacionado con la prestación del servicio Tigo " +
  " Money. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="decimoquinta-cancelación-de-servicios"> ' +
  " Decimoquinta: Cancelación de servicios " +
  "  </h3> " +
  "  <p> " +
  " El Cliente podrá cancelar los servicios Tigo Money en cualquier momento y " +
  " sin expresión de causa, para lo cual deberá acercarse a las oficinas de Tigo " +
  " para solicitarla. Mobile Cash podrá, asimismo, en cualquier momento y sin " +
  " expresión de causa, cancelar los servicios al Cliente dando aviso al Cliente " +
  " a través de un medio debidamente comprobable. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="decimosexta-reclamos">Decimosexta: Reclamos</h3> ' +
  "  <p> " +
  " El Cliente que desee presentar un reclamo deberá hacerlo llamando al *611 o " +
  " al 0981 611611, o bien apersonándose en las oficinas de Tigo distribuidas en " +
  " todo el territorio de la República. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="decimoséptima-cesión">Decimoséptima: Cesión</h3> ' +
  "  <p>El presente contrato no podrá ser objeto de cesión.</p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="decimoctava-antilavado">Decimoctava: Antilavado</h3> ' +
  "  <p> " +
  " El Cliente declara bajo fe de juramento que los bienes objeto del presente " +
  " contrato no se encuentran relacionados ni serán destinados a actividades que " +
  " constituyan delitos tipificados en la ley penal vigente, ni a aquellos " +
  " ilícitos devenidos de la legitimación del dinero o bienes que procedan, " +
  " directa o indirectamente, de cualquier actividad ilícita o delictiva. En " +
  " igual sentido, el mismo manifiesta que conoce y acepta los términos de la " +
  " Ley Nº 1015/97 que establece los procedimientos orientados a prevenir e " +
  " impedir actos destinados a la legitimación del dinero o de bienes " +
  " procedentes de actividades delictivas, así como también lo prescripto por la " +
  " Ley Nº 4024/10, la cual castiga los hechos punibles de terrorismo, " +
  " asociación terrorista y financiamiento del terrorismo. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="decimonovena-resolución-de-conflictos"> ' +
  " Decimonovena: Resolución de conflictos " +
  "  </h3> " +
  "  <p> " +
  " Ante toda controversia, discrepancia, desavenencia, reclamo por cualquier " +
  " cuestión, sea cual fuere su índole o naturaleza, relativa a la " +
  " interpretación, validez, invalidez, calificación, aplicación y/o alcance, " +
  " acerca de su cumplimento, incumplimiento, recepción, ejecución o inejecución " +
  " total o parcial, en cualquiera de sus aspectos o con motivo o en ocasión de " +
  " la rescisión, resolución, conclusión o cualquier otra contingencia que se " +
  " relacione directa o indirectamente con el presente contrato que no pueda ser " +
  " resuelta por negociación directa de las partes, deberá ser sometido a los " +
  " Juzgados y Tribunales de la ciudad de Asunción, renunciando las partes " +
  " expresamente a cualquier otro fuero o jurisdicción. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="vigésima-domicilio-constituido">Vigésima: Domicilio constituido</h3> ' +
  "  <p> " +
  " El Cliente tiene por válido la información relativa a su domicilio que " +
  " Mobile Cash posee en su sistema de identificación de clientes de Tigo Money: " +
  " E-KYC, en donde se tendrán por válidas todas las notificaciones que se " +
  " cursaren y por recibidas las facturas que allí se remitan. Mobile Cash " +
  " constituye domicilio especial en Zavala Cué y Artillería, Fernando de la " +
  " Mora, República del Paraguay, donde se tendrán por válidas las " +
  " notificaciones judiciales y extrajudiciales que allí se efectúen. Las partes " +
  " podrán modificar el domicilio debiendo a tal fin comunicar fehacientemente y " +
  " por escrito el nuevo domicilio a la otra parte. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="vigesimoprimera-otros-productos-ofrecidos"> ' +
  " Vigesimoprimera: Otros productos ofrecidos " +
  "  </h3> " +
  "  <p> " +
  " Mobile Cash podrá constituirse como canal de comercialización de productos " +
  " de otras entidades de intermediación financiera, como ser microcréditos, " +
  " microseguros y microahorros. En todos los casos el Cliente deberá aceptar " +
  " los Términos y Condiciones establecidos por las instituciones que prestan el " +
  " servicio, siendo Mobile Cash únicamente un canal de comercialización de " +
  " éstos productos ofrecidos por las entidades asociadas. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="vigesimosegunda-actualización-de-información"> ' +
  " Vigesimosegunda: Actualización de información " +
  "  </h3> " +
  "  <p> " +
  " El Cliente será responsable de acceder periódicamente a la plataforma a leer " +
  " los Términos y Condiciones, incluyendo el Anexo I – Tarifario de los " +
  " servicios establecidos por Mobile Cash y sus actualizaciones a efectos de " +
  " mantenerse informado sobre las modificaciones implementadas en la prestación " +
  " del servicio, cambios de tarifas, nuevos productos o promociones. Mobile " +
  " Cash, es responsable de notificar al Cliente acerca de lo cambios realizados " +
  " en la plataforma como pueden ser modificaciones en el tarifario, límites, " +
  " servicios y nuevos productos. Las notificaciones son realizadas vía mensaje " +
  " de texto desde número remitente 55. Toda la información acerca de los " +
  " servicios prestados por Mobile Cash se encuentra publicada en la web " +
  ' <a href="https://money.tigo.com.py/" style="color: #00c8ff;">https://money.tigo.com.py/</a>. También ' +
  " según requiera serán publicadas en medios de comunicación. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="vigesimotercera-datos-personales-y-crediticios-consentimiento"> ' +
  " Vigesimotercera: Datos Personales y Crediticios. Consentimiento " +
  "  </h3> " +
  "  <ol> " +
  " <li> " +
  " <code> La entrega de todos los datos requeridos para la contratación del Servicio es obligatoria, de forma que la ausencia o inexactitud de dichos datos, " +
  " cuya veracidad y/o exactitud podrá ser comprobada por Mobile Cash, facultarán a ésta para no aceptar la activación del Servicio o, en su caso, proceder a su suspensión. " +
  " En caso de que existan datos señalados como de cumplimentación opcional, la respuesta será potestativa para El Cliente y será destinada a facilitar la activación del " +
  " Servicio y a la mejor adecuación de este. Mobile Cash utilizará como teléfono de contacto y/o correo electrónico para comunicarse con El Cliente para la gestión de " +
  " cualquiera de los servicios contratados. En caso de que (i) El Cliente no facilite un número de teléfono móvil y/o correo electrónico de contacto, (ii) el número y/o " +
  " correo electrónico facilitados no fueran válidos o (iii) Mobile Cash no dispusiera de los mismos por cualquier motivo, El Cliente autoriza a MOBILE CASH a utilizar el " +
  "  número de teléfono móvil y/o el correo electrónico objeto del Servicio contratado como medios de contacto válido. " +
  " </code> " +
  ' <ol start="2"><br> ' +
  " <li> " +
  " <code> El Cliente otorga su consentimiento previo, expreso e informado a MOBILE CASH, para el tratamiento de sus datos personales, datos biométricos referentes a " +
  " sus huellas dactilares, voz, imagen de su rostro u otros datos similares, datos crediticios y financieros conforme a la legislación vigente y al Aviso de Privacidad " +
  " de MOBILE CASH  publicado en: https://www.tigo.com.py/legales#terminos-y-condiciones-uso-de-web, el cual podrá ser modificado, cambiado o actualizado debido a " +
  " nuevos requerimientos legales, nuestras propias necesidades por los productos o servicios que ofrecemos,  nuestras prácticas, programas o políticas de privacidad, " +
  " cambios en nuestro modelo de negocio u otras causas, a través del sitio web u otras aplicaciones de MOBILE CASH. " +
  " </code> " +
  ' <ol start="3"><br> ' +
  " <li> " +
  " <code> El Cliente de manera voluntaria declara y acepta: (a) que la finalidad de la recolección y el tratamiento de sus datos personales incluirá, entre otros, " +
  " la prestación de los servicios contratados, ofrecimiento de productos y servicios, envío de comunicaciones relacionadas con los servicios y productos, " +
  " promociones comerciales, mejora del servicio, encuestas de satisfacción, estudio de crédito, gestión de cobro, servicio de atención al cliente, soporte técnico " +
  " u otros servicios relacionados, ya sea que se presten de forma directa o a través de terceros, así como aquellas finalidades descritas en el Aviso de Privacidad " +
  " de MOBILE CASH.  En todo caso, con la instalación de los servicios, se autoriza a MOBILE CASH para la realización del tratamiento de todos los datos necesarios " +
  " para la debida prestación de los servicios; (b) que la finalidad de la recolección y el tratamiento de sus datos biométricos referentes a sus huellas dactilares, " +
  " voz, imagen de su rostro, u otros datos similares, los cuales son considerados datos sensibles conforme a la legislación vigente, es solamente para (i) la " +
  " validación de su identidad por parte de MOBILE CASH  en los procesos de venta o postventa u otorgamiento de créditos, con el fin de prevenir fraude, lavado de " +
  " activos, financiación del terrorismo y otras actividades ilegítimas, y (ii) permitirle su autenticación electrónica, siempre que El Cliente así lo decida, en " +
  " ciertos productos y servicios ofrecidos por MOBILE CASH; (c) que conoce los derechos que le asisten, incluidos el derecho de  acceso, actualización, rectificación, " +
  " supresión, eliminación, oposición, portabilidad de sus datos, y cualquier otro de acuerdo a la legislación vigente y como ejercerlos conforme a lo establecido en " +
  " el Aviso de Privacidad de MOBILE CASH y (d) que puede revisar el Aviso de Privacidad de MOBILE CASH  publicado en el siguiente " +
  " link: https://www.tigo.com.py/legales#tigo-politicas-de-privacidad, para conocer acerca de nuestras prácticas para el tratamiento de los datos personales. " +
  " Asimismo, El Cliente autoriza expresamente a MOBILE CASH para que suministre sus datos personales a terceros proveedores de servicios o productos o socios " +
  " comerciales encargados del tratamiento de los datos y a las entidades afiliadas y subsidiarias de MOBILE CASH u otras compañías pertenecientes al mismo grupo " +
  " empresarial, dentro y fuera de la República del Paraguay, con fines relacionados a la prestación del servicio y a lo indicado en el Aviso de Privacidad de MOBILE " +
  " CASH. " +
  " </code> " +
  ' <ol start="4"><br> ' +
  " <li> " +
  " <code> Además, El Cliente autoriza de forma irrevocable a MOBILE CASH , a que recabe por sí o a través de terceros información periódica relacionada con su " +
  "  situación patrimonial, solvencia económica o cumplimiento de obligaciones comerciales a burós de información crediticia o cualquier empresa especializada " +
  "  en el ramo. Asimismo, autoriza a MOBILE CASH o al tercero a quien éste autorice a confirmar, certificar o verificar los datos por El Cliente declarados o " +
  "  provistos, a fin de que pueda contar con elementos de juicio o análisis imprescindibles para hacer una evaluación de riesgo financiero u otras evaluaciones. " +
  "  </code> " +
  "  </li> " +
  " </ol> " +
  " </li> " +
  "  </ol> " +
  "  </li> " +
  " </ol> " +
  " </li> " +
  "  </ol> " +
  '  <h3 style="color: #00377b; font-weight:400" id="anexo-i">Anexo I</h3> ' +
  "  <p> " +
  " Por la prestación de los servicios Tigo Money Mobile Cash cobrará al Cliente " +
  " las siguientes comisiones: " +
  "  </p> " +
  '  <div class="table-scroller"> ' +
  ' <table id="table-py1OJGbPk30i30dt1VlzpKjH-0" style="border:1px solid #e5eaf2; background-color: #fff; line-height: 1.5; ' +
  "  width: 100%; " +
  " border: 1px solid #e5eaf2; " +
  " background-color: #fff; " +
  " border-collapse: separate; " +
  " text-indent: initial; " +
  " border-spacing: 2px; " +
  ' display: table;"> ' +
  ' <thead align="left" > ' +
  " <thead> " +
  "  <tr> " +
  "  <th " +
  ' width="65%" ' +
  ' class="sort is-active" ' +
  ' data-text="Concepto" ' +
  ' id="table-py1OJGbPk30i30dt1VlzpKjH-0-0" ' +
  ' data-table="#table-py1OJGbPk30i30dt1VlzpKjH-0" ' +
  ' data-th="0" ' +
  ' data-order="asc" ' +
  ' style=" border-bottom: 2px solid #00377b;  color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;" ' +
  "  > " +
  ' <div class="d-flex"> ' +
  ' <span class="mr-auto">Concepto</span> ' +
  " </div> " +
  "  </th> " +
  '  <th style=" border-bottom: 2px solid #00377b;  color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;" ' +
  ' width="35%" ' +
  ' class="sort" ' +
  ' data-text="Costo" ' +
  ' id="table-py1OJGbPk30i30dt1VlzpKjH-0-1" ' +
  ' data-table="#table-py1OJGbPk30i30dt1VlzpKjH-0" ' +
  ' data-th="1" ' +
  ' data-order="" ' +
  "  > " +
  ' <div class="d-flex"> ' +
  ' <span class="mr-auto">Costo</span> ' +
  " </div> " +
  "  </th> " +
  "  </tr> " +
  " </thead> " +
  " <tbody> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Carga de dinero a tu Billetera</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Gs. 0</td> ' +
  "  </tr> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Envío entre billeteras Tigo</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Gs. 0(*)</td> ' +
  "  </tr> " +
  " " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Minicargas/Paquetigos</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Gs. 0</td> ' +
  "  </tr> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Pago de facturas</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Gs. 0</td> ' +
  "  </tr> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Pago en comercios</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Gs. 0</td> ' +
  "  </tr> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Retiro de un Giro recibido</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Gs. 0</td> ' +
  "  </tr> " +
  " </tbody> " +
  " </table> " +
  "  </div> " +
  " " +
  "  <p> " +
  " <strong>Nota:</strong> Los envíos* realizados entre billeteras de Tigo Money " +
  " son sin costo para el remitente y registran un recargo del 4 % sobre el " +
  " monto total extraído para el receptor. " +
  "  </p> " +
  "  <br /> " +
  " " +
  '  <div class="table-scroller"> ' +
  ' <table id="table-py1OJGbPk30i30dt1VlzpKjH-1" style="border:1px solid #e5eaf2; background-color: #fff; line-height: 1.5; ' +
  "  width: 100%; " +
  " border: 1px solid #e5eaf2; " +
  " background-color: #fff; " +
  " border-collapse: separate; " +
  " text-indent: initial; " +
  " border-spacing: 2px; " +
  ' display: table;"> ' +
  " <thead> " +
  "  <tr> " +
  '  <th style=" border-bottom: 2px solid #00377b;  color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;" ' +
  ' width="65%" ' +
  ' class="sort is-active" ' +
  ' data-text="Concepto" ' +
  ' id="table-py1OJGbPk30i30dt1VlzpKjH-1-0" ' +
  ' data-table="#table-py1OJGbPk30i30dt1VlzpKjH-1" ' +
  ' data-th="0" ' +
  ' data-order="asc" ' +
  "  > " +
  ' <div class="d-flex"> ' +
  ' <span class="mr-auto">Concepto</span> ' +
  " </div> " +
  "  </th> " +
  '  <th style=" border-bottom: 2px solid #00377b;  color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;" ' +
  ' width="35%" ' +
  ' class="sort" ' +
  ' data-text="Costo" ' +
  ' id="table-py1OJGbPk30i30dt1VlzpKjH-1-1" ' +
  ' data-table="#table-py1OJGbPk30i30dt1VlzpKjH-1" ' +
  ' data-th="1" ' +
  ' data-order="" ' +
  "  > " +
  ' <div class="d-flex"> ' +
  ' <span class="mr-auto">Costo</span> ' +
  " </div> " +
  "  </th> " +
  "  </tr> " +
  " </thead> " +
  " <tbody> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Giros desde el Punto de venta Tigo Money</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">5 %</td> ' +
  "  </tr> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Giros desde tu billetera Tigo Money</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">4 %</td> ' +
  "  </tr> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Giros Interoperados enviados desde tu billetera</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">4 %</td> ' +
  "  </tr> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Retiro de carga de Billetera</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">5 %</td> ' +
  "  </tr> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Retiro de dinero de envío recibido</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">4 %</td> ' +
  "  </tr> " +
  " " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Retiro de Giro interoperado recibido en tu billetera</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">4 %</td> ' +
  "  </tr> " +
  " </tbody> " +
  " </table> " +
  "  </div> " +
  " " +
  "  <p> " +
  " Toda comisión que sea abonada por el usuario en un Punto de Venta o Agente " +
  " Autorizado de Tigo, el comerciante ó Agente está obligado a entregar la " +
  " factura legal por el monto de la comisión y el cliente tiene el derecho de " +
  " solicitar su factura legal en el mismo momento y lugar. " +
  "  </p> " +
  "  <p> " +
  " De igual manera, las transacciones realizadas desde tu billetera donde " +
  " abones una comisión, la factura legal con los cargos del IVA, se pueden " +
  " solicitar en cualquier Sucursal de Tigo en horarios y días hábiles. " +
  "  </p> " +
  '  <h3 style="color: #00377b; font-weight:400" id="límites-de-la-billetera-electrónica"> ' +
  " Límites de la Billetera electrónica " +
  "  </h3> " +
  "  <ul> " +
  " <li> " +
  " Giro enviado desde un Punto Tigo Money: <strong>Gs. 3.900.000</strong> " +
  " </li> " +
  " <li> " +
  " Saldo Máximo en billetera Electrónica: <strong>Gs. 7.650.000</strong> " +
  " </li> " +
  " <li> " +
  " Saldo Máximo en billetera Electrónica con cuenta bancaria asociada: " +
  " <strong>Gs. 15.300.000</strong> " +
  " </li> " +
  "  </ul> " +
  "  <p> " +
  " <strong>Nota:</strong> Si enviás o cargás dinero por montos superiores al " +
  " límite diario, Tigo Money podrá habilitar una cuenta bancaria asociada a tu " +
  " Billetera, de manera a permitir un mayor nivel de transacciones. Para la " +
  " apertura de la cuenta bancaria cuando superas los montos limites de la " +
  " billetera, Mobile Cash te solicitará que presentes comprobantes de ingresos " +
  " que respalden tu actividad económica independiente o en relación de " +
  " dependencia. " +
  "  </p> " +
  "  <p> " +
  " <br />La habilitación de la cuenta bancaria se realizará de manera gratuita, " +
  " sin cargos de mantenimiento y será accesible las 24 horas, tanto desde la " +
  " herramienta digital de registro web como desde el menú *555# de Tigo Money. " +
  " Las transacciones tendrán los mismos costos establecidos en el tarifario " +
  " vigente de Tigo Money. <br />Tigo Money es una empresa comprometida con la " +
  " inclusión financiera y el desarrollo de la comunidad, y como tal, opera bajo " +
  " licencia del Banco Central del Paraguay como EMPE (Entidad de Medio de Pago " +
  " Electrónico) desde el 30/03/2015. <br />Para acceder a las bases y " +
  ' condiciones, ingresá a <a href="/tigo-money" style="color: #00c8ff;">www.tigo.com.py/tigo-money</a>. ' +
  "  </p> " +
  "  <br /> " +
  " " +
  "  <p> " +
  " Estas comisiones podrán ser modificadas por Mobile Cash, para lo cual " +
  " publicará las modificaciones en al menos un medio de prensa escrita de " +
  " tirada nacional, durante tres días corridos, entrando las mismas en vigencia " +
  " luego de transcurridos siete días corridos desde la última publicación. Si " +
  " el Cliente no está conforme con las modificaciones así notificadas, deberá " +
  " solicitar la cancelación de los servicios Tigo Money durante los días " +
  " transcurridos entre la última publicación y la fecha de entrada en vigencia " +
  " de la modificación, por escrito y en las oficinas de atención al Cliente " +
  " habilitadas por Mobile Cash. " +
  "  </p> " +
  '  <div class="table-scroller"> ' +
  ' <table id="table-py1OJGbPk30i30dt1VlzpKjH-2" style="border:1px solid #e5eaf2; background-color: #fff; line-height: 1.5; ' +
  "  width: 100%; " +
  " border: 1px solid #e5eaf2; " +
  " background-color: #fff; " +
  " border-collapse: separate; " +
  " text-indent: initial; " +
  " border-spacing: 2px; " +
  ' display: table;"> ' +
  " <thead> " +
  "  <tr> " +
  '  <th style="border-bottom: 2px solid #00377b;  color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;" ' +
  ' width="33%" ' +
  ' class="sort is-active" ' +
  ' data-text="Versión" ' +
  ' id="table-py1OJGbPk30i30dt1VlzpKjH-2-0" ' +
  ' data-table="#table-py1OJGbPk30i30dt1VlzpKjH-2" ' +
  ' data-th="0" ' +
  ' data-order="asc" ' +
  "  > " +
  ' <div class="d-flex"> ' +
  ' <span class="mr-auto">Versión</span> ' +
  " </div> " +
  "  </th> " +
  '  <th style=" border-bottom: 2px solid #00377b;  color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;" ' +
  ' width="33%" ' +
  ' class="sort" ' +
  ' data-text="Fecha del cambio" ' +
  ' id="table-py1OJGbPk30i30dt1VlzpKjH-2-1" ' +
  ' data-table="#table-py1OJGbPk30i30dt1VlzpKjH-2" ' +
  ' data-th="1" ' +
  ' data-order="" ' +
  "  > " +
  ' <div class="d-flex"> ' +
  ' <span class="mr-auto">Fecha del cambio</span> ' +
  " </div> " +
  "  </th> " +
  '  <th style=" border-bottom: 2px solid #00377b;  color: #00377b; padding: .75rem; display: table-cell; vertical-align: inherit;" ' +
  ' width="34%" ' +
  ' class="sort" ' +
  ' data-text="Descripción" ' +
  ' id="table-py1OJGbPk30i30dt1VlzpKjH-2-2" ' +
  ' data-table="#table-py1OJGbPk30i30dt1VlzpKjH-2" ' +
  ' data-th="2" ' +
  ' data-order="" ' +
  "  > " +
  ' <div class="d-flex"> ' +
  ' <span class="mr-auto">Descripción</span> ' +
  " </div> " +
  "  </th> " +
  "  </tr> " +
  " </thead> " +
  " <tbody> " +
  "  <tr> " +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">1</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">07 de marzo de 2022</td> ' +
  '  <td style="padding: .75rem; display: table-cell; vertical-align: inherit; border-bottom:1px solid #e5eaf2;">Modificación de texto</td> ' +
  "  </tr> " +
  " </tbody> " +
  " </table> " +
  "  </div> " +
  "</div> ";

export default termsPY;
